import React, { useState } from "react";
import { t } from "i18next";
import { addMaster } from "../Master/MasterActions";
import AddValueModal from "../../components/AddValueModal/AddValueModal";
import EditJigs from "../Jigs/EditJigs";
import TextButton from "../../components/Buttons/TextButton";
import classes from "./ActionItem.module.css";
import { useDispatch } from "react-redux";
import Utils from "../../Utils";


function ActionItem({value, items, onChange, action, addValueLabel, addButtonAtBottom, style, name, onValueAdd}) {
    const dispatch = useDispatch();
    const actionValue = value || "NA";
    const [showAddValue, setShowAddValue] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showEditJigs, setShowEditJigs] = useState(false);
    const disallowedValues = Object.values(items).map((item) => item.toLowerCase());

    if (!onValueAdd) {
        onValueAdd = onChange;
    }

    function valueChangedHandler(value) {
        onChange && onChange(action, value);
    }

    function clearHandler() {
        valueChangedHandler('NA');
    }

    function addValueHandler(actionType) {
        setShowAddValue(true);
    }

    function closeAddValueHandler() {
        setShowAddValue(false);
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    function addNewValueHandler(value) {
        setShowAddValue(false);

        const master = {
            type: action,
            value: value
        };

        addMaster(dispatch, master).then((response) => {
            if (response && response.data && response.data.success) {
                onValueAdd && onValueAdd(action, value);
            }
            else {
                setShowError(true);
            }
        });
    }

    function addJigHandler() {
        setShowEditJigs(true);
    }

    function editJigsCloseHandler(obj) {
        if (obj && obj.isAdded) {
            valueChangedHandler(obj.name);
        }
        setShowEditJigs(false);
    }

    const addValue =  addValueLabel === t('button.addJig') ? addJigHandler : addValueHandler;

    return (
        <>
            {showError && Utils.showErrorDialog(t('extendFilter.error.addValueFailed'), closeErrorHandler)}
            {showAddValue && <AddValueModal disallowedValues={disallowedValues} onAdd={addNewValueHandler} onClose={closeAddValueHandler} />}
            {showEditJigs && <EditJigs editJigsCloseHandler={editJigsCloseHandler} previewJig={""} />}
            <div className={addButtonAtBottom ? classes.mainWithAddButtonAtBottom : classes.main}>
                <div className={classes.label} style={style && {"width" : style.labelWidth}}>
                    <label>{name}:</label>
                </div>
                <div className={classes.select} style={style && {"width" : style.selectWidth}}>
                    <select value={actionValue} onChange={(e) => valueChangedHandler(e.target.value)}>
                        <option value="NA">{t('option.notAssigned')}</option>
                        {
                            Object.keys(items).map((key) => {
                                return <option key={key} value={key}>{items[key]}</option>
                            })
                        }
                    </select>
                    {addButtonAtBottom &&
                        <div className={classes.addValueButtonAtBottom} style={style && {"width" : style.addBtnWidth}}>
                            <TextButton onClick={addValue} label={addValueLabel || t('button.addValue')}  />
                        </div>
                    }
                </div>
                <div className={classes.clearButton} style={style && {"width" : style.clearBtnWidth}}>
                    <TextButton onClick={clearHandler} label={t('button.clear')} />
                </div>
                {!addButtonAtBottom &&
                    <div className={classes.addValueButton} style={style && {"width" : style.addBtnWidth}}>
                        <TextButton onClick={addValue} label={addValueLabel || t('button.addValue')}  />
                    </div>
                }
            </div>
        </>
    );
}

export default ActionItem;
