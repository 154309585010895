import React from "react";
import Utils from "../../Utils";
import classes from "./RuleItem.module.css";

function RuleItem(props) {

    const ruleValues = props.ruleValues;

    function changeHandler(e) {
        props.onChange(props.ruleKey, e.target.value, e.target.checked);
    }
    return (<div className={classes.main}>
        <h3>{Utils.getStringFromKey("actions." + props.ruleKey)}</h3>
        <div className={classes.lists}>
            {ruleValues.map((value) => {
                return <div className={classes.list} key={value}><input type="checkbox" id={value} value={value} checked={props.selectedRules[props.ruleKey].includes(value)
                } onChange={changeHandler} />
                    <div className={classes.label}>
                        <label htmlFor={value} >{value}</label>
                    </div>
                </div>
            })}
        </div>
    </div>
    );
}

export default RuleItem;