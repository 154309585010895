import { createSlice } from "@reduxjs/toolkit";

function getDefaultConfig() {
  return {
    // mm units.
    unit: "mm",

    // Poll every 2 mins for new orders.
    isAutoPollingOn: true,
    autoPollingIntervalMins: 2,

    // Purge history orders older than 21 days. Run at 0000 hours UTC.
    isAutoPurgeOn: true,
    autoDeleteAfterDays: 21,
    autoDeleteScheduleAt: 0,

    // Help message.
    showHelpMessage: false,
    helpMessage: ""
  };
}

const initialState = {
  config: getDefaultConfig()
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    getConfig: (state, action) => {
      let newConfig = {
        ...getDefaultConfig() // Default to begin with
      };
      if (action.payload.config) {
        // Merge in the config we got from server.
        Object.assign(newConfig, action.payload.config);
      }
      state.config = newConfig;
    },
    updateConfig: (state, action) => {
      const newConfig = {
        ...state.config,
        ...action.payload.config
      }
      state.config = newConfig;
    }
  }
});

export const configActions = configSlice.actions;

export default configSlice.reducer;
