import React from "react";
import closeOutlineImage from "../../assets/add-circle-outline.svg";
import classes from './CloseButton.module.css';

function CloseButton(props) {

    const closeBtn = `btn ${classes.closeBtn}`;
    return (<>
        <button type="button" onClick={props.onClick}
            className={closeBtn}><img src={closeOutlineImage} alt="Close Button" />
        </button>
    </>)
}

export default CloseButton;