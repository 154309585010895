import React, { useEffect } from "react";
import SideMenuItem from "./SideMenuItem";
import loginIcon from "../../assets/login-outline.svg";

function poptastic(url, clientId, redirectUrl, scope, responseType, nonce, responseMode) {
    const encode = window.encodeURIComponent;
    url = `${url}?client_id=${encode(clientId)}&redirect_uri=${encode(redirectUrl)}`;
    if (scope) {
        url += `&scope=${encode(scope)}`
    }
    if (responseType) {
        url += `&response_type=${encode(responseType)}`
    }
    if (nonce) {
        url += `&nonce=${encode(nonce)}`
    }
    if (responseMode) {
        url += `&response_mode=${encode(responseMode)}`
    }
	window.open (url, "auth", "width=700,height=900");
}
  
function SideMenuAuthItem({url, clientId, redirectUrl, scope, responseType, nonce, responseMode, callback}) {
    useEffect(() => {
        const onMessage = e => {
            if (e.origin === window.location.origin && e.source.name === 'auth') {
                callback && callback(e.data);
            }
        }
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener("message", onMessage);
        }
    }, [callback]);
    return <SideMenuItem 
        title={"Login Roland DG Connect admin account"} 
        type={"submit"}
        onClick={e => poptastic(url, clientId, redirectUrl, scope, responseType, nonce, responseMode)}
        imageIcon={loginIcon}/>
}

export default SideMenuAuthItem;