import browserHistory from "../../../../history.js";
import axios from '../../../axios';

export function createEnvironment(data) {
    return axios({
        url: "/pax/customers/createEnvironment",
        method: "post",
        data,
        responseType: "json",
    }).then((response) => {
        if (response.data.success) {
          window.setResourceName(data.resourceName);
          let dataLayer = window.dataLayer;
          if (dataLayer) {
            dataLayer.push({
                event: 'environmentCreated',
                environment: data.resourceName,
            });
          }
          let url = `/${data.resourceName}`;
          browserHistory.push(url);
          browserHistory.go();
        }
        return response;
      }).catch((error) => {
        return error;
      });
}