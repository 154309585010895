import React from "react";
import { useTranslation } from "react-i18next";
import Instruction from "../../components/Instruction/Instruction";
import BackButton from '../../components/Buttons/BackButton';
import Consts from "../../Consts";
import classes from "./Connectors.module.css";

function Connectors(props) {
    const { t } = useTranslation();

  return (
    <div className={classes.main}>
        <div className={classes.backButton}>
            <BackButton onBack={() => props.history.goBack()} />
        </div>
        <div className={classes.container}>
            <div className={classes.heading}>
                <h2>{t('connectors.heading')}</h2>
            </div>
            <div className={classes.body}>
                <Instruction 
                    style={{width: "45%", marginTop: "1rem"}} 
                    link={Consts.CONNECTOR_INPUT_INSTALLER_LINK} 
                    value={t('instruction.downloadInputConnector')}/>
                <Instruction 
                    style={{width: "45%", marginTop: "1rem"}} 
                    link={Consts.CONNECTOR_OUTPUT_INSTALLER_LINK} 
                    value={t('instruction.downloadOutputConnector')}/>
            </div>
        </div>        
    </div>
  );
}

export default Connectors;