import React, { useState } from 'react';
import { t } from "i18next";
import { useDispatch } from 'react-redux';
import { addMaster } from '../Master/MasterActions';
import AddValueModal from '../../components/AddValueModal/AddValueModal';
import TextButton from '../../components/Buttons/TextButton';
import Utils from '../../Utils';
import classes from './DeviceSettingOption.module.css';

function DeviceSettingOption(props) {

    const dispatch = useDispatch();
    const [showAddValue, setShowAddValue] = useState(false);
    const [showError, setShowError] = useState(false);
    const items = props.items;
    const disallowedValues = Object.values(items).map((item) => item.toLowerCase());

    function valueChangedHandler(value) {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    function addNewValueHandler(value) {
        setShowAddValue(false);

        const master = {
            type: props.action,
            value: value
        };

        addMaster(dispatch, master).then((response) => {
            if (response && response.data && response.data.success) {
                valueChangedHandler(value);
            }
            else {
                setShowError(true);
            }
        });
    }
    
    return <>
        {showError && Utils.showErrorDialog(t('extendFilter.error.addValueFailed'), closeErrorHandler)}
        {showAddValue && <AddValueModal disallowedValues={disallowedValues} onAdd={addNewValueHandler} onClose={e => setShowAddValue(false)} />}
        <div className={classes.main} style={{height: 'unset', width: 'unset'}}>
            <h3>{props.name}</h3>
            <select value={props.value || "NA"} onChange={(e) => valueChangedHandler(e.target.value)}>
                <option value="NA">{t('option.notAssigned')}</option>
                {
                    Object.keys(items).map((item) => {
                        return <option key={item} value={item}>{items[item]}</option>
                    })
                }
            </select>
            <div className={classes.btn}>
                <TextButton label={t('button.addValue')} onClick={e => setShowAddValue(true)}/>
            </div>
        </div>
    </>
}

export default DeviceSettingOption;