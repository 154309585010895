import axios from '../../axios';
import { inputSettingsActions } from "./InputSettingsSlice";

export function getInputSettings(dispatch) {
    return axios({
        url: "/pax/inputsetting",
        method: "get",
        responseType: "json",
    })
        .then(function (response) {
            let data = response.data;
            if (data.success && data.inputSettings) {
                dispatch(inputSettingsActions.fetchInputSettings({ inputSettings: data.inputSettings }));
            }
            return response;
        })
        .catch(function (error) {
            console.log(error);
            return error;
        });
}

export function updateConnectors(dispatch, data) {
    return axios({
        url: "/pax/inputsetting/connectors",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                dispatch(inputSettingsActions.updateConnectors({ inputSettings: data }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function updateCustomParams(dispatch, data) {
    return axios({
        url: '/pax/inputsetting/customparams',
        method: "post",
        data: {
            customParams: data
        },
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                dispatch(inputSettingsActions.updateCustomParams({ customParams: data }));
                return true;
            }
            return response;
        }).catch((error) => {
            return error;
        });
}
