import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Modal from "../../../components/Modal/Modal";
import OrderViewer from './OrderViewer';
import classes from './EditOrder.module.css';
import { updateOrder } from "../OrdersActions";
import Utils from "../../../Utils";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import AssignedUsers from "./AssignedUsers";
import Consts from '../../../Consts';

function EditOrder(props) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allJigs = useSelector((state) => state.jigs.allJigs.jigs, shallowEqual);
    const selectedOrder = props.selectedOrder;
    const [copies, setCopies] = useState(selectedOrder.copies);
    const [printQuantity, setPrintQuantity] = useState(parseInt(selectedOrder.remaining));

    const [jig, setJig] = useState(selectedOrder.jig || "");
    const [assignedUsers, setAssignedUsers] = useState(props.selectedOrder.users || []);
    const [showError, setShowError] = useState(false);
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
    let errorMessage = "";
    let myStyle = {};

    function saveJigHandler() {
        let jsonData = {
            "id": selectedOrder.objId,
            "jobId": selectedOrder.jobId,
            "copies": copies,
            "remaining": printQuantity,
            "jig": jig,
            "users": assignedUsers
        };
        updateOrder(dispatch, jsonData).then((response) => {
            if (response && response.data && response.data.success) {
                setShowError(false);
                props.editOrderSaveHandler();
            }
            else {
                setShowError(true);
            }
        });
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    function assignedUsersChangeHandler(value) {
        setAssignedUsers(value);
    }

    function isSaveDisabled() {
        if (selectedOrder.assigned) {
            errorMessage = t('editOrder.error.jobAssigend');
            myStyle = { "color": "red" };
            return true;
        }

        if (copies <= 0 || copies < printQuantity) {
            copies <= 0 ? errorMessage = t("editOrder.error.noCopies") : errorMessage = t("editOrder.error.remainingExceedsCopies");
            myStyle = { "color": "red" };
            return true;
        } else if (isNaN(copies) || isNaN(printQuantity)) {
            myStyle = { "visibility": "hidden" };
            return true;
        } else {
            myStyle = { "visibility": "hidden" };
            return false;
        }
    }

    return (
        <Modal onClose={props.editOrderCloseHandler} title={t('editOrder.heading')} >
            {showError && Utils.showErrorDialog(t('editOrder.error.saveFailed'), closeErrorHandler)}
            <div className={classes.main}>
                <div className={classes.action}>
                    <SecondaryButton onClick={props.editOrderCloseHandler} label={t('button.cancel')} />
                    <PrimaryButton onClick={saveJigHandler} label={t('button.save')} disabled={isSaveDisabled()} /> </div>
                <div className={classes.containerTop}>
                    <div className={classes.box}>
                        <h3> {t('editOrder.orderId.label')} </h3>
                        <p>{selectedOrder.orderId}</p>
                    </div>
                    <div className={classes.box}>
                        <h3> {t('editOrder.dealerOrderId.label')} </h3>
                        <p>{selectedOrder.dealerOrderId}</p>
                    </div>
                    <div className={classes.box}>
                        <h3> {t('editOrder.frame.label')} </h3>
                        <p>{selectedOrder.jobId}</p>
                    </div>
                    <div className={classes.box}>
                        <h3> {t('editOrder.size.label')} </h3>
                        <p>{selectedOrder.mediaSize}</p>
                    </div>
                </div>
                <div className={classes.containerMiddle}>
                    <div className={classes.containerLeft}>
                        <div className={classes.bigBox}>
                            <div className={classes.selectBox}>
                                <label htmlFor="copies"> {t('editOrder.quantity.label')}</label>
                                <input type="number" id="copies" min={1} onChange={(e) => {
                                    setCopies(parseInt(e.target.value));
                                }} value={copies} disabled={selectedOrder.assigned} />
                            </div>
                            <div className={classes.selectBox}>
                                <label htmlFor="channelPreset"> {t('editOrder.jigSelection.label')}</label>
                                <select name="channelPreset" id="channelPreset" onChange={(e) => {
                                    setJig(e.target.value);
                                }} value={jig} disabled={selectedOrder.assigned || !Utils.canChangeOrderJig(permissions)}>
                                    {jig === "" ? <option value="NA">{t('option.notAssigned')}</option> : null}
                                    {allJigs && allJigs.map((jig) => {
                                        return (
                                            <option key={jig.name} value={jig.name}>{jig.name} </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className={classes.bigBox}>
                            <div className={classes.selectBox}>
                                <label htmlFor="printQuantity"> {t('editOrder.printQuantity.label')}</label>
                                <input type="number" id="printQuantity" min={0} max={copies} onChange={(e) => {
                                    setPrintQuantity(parseInt(e.target.value));
                                }} value={printQuantity} disabled={selectedOrder.assigned} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.containerRight}>
                        <OrderViewer job={selectedOrder} />
                    </div>
                </div>
                {Utils.canAssignUsers(permissions) &&
                    <div className={classes.assignToBox}>
                        <AssignedUsers assignedUsers={assignedUsers} onChange={assignedUsersChangeHandler} />
                    </div>
                }
                <div className={classes.errorBox}>
                    <span style={myStyle}> {errorMessage}</span>
                </div>
            </div>
        </Modal>
    );

}

export default EditOrder;