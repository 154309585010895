import React from 'react'
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import UnitInput from '../../components/UnitInput/UnitInput';
import Utils from '../../Utils';
import trashOutlineImage from "../../assets/trash-outline.svg";
import copyOutlineImage from "../../assets/copy-outline.svg";
import IconButton from "../../components/Buttons/IconButton";

function EditTableItem(props) {

    const { t } = useTranslation();
    const unit = useSelector((state) => state.config.config.unit, shallowEqual);
    const jig = props.jig;
    const jigData = props.jigData;
    const jigsData = props.jigsData;
    const index = props.index;

    const options = [];
    jigsData.forEach((jig) => {
        options.push(<option key={jig.name} value={jig.name}>{jig.name}</option>);
    });
    return (
        <tr>
            <td> {index + 1} </td>
            <td> <select name="jigs" id="jigs" onChange={(e) => {
                jigsData.forEach((jig, id) => {
                    if (jig.name === e.target.value) {
                        props.onChange("id", id, index);
                    }
                })
            }} value={jigData && jigData.name}>
                {!jigData && <option value="NA"> {t('editTable.option.jigNA')} </option>}
                {options}
            </select></td>
            {jigData && <> <td> {Utils.formatMMToUnits(jigData.width, unit)} </td>
                <td> {Utils.formatMMToUnits(jigData.height, unit)} </td>
                <td> <UnitInput name="x"
                    onChange={(value) => props.onChange("x", value, index)}
                    min={0}
                    value={jig.x}
                    changedByCode={true} />
                </td>
                <td> <UnitInput name="y"
                    onChange={(value) => props.onChange("y", value, index)}
                    min={0}
                    value={jig.y}
                    changedByCode={true} />
                </td>
            </>}
            {!jigData && <td colSpan={4}> <p style={{ "textAlign": "center", "color": "red", "padding": "0.5rem 0 0 0.5rem" }}> {t('editTable.error.jigNotFound')}</p></td>}
            <td> 
                <IconButton
                    onClick={() => props.onChange("delete", "NA", index)}
                    iconImage={trashOutlineImage}
                    tooltip={t('tooltip.delete')}
                    altText="Delete Jig" />
                <IconButton
                    onClick={() => props.onCopyJig(index)}
                    iconImage={copyOutlineImage}
                    tooltip={t('tooltip.copy')}
                    altText="Copy Jig" />
            </td>
        </tr>
    );
}

export default EditTableItem

