import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import nextId from 'react-id-generator';
import Utils from "../../Utils";
import { addTable, updateTable } from "./TablesActions";
import Modal from "../../components/Modal/Modal";
import TablePreview from './TablePreview';
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import classes from "./EditTable.module.css";
import UnitInput from "../../components/UnitInput/UnitInput";
import EditTableItem from "./EditTableItem";
import SaveAs from "../../components/SaveAs/SaveAs";

// Get the initial printable area.
function getTablePrintableArea(table) {
    if (!table) {
        return {};
    }

    if (!table.printableArea) {
        return {
            x: 0,
            y: 0,
            width: table.width,
            height: table.height
        }
    }

    return table.printableArea;
}

function EditTable(props) {

    const unit = useSelector((state) => state.config.config.unit, shallowEqual);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allJigs = useSelector((state) => state.jigs.allJigs);
    const allTables = useSelector((state) => state.tables.allTables);

    let jigsData = new Map();
    let firstJigId = 0;
    allJigs.jigs.forEach((jig, index) => {
        if (index === 0) {
            firstJigId = jig.id;
        }
        
        jigsData.set(jig.id, {
            name: jig.name,
            width: jig.width,
            height: jig.height,
            thickness: jig.thickness,
            imageBoxBounds: Utils.getImageBoxBounds(jig),
            locations: jig.locations ? [...jig.locations] : [],
            printerTypes: jig.printerTypes ? [...jig.printerTypes] : []
        });
    });
    let jigsIntialState = [];
    if (props.previewTable && props.previewTable.jigs) {
        jigsIntialState = props.previewTable.jigs.map((jig) => {
            let obj = { ...jig }
            if (!jig.key) {
                obj.key = nextId();
            }
            return obj;
        });
    }

    const [jigs, setJigs] = useState(jigsIntialState);
    const [name, setName] = useState(props.previewTable.name);
    const [width, setWidth] = useState(props.previewTable.width);
    const [height, setHeight] = useState(props.previewTable.height);
    const [referenceText, setReferenceText] = useState(props.previewTable.referenceText);
    const [defaultSpacing, setDefaultSpacing] = useState(props.previewTable.defaultSpacing || 10)
    const [jigRects, setJigRects] = useState([]);
    const [imageBoxRects, setImageBoxRects] = useState([]);
    const [showError, setShowError] = useState(false);
    const [printableArea, setPrintableArea] = useState(getTablePrintableArea(props.previewTable));
    const [showSaveAsDialog, setShowSaveAsDialog] = useState(false);
    const maxWidthOrHeight = Utils.getMaxJigOrTableSize(unit);
    const allMasters = useSelector((state) => state.master.allMasters, shallowEqual) || [];


    let allTableNames = new Map();
    allTables.tables.forEach((table) => {
        allTableNames.set(table.name, true);
    });

    const printableBounds = {
        x: printableArea.x || 0,
        y: printableArea.y || 0,
        width: printableArea.width || width || 0,
        height: printableArea.height || height || 0
    };

    printableBounds.width = Math.max(0, Math.min(printableBounds.width, width - printableBounds.x));
    printableBounds.height = Math.max(0, Math.min(printableBounds.height, height - printableBounds.y));

    const tableBounds = {
        x: 0,
        y: 0,
        width: width,
        height: height
    }

    function getCopyName(name) {
        return Utils.getCopyName(allTableNames, name);
    }

    useEffect(() => {
        updatePreview();
    }, [width, height, jigs, printableArea]);

    function checkTable() {
        if (jigs && Utils.outsideBounds(jigRects, tableBounds) === false &&
            Utils.outsideBounds(imageBoxRects, printableBounds) === false &&
            Utils.checkOverlap(jigRects) === false) {
            return true;
        }
        else {
            return false;
        }
    }

    function saveTableHandler(isUpdate, tableName) {
        if (height > 0 && width > 0 && checkTable() && jigs.length) {
            let jigsWithouKey = jigs?.map((jig) => {
                let obj = { ...jig };
                delete obj.key;
                return obj;
            })
            const data = {
                name: name,
                width: parseFloat(width),
                height: parseFloat(height),
                jigs: jigsWithouKey,
                printableArea: printableArea,
                referenceText: referenceText,
                defaultSpacing: parseFloat(defaultSpacing)
            }
            if (tableName) {
                data.name = tableName;
            }
            if (props.previewTable) {
                data.id = props.previewTable.id;
            }
            (props.previewTable && isUpdate ? updateTable : addTable)(dispatch, data).then((response) => {
                if (response && response.data && response.data.success) {
                    setShowError(false);
                    props.editTableCloseHandler();
                    if (!props.previewTable || !isUpdate) {
                        props.scrollToBottom();
                    }
                }
                else {
                    setShowError(true);
                }
            });
        }
    }

    function saveAsTableHandler(tableName) {
        saveTableHandler(false, tableName);
        setShowSaveAsDialog(false)
    }
    function modalCloseHandler() {
        props.editTableCloseHandler();
    }

    function updatePreview() {
        if (width > 0 && height > 0) {
            let rects = [];
            let boxRects = [];
            jigs.forEach((jig) => {
                const jigData = jigsData.get(jig.id);
                if (jigData) {
                    let obj = {
                        x: parseFloat(jig.x),
                        y: parseFloat(jig.y),
                        width: parseFloat(jigData.width),
                        height: parseFloat(jigData.height),
                    }
                    rects.push(obj);

                    const imageBoxBounds = jigData.imageBoxBounds;
                    let imageBoxObj = {
                        x: parseFloat(jig.x + imageBoxBounds.minX),
                        y: parseFloat(jig.y + imageBoxBounds.minY),
                        width: parseFloat(imageBoxBounds.maxX - imageBoxBounds.minX),
                        height: parseFloat(imageBoxBounds.maxY - imageBoxBounds.minY)
                    }
                    boxRects.push(imageBoxObj);
                }
            });
            setJigRects(rects);
            setImageBoxRects(boxRects);
        }
    }

    function getJigCoordinates(jig) {
        const jigData = jigsData.get(jig.id);
        if(!jigData){
            return
        }
        const imageBoxBounds = jigData.imageBoxBounds;
        let xPos = Math.max(0, printableBounds.x - imageBoxBounds.minX);
        let yPos = Math.max(0, printableBounds.y - imageBoxBounds.minY);
        return {
            x: xPos,
            y: yPos
        }
    }

    function getMaxY(index, jigs) {
        let maxY = 0;
        for (let i = 0; i < index; i++) {
            const jigData = jigsData.get(jigs[i].id);
            maxY = Math.max(maxY, jigs[i].y + jigData?.height);
        }
        return maxY;
    }

    function repositionJig(jig, index, jigs) {
        const jigCoordinates = getJigCoordinates(jig);
        if (index > 0) {
            const lastJig = jigs[index - 1];
            const lastJigData = jigsData.get(lastJig.id);
            const jigData = jigsData.get(jig.id);

            // Our tentative x position.
            const posX = lastJig.x + lastJigData?.width + defaultSpacing;

            // Check if the jig stays with the table bounds, and the image boxes stay within the
            // printable bounds.
            if (posX + jigData?.width <= width &&
                posX + jigData?.imageBoxBounds.maxX <= printableBounds.x + printableBounds?.width) {
                jig.x = posX;
                if (lastJig?.y <= printableBounds?.y) {
                    // Place jig on first row.
                    jig.y = jigCoordinates.y;
                } else {
                    jig.y = lastJig.y;
                }
            } else {
                // Place on a new row.
                // Position above the maximum extent of the previous row of jigs.
                jig.x = jigCoordinates?.x;
                jig.y = getMaxY(index, jigs) + defaultSpacing;
            }
        } else {
            jig.x = jigCoordinates.x;
            jig.y = jigCoordinates.y;
        }
    }

    function addJigHandler() {
        setJigs((jigs) => {
            let obj = {
                x: 0,
                y: 0,
                id: firstJigId,
                key: nextId()
            }

            if (jigs.length) {
                obj.id = jigs[jigs.length - 1].id;
            }

            // Position the newly added jig.
            repositionJig(obj, jigs.length, jigs);
            return [...jigs, obj];
        });
    }

    function copyJigHandler(index) {
        setJigs((jigs) => {
            let obj = {
                x: 0,
                y: 0,
                id: jigs[index].id,
                key: nextId()
            }
            // Position the copied jig.
            repositionJig(obj, jigs.length, jigs);
            return [...jigs, obj];
        });
    }

    function nameChangeHandler(e) {
        setName(e.target.value);
    }

    function widthChangeHandler(value) {
        setWidth(value);
    }

    function heightChangeHandler(value) {
        setHeight(value);
    }

    function referenceTextChangeHandler(e) {
        setReferenceText(e.target.value);
    }

    function defaultSpacingChangeHandler(value) {
        setDefaultSpacing(value);
    }

    function isNameExist(name) {
        let isNameExist = false;
        allTables.tables.forEach((table) => {
            if (name && table.name === name.trim()) {
                isNameExist = true;
            }
        });
        return isNameExist;
    }

    function closeErrorHandler() {
        setShowError(false)
    }

    const previewTable = {
        width: width,
        height: height,
        jigs: jigs,
        printableArea: printableBounds
    };

    function jigChangeHandler(property, value, index) {
        if (property === "delete") {
            setJigs((prevState) => {
                let temp = [...prevState];
                temp.splice(index, 1);
                return temp;
            });
        }
        else {
            setJigs((prevState) => {
                let temp = [...prevState];
                temp[index] = {
                    ...temp[index],
                };
                temp[index][property] = value;
                if (property === "id") {
                    for (let i = index; i < temp.length; i++) {
                        repositionJig(temp[i], i, temp);
                    }
                }
                return temp;
            });
        }
    }

    function checkJigThickness() {
        let thickness;
        let result = jigs.some((jig, index) => {
            const jigData = jigsData.get(jig.id);
            if (!jigData) {
                return false;
            }
            if (jigData.thickness === undefined) {
                return false;
            }
            if (thickness === undefined) {
                thickness = jigData.thickness;
                return false;
            }
            if (thickness === jigData.thickness) {
                return false;
            }
            return true;
        });
        return result;
    }

    function shouldSaveDisable() {
        if (!name || Utils.outsideBounds(jigRects, tableBounds) ||
            Utils.outsideBounds(imageBoxRects, printableBounds) ||
            Utils.checkOverlap(jigRects) || !jigs.length ||
            (props.previewTable.name !== name && isNameExist(name))) {
            return true;
        }
        return false;
    }

    function previewMessage() {
        if (jigRects && (Utils.outsideBounds(jigRects, tableBounds))) {
            return <p>{t('tables.error.jigsExceedTable')}</p>;
        } else if (imageBoxRects && Utils.outsideBounds(imageBoxRects, printableBounds)) {
            return <p>{t('tables.error.imageBoxesExceedPrintableBounds')}</p>;
        } else if (jigRects && Utils.checkOverlap(jigRects)) {
            return <p>{t('tables.error.jigsOverlap')}</p>;
        } else {
            return null;
        }
    }

    function getJigsData() {
        if (jigs.length <= 1) {
            return jigsData;
        } else {
            const updatedJigsData = new Map();
            for (const [key, value] of jigsData.entries()) {
                const jigIds = jigs?.map(e => e.id).concat(key);
                const locationIntersection = Utils.getMasterDataIntersection("locations", jigsData, jigIds);
                const printerTypeIntersection = Utils.getMasterDataIntersection("printerTypes", jigsData, jigIds);
                if (locationIntersection?.length && printerTypeIntersection?.length) {
                    updatedJigsData.set(key, value);
                }
            }
            return updatedJigsData;
        }
    }

    function getActionValues(action, valMap) {
        let values = Utils.getMasterDataIntersection(action, jigsData, jigs?.map(e => e.id))?.map(e => valMap[e]);
        if (values.length) {
            return values;
        }
        const jigIdsSet = new Set();
        jigs.forEach((e) => {
            const currJigData = jigsData.get(e.id);
            if (currJigData?.[action] && currJigData?.[action].length >= 1) {
                jigIdsSet.add(e.id);
            }
        })
        if (jigIdsSet.size === 0) {
            return t("items.notAssigned");
        } else {
            return t("tables.error.jigsNoCommonMasterData");
        }
    }

    const allLocations = allMasters.location || {};
    const allPrinterTypes = allMasters.printerType || {};

    const locationsMessage = `${t('actions.location')} : ${getActionValues("locations", allLocations)}`;
    const printerTypesMessage = `${t('actions.printerType')} : ${getActionValues("printerTypes", allPrinterTypes)}`;

    return (
        <Modal onClose={modalCloseHandler} title={props.previewTable ? t('editTable.heading.edit') : t('editTable.heading.add')}>
            {showError && Utils.showErrorDialog(t('tables.error.saveFailed'), closeErrorHandler)}
            {showSaveAsDialog && <SaveAs onClose={() => setShowSaveAsDialog(false)} onSave={saveAsTableHandler} name={getCopyName(name)} checkName={isNameExist} />}
            <div className={classes.main}>
                <div className={classes.leftContainer}>
                    <h3>{t('editTable.table.heading')}</h3>
                    <div className={classes.tableFields}>
                        <div className={classes.tableField}> <span className={classes.required}>{'* '}</span><span>{t('editTable.table.name')}</span> </div>
                        <div className={classes.tableField}> <input type="text"
                            name="name"
                            onChange={nameChangeHandler}
                            value={name} />
                        </div>
                        {props.previewTable.name !== name && isNameExist(name) && <p style={{ color: "red" }}> {t('editTable.table.sameNameMsg')} </p>}
                        <div className={classes.tableFieldHeading}> <span>{t('editTable.table.physicalSize')}</span></div>
                        <div className={classes.tableField}> <span className={classes.required}>{'* '}</span><span>{Utils.appendUnits(t('editTable.table.width'), unit)}</span> </div>
                        <div className={classes.tableField}>
                            <UnitInput name="width"
                                onChange={widthChangeHandler}
                                min={0}
                                value={width}
                                maxValue={maxWidthOrHeight}
                            />
                        </div>
                        <div className={classes.tableField}> <span className={classes.required}>{'* '}</span><span>{Utils.appendUnits(t('editTable.table.height'), unit)}</span> </div>
                        <div className={classes.tableField}>
                            <UnitInput name="height"
                                onChange={heightChangeHandler}
                                min={0}
                                value={height}
                                maxValue={maxWidthOrHeight}
                            />
                        </div>
                        <div className={classes.tableFieldHeading}> <span>{t('editTable.table.printArea')}</span></div>
                        <div className={classes.tableField}> <span>{Utils.appendUnits(t('editTable.table.xOffset'), unit)}</span> </div>
                        <div className={classes.tableField}>
                            <UnitInput name="xOffset"
                                onChange={(value) => {
                                    setPrintableArea((prevState) => {
                                        return {
                                            ...prevState,
                                            x: value
                                        }
                                    });
                                }}
                                min={0}
                                value={printableArea.x}
                            />
                        </div>
                        <div className={classes.tableField}> <span>{Utils.appendUnits(t('editTable.table.yOffset'), unit)}</span> </div>
                        <div className={classes.tableField}>
                            <UnitInput name="yOffset"
                                onChange={(value) => {
                                    setPrintableArea((prevState) => {
                                        return {
                                            ...prevState,
                                            y: value
                                        }
                                    });
                                }}
                                min={0}
                                value={printableArea.y}
                            />
                        </div>
                        <div className={classes.tableField}> <span>{Utils.appendUnits(t('editTable.table.printAreaWidth'), unit)}</span> </div>
                        <div className={classes.tableField}>
                            <UnitInput name="printWidth"
                                onChange={(value) => {
                                    setPrintableArea((prevState) => {
                                        return {
                                            ...prevState,
                                            width: value
                                        }
                                    });
                                }}
                                min={0}
                                value={printableArea.width}
                            />
                        </div>
                        <div className={classes.tableField}> <span>{Utils.appendUnits(t('editTable.table.printAreaHeight'), unit)}</span> </div>
                        <div className={classes.tableField}>
                            <UnitInput name="printHeight"
                                onChange={(value) => {
                                    setPrintableArea((prevState) => {
                                        return {
                                            ...prevState,
                                            height: value
                                        }
                                    });
                                }}
                                min={0}
                                value={printableArea.height}
                            />
                        </div>
                        <div className={classes.tableField}> <span>{t('editTable.table.referenceText')}</span> </div>
                        <div className={classes.tableField}> <input type="text"
                            name="referenceText"
                            onChange={referenceTextChangeHandler}
                            value={referenceText} />
                        </div>
                        <div className={classes.tableField}> <span>{Utils.appendUnits(t('editTable.table.defaultSpacing'), unit)}</span> </div>
                        <div className={classes.tableField}>
                            <UnitInput name="defaultSpacing"
                                onChange={defaultSpacingChangeHandler}
                                min={0}
                                value={defaultSpacing}
                            />
                        </div>
                        <div className={classes.tableField}>
                            <span className={classes.required}>{'*'}</span><span style={{ whiteSpace: 'pre' }}>{' - '}</span><span>{t('label.requiredField')}</span>
                        </div>
                    </div>
                </div>
                <div className={classes.middleContainer}>
                    <h3> {t('editTable.jigsEntry.heading')}</h3>
                    <div className={classes.jigTable}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ "width": "10%" }}>{t('editTable.jigsEntry.id')}</th>
                                    <th style={{ "width": "15%" }}>{t('editTable.jigsEntry.name')}</th>
                                    <th style={{ "width": "14%" }}>{Utils.appendUnits(t('editTable.jigsEntry.width'), unit)}</th>
                                    <th style={{ "width": "14%" }}>{Utils.appendUnits(t('editTable.jigsEntry.height'), unit)}</th>
                                    <th style={{ "width": "14%" }}>{Utils.appendUnits(t('editTable.jigsEntry.x'), unit)}</th>
                                    <th style={{ "width": "14%" }}>{Utils.appendUnits(t('editTable.jigsEntry.y'), unit)}</th>
                                    <th style={{ "width": "19%" }}>{t('editTable.jigsEntry.deleteJig')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jigs && jigs?.map((jig, index) => {
                                    const jigData = jigsData.get(jigs[index].id);
                                    return <EditTableItem key={jig.key} jig={jig} jigsData={getJigsData()} jigData={jigData}
                                        index={index} onChange={jigChangeHandler} onCopyJig={copyJigHandler} />
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.addJigBtn}>
                        <SecondaryButton onClick={addJigHandler} label={t('editTable.jigEntry.addbtn')} />
                    </div>
                </div>
                <div className={classes.rightContainer}>
                    <div className={classes.previewHeading}>
                        <h3>{t('preview.heading')}</h3>
                    </div>
                    <div className={classes.previewContent}>
                        <TablePreview previewTable={previewTable} showOrigin={true} showValidation={true} showImageBox={true} style={{"height": "calc(100% - 5rem)"}} />
                        <div className={classes.previewMessage}>
                            {previewMessage()}
                            {checkJigThickness() && <p> {t('tables.error.jigsVaryingThickness')} </p>}
                        </div>
                    </div>
                    {jigs.length >= 1 &&
                        <div className={classes.masterDataMessage}>
                            <p title={locationsMessage}>{locationsMessage}</p>
                            <p title={printerTypesMessage}>{printerTypesMessage}</p>
                        </div>
                    }
                    <div className={classes.actions}>
                        {props.previewTable && <div className={classes.saveAsBtn}>
                            <SecondaryButton disabled={shouldSaveDisable()}
                                onClick={() => setShowSaveAsDialog(true)} label={t('button.saveAs')} />
                        </div>
                        }
                        <SecondaryButton onClick={modalCloseHandler} label={t('button.cancel')} />
                        <PrimaryButton disabled={shouldSaveDisable()} onClick={() => saveTableHandler(true)} label={t('button.save')} />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default EditTable;
