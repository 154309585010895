import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addTable, deleteTable } from "./TablesActions";
import { encodeTableToSvg } from "../../SvgParser";
import TablePreview from "./TablePreview";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import TableItem from "./TableItem";
import Confirm from "../../components/Confirm/Confirm";
import EditTable from "./EditTable";
import BackButton from "../../components/Buttons/BackButton";
import Utils from "../../Utils";
import classes from "./Tables.module.css";
import SearchField from "../../components/SearchField/SearchField";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Filters from "../Filters/Filters";
import { useHistory } from "react-router-dom";

function Tables(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tableStartRef = useRef();
  const tableEndRef = useRef();
  const allTables = useSelector((state) => state.tables.allTables, shallowEqual);
  const allJigs = useSelector((state) => state.jigs.allJigs.jigs, shallowEqual);
  const unit = useSelector((state) => state.config.config.unit, shallowEqual);
  const [showEditTable, setShowEditTable] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedTable, setSelectedTable] = useState(allTables.tables[0]);
  const [previewTable, setPreviewTable] = useState({});
  const [tableid, setTableid] = useState(0);
  const [activeRow, setActiveRow] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
  const history = useHistory();

  useEffect(() => {
    setSelectedTable(allTables.tables[allTables.selectedIndex]);
    setActiveRow(allTables.selectedIndex);
  }, [allTables]);

  let allTableNames = new Map();
  allTables.tables.forEach((table) => {
    allTableNames.set(table.name, true);
  });

  function scrollToBottom() {
    tableEndRef.current.scrollIntoView({ behavior: 'smooth', block: "end" });
  }

  function scrollToTop() {
    tableStartRef.current.scrollIntoView({ behavior: 'smooth', block: "end" });
  }

  function getCopyName(name) {
    return Utils.getCopyName(allTableNames, name);
  }

  function addTableHandler() {
    setShowEditTable(true);
    setPreviewTable("");
  }

  function editTableCloseHandler() {
    setShowEditTable(false);
  }

  function deleteTableCloseHandler() {
    setShowConfirmation(false);
  }

  function confirmTableDeleteHandler() {
    setShowConfirmation(false);
    deleteTable(dispatch, tableid).then((response) => {
      if (response && response.data && response.data.success) {
        setShowError(false);
        scrollToTop();
      }
      else {
        setErrorMessage(t('tables.error.deleteFailed'));
        setShowError(true);
      }
    });
  }

  function selectTableHandler(table, index) {
    setSelectedTable(table);
    setPreviewTable(table);
    setActiveRow(index);
  }

  function editTableHandler(table) {
    setSelectedTable(table);
    setPreviewTable(table);
    setShowEditTable(true);
  }

  function deleteTableHandler(id) {
    setShowConfirmation(true);
    setShowEditTable(false);
    setTableid(id);
  }

  function changeSearchTextHandler(e) {
    setSearchText(e.target.value);
  }

  function copyTableHandler(table) {
    const data = {
      ...table,
      name: getCopyName(table.name)
    }
    delete data.id;
    addTable(dispatch, data).then((response) => {
      if (response && response.data && response.data.success) {
        setShowError(false);
        scrollToBottom();
      }
      else {
        setErrorMessage(t('tables.error.copyFailed'));
        setShowError(true);
      }
    });
  }

  function downloadTableHandler(table) {
    // Expand the table data to include jigs details.
    let tableData = Utils.expandTableDataWithJigDetails(table, allJigs);

    let svg = encodeTableToSvg(tableData),
      a = document.createElement('a'),
      url = window.URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
    a.href = url;
    a.download = tableData.name + '.svg';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  function closeErrorHandler() {
    setShowError(false)
  }

  function filterTable(table, searchText) {
    if (table.name) {
      const name = table.name.toLowerCase();
      if (name.includes(searchText.toLowerCase())) {
        return true;
      }
    }
    if (table.referenceText) {
      const refText = table.referenceText.toLowerCase();
      if (refText.includes(searchText.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  function openFiltersHandler() {
    setShowFilters(true);
  }

  return (<>
    {showError &&
      Utils.showErrorDialog(errorMessage, closeErrorHandler)}
    {showEditTable &&
      <EditTable editTableCloseHandler={editTableCloseHandler} previewTable={previewTable} scrollToBottom={scrollToBottom} />}
    {showFilters && <Filters onClose={e => {
      history.replace(`/${window.getResourceName()}/settings`);
    }} />}
    {showConfirmation &&
      <Confirm
        onClose={deleteTableCloseHandler}
        onConfirm={confirmTableDeleteHandler}
        title={t('button.delete')}
        message={t('tables.delete.message')}
        confirmLabel={t('button.delete')}
        cancelLabel={t('button.cancel')}
      />
    }
    <div className={classes.main}>
      <div className={classes.leftContainer}>
        <div className={classes.backButton}>
          <BackButton onBack={() => props.history.goBack()} />
        </div>
        <div className={classes.container}>
          <div className={classes.heading}>
            <h2> {t('tables.heading')} </h2>
            <div className={classes.primaryBtn}>
              <SearchField onChange={changeSearchTextHandler} label={t('label.search')} />
              {Utils.canEditTables(permissions) &&
                <PrimaryButton onClick={addTableHandler} label={t('tables.button.add')} />
              }
            </div>
          </div>
        </div>
        <div className={classes.jigsTable}>
          <div ref={tableStartRef} />
          <table className="table">
            <thead>
              <tr>
                <th style={{ "width": "10%" }}>{t('tables.id')}</th>
                <th style={{ "width": "30%", "textAlign": "left" }}>{t('tables.name')}</th>
                <th style={{ "width": "15%" }}>{Utils.appendUnits(t('tables.width'), unit)}</th>
                <th style={{ "width": "15%" }}>{Utils.appendUnits(t('tables.height'), unit)}</th>
                <th style={{ "width": "30%" }}>{t('tables.deleteTable')}</th>
              </tr>
            </thead>
            <tbody>
              {allTables && allTables.tables.map((table, index) => {
                if ((searchText.length < 3) || (searchText.length >= 3 && filterTable(table, searchText))) {
                  return (
                    <TableItem key={table.id} table={table} index={index}
                      onSelect={selectTableHandler} onDelete={deleteTableHandler}
                      onEdit={editTableHandler} onCopy={copyTableHandler}
                      onDownload={downloadTableHandler}
                      isActive={activeRow === index} />
                  );
                }
                return null;
              })}
            </tbody>
          </table>
          <div ref={tableEndRef} />
        </div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.rightHeader}>
          {Utils.canViewAutomationRules(permissions) && 
            <SecondaryButton onClick={openFiltersHandler} label={t('tables.button.openAutomationRules')} />
          }
        </div>
        <div className={classes.preview}>
          {selectedTable && <div> <h2 title={selectedTable.name}>{selectedTable.name} </h2>
            <p>Size - {Utils.formatMMSizeToUnits(selectedTable.width, selectedTable.height, unit)} </p></div>}
          {selectedTable && <TablePreview previewTable={selectedTable} showOrigin={false} showValidation={false} showImageBox={false} />}
        </div>
      </div>
    </div>
  </>
  );
}

export default Tables;
