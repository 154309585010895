import React from "react";

function PrimaryButton({label, style, type="button", onClick, disabled}) {
    return (
        <button type={type} onClick={onClick} disabled={disabled}
            className={`btn btn-md primaryBtn`} style={style}> {label}
        </button>
    );
}

export default PrimaryButton;