import React from "react";
import classes from "./OutputSettingSubContainer.module.css";
import TextButton from "../../components/Buttons/TextButton";
import Utils from "../../Utils";

function OutputSettingSubContainer({containerStyle, heading, headingChildren, style, withAddButton, children, className, addButtonLabel, addButtonClickHandler, disableAddButton}) {
    return( 
    <div className={classes.main} style={containerStyle}>
        <div className={classes.heading}>
            {heading && <h3>{heading}</h3>}
            {headingChildren}
        </div>
        <div className={Utils.getClasses(classes.body, className)} 
            style={{ ...style, height: withAddButton ? "calc(100% - 5rem)" : "calc(100% - 2.5rem)"}}>
            {children}
        </div>
        {withAddButton &&
            <div className={classes.btn}>
                <TextButton 
                    label={addButtonLabel}
                    onClick={addButtonClickHandler}
                    disabled={disableAddButton}
                />
            </div>
        }
    </div>);
}

export default OutputSettingSubContainer;