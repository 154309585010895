import React, { useRef } from 'react';
import { useContainerDimensions } from "../../../custom-hooks/ContainerDimensions";
import Utils from "../../../Utils";
import Consts from '../../../Consts';
import classes from "./OrderPreview.module.css";

let getSlotsMarkup = (slot, index, jigWidth, jigHeight, previewScale, jigKey) => {
    const imageBox = slot.imageBox;
    let placedWidth = imageBox.orderPhysicalWidth,
        placedHeight = imageBox.orderPhysicalHeight;

    let rotation = (180 + imageBox.rotation) % 360;
    if (rotation === 90 || rotation === 270) {
        placedWidth = imageBox.orderPhysicalHeight;
        placedHeight = imageBox.orderPhysicalWidth;
    }

    // Compute the translation to bring order image back into view post rotation.
    let rotateTransX = 0,
        rotateTransY = 0;
    if (rotation === 90) {
        rotateTransY = -placedWidth;
    }
    else if (rotation === 180) {
        rotateTransX = -placedWidth;
        rotateTransY = -placedHeight;
    }
    else if (rotation === 270) {
        rotateTransX = -placedHeight;
    }

    // Compute the translation to bring order image back into view post mirroring.
    let mirrorScaleX = 1,
        mirrorScaleY = 1,
        mirrorTransX = 0,
        mirrorTransY = 0;

    if (imageBox.mirror === "HORIZONTAL") {
        mirrorScaleX = -1;
        mirrorTransX = -placedWidth;
    }
    else if (imageBox.mirror === "VERTICAL") {
        mirrorScaleY = -1;
        mirrorTransY = -placedHeight;
    }

    let scaling = 1.0;
    if (imageBox.scale !== "TRUE") {
        let rx = imageBox.width / placedWidth;
        let ry = imageBox.height / placedHeight;
        let r;
        switch (imageBox.scale) {
            case "FIT_WIDTH":
                r = rx;
                break;
            case "FIT_HEIGHT":
                r = ry;
                break;
            case "FILL":
                r = Math.max(rx, ry);
                break;
            case "FIT":
            default:
                r = Math.min(rx, ry);
                break;
        }
        scaling = scaling * r;
    }

    placedWidth *= scaling;
    placedHeight *= scaling;

    let toImageX = 0,
        toImageY = 0;

    if (imageBox.scale === "TRUE" || imageBox.scale.includes("FIT")) {
        // Compute the translation from order to slot as per the slot placement (reference point).
        if (imageBox.placement.includes("LEFT")) {
            toImageX = imageBox.x + imageBox.width - placedWidth;
        }
        else if (imageBox.placement.includes("CENTER")) {
            toImageX = (imageBox.x + imageBox.width / 2) - placedWidth / 2;
        }
        else {
            toImageX = imageBox.x; // RIGHT
        }

        if (imageBox.placement.includes("TOP")) {
            toImageY = imageBox.y + imageBox.height - placedHeight;
        }
        else if (imageBox.placement.includes("MIDDLE")) {
            toImageY = (imageBox.y + imageBox.height / 2) - placedHeight / 2;
        }
        else {
            toImageY = imageBox.y; // BOTTOM
        }
    }
    else {
        // Compute the translation from center of order to center of slot.
        toImageX = (imageBox.x + imageBox.width / 2) - placedWidth / 2;
        toImageY = (imageBox.y + imageBox.height / 2) - placedHeight / 2;
    }

    const strokeWeight = `${1 / previewScale}px`;
    let slotPath = Utils.getSlotShapeMarkup(slot, Consts.SLOT_FILL_COLOR, Consts.SLOT_STROKE_COLOR, strokeWeight);
    let imageBoxPath = Utils.getSlotShapeMarkup(imageBox, Consts.IMAGE_BOX_FILL_COLOR, Consts.IMAGE_BOX_STROKE_COLOR, strokeWeight);
    let clipPathId = "mask_" + jigKey + "_" + index;

    return (
        <g key={clipPathId}>
            {slotPath}
            <g transform={`translate(${slot.x} ${slot.y})`}>
                <defs>
                    <clipPath id={clipPathId}>
                        {imageBoxPath}
                    </clipPath>
                </defs>
                {imageBoxPath}
                {slot.isBooked &&
                    <g clipPath={`url(#${clipPathId})`}>
                        <g transform={`translate(${toImageX}, ${toImageY})`}>
                            <g transform={`scale(${scaling})`}>
                                {/* First rotate then mirror the image*/}
                                <g transform={`scale(${mirrorScaleX} ${mirrorScaleY}) translate(${mirrorTransX}, ${mirrorTransY})`}>
                                    <image href={`${imageBox.orderThumbnailPath}?customer=${window.getResourceName()}`} transform={`rotate(${rotation}) translate(${rotateTransX}, ${rotateTransY})`} width={imageBox.orderPhysicalWidth} height={imageBox.orderPhysicalHeight} />
                                </g>
                            </g>
                        </g>
                    </g>
                }
            </g>
        </g>
    );
}

function OrderPreview({selectedTable, showLayout}) {
    const previewTable = showLayout ? selectedTable : (selectedTable?.tableData || selectedTable);
    function getJigName(jig) {
        return `${jig.name}`;
    }

    const containerRef = useRef();
    const { width, height } = useContainerDimensions(containerRef);

    let scaling = 1.0;
    const validTable = (previewTable.width && previewTable.height); // defined and not zero

    if (validTable) {
        const rx = width / previewTable.width;
        const ry = height / previewTable.height;
        scaling = rx < ry ? rx : ry;
        if (scaling === 0) {
            // On first preview render, we get width and height of preview container as 0.
            // This causes scaling to become 0, and result in Infinity results dividing by scaling.
            // We set the scaling back to 1.0 here.
            scaling = 1.0;
        }
    }

    const fontSize = `${0.75 / scaling}rem`;
    const strokeWeight = `${1 / scaling}px`;
    const dashLength = `${4 / scaling}px`;

    let getTableBoundsMarkup = () => {
        return <rect width={previewTable.width} height={previewTable.height} stroke={Consts.TABLE_STROKE_COLOR} strokeWidth={strokeWeight} fill={Consts.TABLE_FILL_COLOR} />;
    };

    let getTablePrintableBoundsMarkup = () => {
        const printArea = previewTable.printableArea;
        if (printArea && !isNaN(printArea.x) && !isNaN(printArea.y) && !isNaN(printArea.width) && !isNaN(printArea.height)) {
            return <rect x={printArea.x} y={printArea.y} width={printArea.width} height={printArea.height} stroke={Consts.TABLE_PRINTABLE_STROKE_COLOR} strokeWidth={strokeWeight} fill={Consts.TABLE_PRINTABLE_FILL_COLOR} strokeDasharray={dashLength} />;
        }
    };

    let jigs = previewTable.jigs || [];

    let getJigsMarkup = () => {
        return jigs.map((jig, index) => {
            let transformation = `translate(${jig.x}, ${jig.y})`;
            const jigKey = previewTable.id + "_jigPreview" + index;
            let slotsMarkup = jig.slots?.map((slot, index) => {
                return getSlotsMarkup(slot, index, jig.width, jig.height, scaling, jigKey);
            });

            return (
                <g key={jigKey} transform={transformation}>
                    <rect width={jig.width} height={jig.height} fill={Consts.JIG_FILL_COLOR} stroke={Consts.JIG_STROKE_COLOR} strokeWidth={strokeWeight} />
                    {slotsMarkup}
                    <g transform="scale(-1)">
                        <text x={-jig.width / 2} y={-jig.height / 2 + (10 / scaling)} alignmentBaseline="middle" textAnchor="middle" fontSize={fontSize} fontWeight="bold" fill={Consts.TEXT_COLOR}>{index + 1}</text>
                        <text x={-jig.width / 2} y={-jig.height / 2 - (10 / scaling)} alignmentBaseline="middle" textAnchor="middle" fontSize={fontSize} fill={Consts.TEXT_COLOR}>{getJigName(jig)}</text>
                    </g>
                </g>
            );
        });
    }

    const scaledTableWidth = scaling * previewTable.width;
    const scaledTableHeight = scaling * previewTable.height;

    return (
        <div className={classes.previewContainer} ref={containerRef}>
            {validTable && <svg width={scaledTableWidth + 10} height={scaledTableHeight + 10} >
                <g transform={`scale(${scaling})`}>
                    <g transform={`scale(-1) translate(${-previewTable.width} ${-previewTable.height})`}>
                        {getTableBoundsMarkup()}
                        {getJigsMarkup()}
                        {getTablePrintableBoundsMarkup()}
                    </g>
                </g>
            </svg>}
        </div>
    );

}

export default OrderPreview;