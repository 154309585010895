import axios from '../../axios';
import { logoutUser } from '../Auth/AuthActions';
import { usersActions } from "./UsersSlice";

export function getAllUsers(dispatch) {
  return axios({
    url: "/pax/user/allUsers",
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(usersActions.fetchUsers({ users: data.users || [] }));
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function updateUser(dispatch, data, isCurrentUser) {
  return axios({
    url: "/pax/user/edit",
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        delete data.password;
        if (!isCurrentUser) {
          dispatch(usersActions.updateUser({ user: data }));
        }
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function deleteUser(dispatch, data) {
  return axios({
    url: "/pax/user",
    method: "delete",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(usersActions.deleteUser({ id: data.id }));
      };
      return response;
    }).catch((error) => {
      return error;
    });
}

export function createUser(dispatch, data) {
  return axios({
    url: "/pax/user/create",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        let newdata = data;
        newdata.id = response.data.user.id;
        delete newdata.password;
        dispatch(usersActions.createUser({ user: newdata }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}


export function fetchProfile(dispatch) {
  return axios({
    url: "/pax/user/profile"
  }).then((response) => {
    const data = response.data;
    if (data.success) {
      delete data.success;
      dispatch(usersActions.fetchProfile(data));
    } else {
      return logoutUser();
    }
    return response;
  }).catch((error) => {
    return logoutUser();
  });
}