import React from "react";
import TextButton from "../Buttons/TextButton";
import Modal from "../Modal/Modal";
import classes from './MessageDialog.module.css'

function MessageDialog(props) {
    return (
        <Modal title={props.title} onClose={props.onClose}>
            <div style={{ "width": props.width, "height": props.height }}>
                <div className={classes.message}>
                    <p>{props.message}</p>
                </div>
                <footer className={classes.actions}>
                    <TextButton onClick={props.onClose} label={props.btnLabel.toUpperCase()} />
                </footer>
            </div>
        </Modal>
    );
}

export default MessageDialog;