import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Header from '../../../components/Header/Header';
import { logoutUser } from '../../Auth/AuthActions';
import { useEffect } from 'react';
import { fetchProfile } from '../../Users/UsersActions';
import { useDispatch } from 'react-redux';
import Customers from '../../Customers/Customers';

export default function ({ match: { url } }) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchProfile(dispatch);
    }, [dispatch]);

    return <div>
        <Header logoutUser={logoutUser} isAuth={true} url={url} />
        <Switch>
            <Route exact path="" component={Customers} />
        </Switch>
    </div>
}
