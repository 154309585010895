import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateMasters } from "./MasterActions";
import AddValue from "../../components/ValueList/AddValue";
import Modal from "../../components/Modal/Modal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import TextButton from "../../components/Buttons/TextButton";
import Utils from "../../Utils";
import classes from "./Master.module.css";
import MasterItem from "./MasterItem";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

const excludeMaster = new Set(['id', 'tenantName', "_id"]);

function Master({onClose, showSuccessCardHandler}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const allMasters = useSelector((state) => state.master.allMasters, shallowEqual);
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
    
    const [masters, setMasters] = useState(allMasters);
    const [selectedMaster, setSelectedMaster] = useState("qualityLevel");
    const [selectedMasterValues, setSelectedMasterValues] = useState(masters.qualityLevel || {});
    const [showAddValue, setShowAddValue] = useState(false);
    const [showError, setShowError] = useState(false);
    const disallowedValues = Object.values(selectedMasterValues).map((value) => value.toLowerCase());

    useEffect(() => {
        setSelectedMasterValues(masters[selectedMaster] || {});
    }, [selectedMaster]);

    useEffect(() => {
        setMasters((prevState) => {
            const obj = {
                ...prevState,
            }
            obj[selectedMaster] = selectedMasterValues;
            return obj;
        });
    }, [selectedMasterValues]);

    function addValueHandler(value) {
        setSelectedMasterValues((prevState) => {
            return {...prevState, [value]: value};
        });
        setShowAddValue(false);
    }

    function updateClickHandler() {
        updateMasters(dispatch, masters).then((response) => {
            if (response && response.data && response.data.success) {
                showSuccessCardHandler && showSuccessCardHandler(true);
                onClose && onClose();
            }
            else {
                setShowError(true);
            }
        });
    }

    function errorDialogeCloseHandler() {
        setShowError(false);
    }

    function isUpdateDisabled() {
        if (Utils.canEditMasters(permissions)) {
            return false;
        }

        return true;
    }

    return (
        <>
            {showError && Utils.showErrorDialog(t('master.error.updateFailed'), errorDialogeCloseHandler)}
            <Modal title={t('master.heading')} onClose={onClose}>
                <div className={classes.main}>
                    <h3>{t('master.addMaster')}</h3>
                    <div className={classes.selectionContainer}>
                        <select onChange={(e) => {
                            setSelectedMaster(e.target.value);
                        }}>
                            {masters && Object.keys(masters).map((key) => {
                                if (!excludeMaster.has(key)) {
                                    return <option key={key} value={key}>{Utils.getStringFromKey("actions." + key)}</option>
                                }
                                return null;
                            })}
                        </select>
                    </div>
                    <div className={classes.tableContainer}>
                        <div className={classes.table}>
                            <div className={classes.addValueContainer}>
                                {showAddValue && <AddValue onAdd={addValueHandler} onCancel={() => setShowAddValue(false)} disallowedValues={disallowedValues} />}
                            </div>
                            <div className={classes.tableItem} style={showAddValue ? { "height": "60%" } : { "height": "85%" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ "width": "20%", "textAlign": "left" }}>{t('master.id')}</th>
                                            <th style={{ "width": "45%", "textAlign": "left" }}>{t('master.name')}</th>
                                            <th style={{ "width": "35%", "textAlign": "center" }}>{t('master.actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(selectedMasterValues).map((item, index) => 
                                            <MasterItem item={selectedMasterValues[item]} index={index} 
                                                onDelete={idx => {
                                                    setSelectedMasterValues((prevState) => {
                                                        const values = {...prevState};
                                                        delete values[item];
                                                        return values;
                                                    });
                                                }} 
                                                onSave={value => {
                                                    setSelectedMasterValues((prevState) => {
                                                        const values = {...prevState};
                                                        values[item] = value;
                                                        return values;
                                                    });
                                                }} 
                                                disallowedValues={disallowedValues} 
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.valueListFooter}>
                                <div className={classes.addValueBtn}>
                                    <TextButton onClick={() => setShowAddValue(true)} label={t('button.addValue')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.actionBtn}>
                        <SecondaryButton onClick={onClose} label={t('button.cancel')} />
                        <PrimaryButton label={t('button.update')} onClick={updateClickHandler} disabled={isUpdateDisabled()}/>
                    </div>
                </div>
            </Modal>
        </>);
}

export default Master;