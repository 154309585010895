import axios from '../../axios';
import { customersActions } from "./CustomersSlice";

export function fetchCustomers(dispatch) {
  return axios({
    url: "/pax/customers"
  }).then((response) => {
    const data = response.data;
    if (data.success) {
      dispatch(customersActions.fetchCustomers(data.customers));
    }
    return response;
  });
}