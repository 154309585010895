import React, { useState, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Modal from "../../components/Modal/Modal";
import Password from "../../components/Password/Password";
import Permissions from "../../components/Permissions/Permissions";
import ActionItem from "../Filters/ActionItem";
import Utils from "../../Utils";
import classes from "./EditUser.module.css";
import { createUser, updateUser } from "./UsersActions";

const defaultAdminPermissions = Utils.getDefaultAdminPermissions();
const defaultPowerUserPermissions = Utils.getDefaultPowerUserPermissions();
const defaultOperatorPermissions = Utils.getDefaultOperatorPermissions();

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));

function EditUser(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const forNewOrEdit = (props.editType === "NEW" || props.editType === "EDIT");

    const allUsers = useSelector((state) => state.users.allUsers, shallowEqual) || [];
    const allLocations = useSelector((state) => state.master.allMasters.location, shallowEqual) || [];
    const [userDetails, setUserDetails] = useState(props.userDetails || { userId: "", role: "ADMIN" });
    const [passwordScore, setPasswordScore] = useState(0);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [permissions, setPermissions] = useState(props.userDetails?.permissions?.split(',') || []);

    function actionHandler() {
        if (props.editType === "NEW" || props.editType === "PASSWORD") {
            if (userDetails.password !== userDetails.confirmPassword) {
                setIsPasswordMatch(false);
            } else if (passwordScore >= 1) {
                let data = { ...userDetails, permissions: permissions.join(',') };
                if (props.editType === "PASSWORD") {
                    delete userDetails.role;
                    delete data.permissions
                }
                delete data.confirmPassword;
                (props.editType === "NEW" ? createUser : updateUser)(dispatch, data).then((response) => {
                    if (response && response.data && response.data.success) {
                        setShowError(false);
                        props.editUserCloseHandler();
                        if (props.editType === "NEW") {
                            props.responseHandler(true, t('successCard.message.userCreated'));
                            props.scrollToBottom();
                        }
                        else {
                            props.responseHandler(true, t('successCard.message.passwordUpdated'));
                        }
                    }
                    else {
                        if (props.editType === "NEW") {
                            setErrorMessage(t('users.error.createFailed'));
                        }
                        else {
                            setErrorMessage(t('users.error.updatePasswordFailed'));
                        }
                        setShowError(true);
                        props.responseHandler(false);
                    }

                });
            }
            else {
                setIsPasswordMatch(true);
            }
        }
        else {
            let data = { ...userDetails, permissions: permissions.join(',') };
            updateUser(dispatch, data).then((response) => {
                if (response && response.data && response.data.success) {
                    setShowError(false);
                    props.editUserCloseHandler();
                    props.responseHandler(true, t('successCard.message.userUpdated'));
                }
                else {
                    setErrorMessage(t('users.error.updateFailed'));
                    setShowError(true);
                    props.responseHandler(false);
                }

            })
        }
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    function setDefaultPermissions(role) {
        if (role === "ADMIN") {
            setPermissions(defaultAdminPermissions);
        } else if (role === "POWERUSER") {
            setPermissions(defaultPowerUserPermissions);
        } else {
            setPermissions(defaultOperatorPermissions);
        }
    }

    useEffect(() => {
        if (permissions.length === 0) {
            setDefaultPermissions(userDetails.role);
        }
    }, [userDetails.role]);

    function roleChangeHandler(e) {
        setUserDetails((prevState) => {
            return {
                ...prevState,
                role: e.target.value
            }
        });
        setDefaultPermissions(e.target.value);
    }

    function createOrUpdateHandler() {
        if (props.editType === "NEW") {
            if (Utils.validateEmail(userDetails.userId, allUsers?.users || []).valid) {
                actionHandler();
                setIsEmailValid(true);
                setEmailErrorMessage("");
            }
            else {
                setIsEmailValid(false);
                setEmailErrorMessage(Utils.validateEmail(userDetails.userId, allUsers?.users || []).message);
            }
        }
        else {
            actionHandler();
        }
    }

    function locationChangeHandler(action, value) {
        setUserDetails((prevState) => {
            return {
                ...prevState,
                location: value
            }
        });
    }

    function getLeftContainer() {
        return <>
        <div className={classes.leftContainer}>
            <label htmlFor="userId">{t('editUser.userId')}</label>
            <input type="email"
                id="userId"
                autoFocus={props.editType === "NEW"}
                onChange={(e) => setUserDetails((prevState) => {
                    return {
                        ...prevState,
                        userId: e.target.value
                    }
                })}
                value={userDetails.userId} readOnly={props.editType !== "NEW"} />
            {!isEmailValid && <div style={{ "color": "red" }} ><p> {emailErrorMessage} </p></div>}
            {forNewOrEdit &&
                <> <label htmlFor="role">{t('editUser.role')}</label>
                    <select id="role" onChange={roleChangeHandler} value={userDetails.role} >
                        <option value="ADMIN" > {t('edituser.selectAdmin')}</option>
                        <option value="POWERUSER">{t('edituser.selectPowerUser')}</option>
                        <option value="OPERATOR">{t('edituser.selectOperator')}</option>
                    </select>
                </>
            }
            {(props.editType === "PASSWORD" || props.editType === "NEW") &&
                <> <label htmlFor="password">{props.editType === "PASSWORD" ? t('editUser.newPassword') : t('editUser.password')}</label>
                    <Password id="password" autoFocus={props.editType === "PASSWORD"} autoComplete="new-password"
                        togglePasswordVisibility={true} width="12.5rem" height="2rem"
                        onChange={(e) =>
                            setUserDetails((prevState) => {
                                return {
                                    ...prevState,
                                    password: e.target.value
                                }
                            })
                        } />
                    <div className={classes.container}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <PasswordStrengthBar password={userDetails.password} scoreWords={[t('passwordStrength.weak'), t('passwordStrength.weak'), t('passwordStrength.okay'), t('passwordStrength.good'), t('passwordStrength.strong')]} minLength={4} shortScoreWord={t('passwordStrength.short')} onChangeScore={(score, feedback) => {
                                setPasswordScore(score);
                            }} />
                        </Suspense>
                    </div>
                    <label htmlFor="confirmPaswword">{t('editUser.confirmPassword')}</label>
                    <Password id="confirmPaswword"
                        togglePasswordVisibility={true} width="12.5rem" height="2rem"
                        onChange={(e) =>
                            setUserDetails((prevState) => {
                                return {
                                    ...prevState,
                                    confirmPassword: e.target.value
                                }
                            })} />
                    {!isPasswordMatch && <div style={{ "color": "red" }} ><p> {t('password.misMatchMsg')} </p></div>}
                </>
            }
        </div>
        </>
    }

    function getActions() {
        return <>
        <SecondaryButton onClick={() => props.editUserCloseHandler()} label={t('button.cancel')} />
        <PrimaryButton onClick={createOrUpdateHandler} label={props.editType === "NEW" ? t('button.create') : t('button.update')} />
        </>
    }

    return (<Modal onClose={() => props.editUserCloseHandler()} title={props.editType === "NEW" ? t('editUser.heading.new') : (props.editType === "PASSWORD" ? t('editUser.heading.password') : t('editUser.heading'))}>
        {showError &&
            Utils.showErrorDialog(errorMessage, closeErrorHandler)}
        {forNewOrEdit 
            ?
            <div className={classes.mainForNewOrEdit}>
                <div className={classes.bodyForNewOrEdit}>
                    {getLeftContainer()}
                    <div className={classes.middleContainer}>
                        <ActionItem name={t('actions.location')} 
                            action="location" 
                            items={allLocations} 
                            value={userDetails.location} 
                            onChange={locationChangeHandler} 
                            addValueLabel={t('button.addLocation')}
                            addButtonAtBottom={true}/>
                    </div>
                    <div className={classes.rightContainer}>
                        <label>{t("editUser.setAccessibility")}</label>
                        <Permissions permissions={permissions} setPermissions={(e) => setPermissions(e)}/>
                    </div>
                </div>
                <div className={classes.actionsForNewOrEdit}>
                    {getActions()}
                </div>
            </div>
            :
            <div className={classes.main}>
                <div className={classes.body}>
                    {getLeftContainer()}
                </div>
                <div className={classes.actions}>
                    {getActions()}
                </div>
            </div>
        }
    </Modal >
    )
}

export default EditUser;
