import React from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../Buttons/PrimaryButton";
import classes from "./HelpGuide.module.css";

function HelpGuide({message, onHide}) {
  const {t, i18n} = useTranslation();
  const title = t("helpGuide.title");
  let msg;
  if (message) {
    if (typeof message === "object") {
      msg = message[i18n.language];
    } else {
      msg = message;
    }
  }

  return (
    <div className={classes.main}>
      <div className={classes.content}>
        <div className={classes.title}>
          {title}
        </div>
        <div className={classes.body}>
          <div className={classes.bodyContent}>
            {msg || ""}
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        <PrimaryButton label={t("button.hideMessage")} onClick={onHide} />
      </div>
    </div>
  );
}

export default HelpGuide;
