import React, { useState } from "react";
import classes from './ReferencePoint.module.css';

const initialState = new Array(9).fill(false);
const valArr = [
    "TOP_LEFT", "TOP_CENTER", "TOP_RIGHT",
    "MIDDLE_LEFT", "MIDDLE_CENTER", "MIDDLE_RIGHT",
    "BOTTOM_LEFT", "BOTTOM_CENTER", "BOTTOM_RIGHT"
];

function ReferencePoint(props) {
    const [checked, setChecked] = useState(initialState);

    return (
        <div className={classes.main}>
            <div className={classes.grid}>
                {valArr.map((value, index) => {
                    checked[index] = (value === props.placement);
                    const key = `input_${props.id}_${value}`;
                    return (
                        <div key={key} className={classes.point}>
                            <input type="checkbox" id={key} className={classes.gridElements} value={value} onChange={(e) => {
                                setChecked((checkedArr) => {
                                    let temp = [...checkedArr];
                                    temp.fill(false);
                                    temp[index] = true;
                                    return temp;
                                });
                                props.onChange(e.target.value);
                            }} checked={checked[index]} disabled={props.disabled} />
                            <label for={key}></label>
                        </div>
                    );
                })}
            </div>
        </div >
    );
}

export default ReferencePoint;