import React, { useState, useEffect, useRef } from 'react';
import classes from './AutoComplete.module.css';

function AutoComplete(props) {
    const suggestions = props.suggestions || [];

    const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState('');
    const inputRef = useRef();

    useEffect(()=>{
        function checkIfClickedOutside(e) {
            if ( showSuggestions && inputRef.current && !inputRef.current.contains(e.target)) {
                setShowSuggestions(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }

    },[showSuggestions]);

    function onChangeHandler(e) {
        const value = e.currentTarget.value;
        const filtered = suggestions.filter(
            (suggestion) =>
                suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
        );
        setFilteredSuggestions(filtered);
        setShowSuggestions(true);
        setUserInput(value);
        props.valueChangedHandler(value);
    };

    function onClickHandler(e) {
        setShowSuggestions(false);
        setUserInput(e.currentTarget.innerText);
        props.valueChangedHandler(e.currentTarget.innerText);
    };

    function onFocusHandler(e) {
        setShowSuggestions(true);
    }

    let suggestionsListComponent = null;
    if (showSuggestions && filteredSuggestions.length !== 0) {
        suggestionsListComponent = (
            <div className={classes.autocompleteOuter}>
                <div className={classes.autocompleteItems}>
                    {filteredSuggestions.map((suggestion, index) => {
                        return (
                            <div key={suggestion} onClick={onClickHandler}>
                                {suggestion}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <div className={classes.autocomplete} ref={inputRef}>
            <input
                type="text"
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                value={userInput}
                autoComplete="off"
            />
            {suggestionsListComponent}
        </div>
    );
}
export default AutoComplete;