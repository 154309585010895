import React from "react";
import classes from "./SideMenuItem.module.css";

function SideMenuItem(props) {

    return <button className={classes.main} type={props.type || "button"} onClick={props.onClick}>
        {props.imageIcon && <div className={classes.iconImage} style={{
            backgroundImage: `url(${props.imageIcon})`,
        }}>
        </div>}
        <p>{props.title}</p>
    </button>
}

export default SideMenuItem;