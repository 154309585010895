import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function CustomerItem(props) {
    const customer = props.customer;
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <tr>
            <td style={{ "whiteSpace": "nowrap", "maxWidth": "0", "overflow": "hidden", "textOverflow": "ellipsis" }}
                title={customer.id}>
                {customer.id}
            </td>
            <td style={{ "whiteSpace": "nowrap", "maxWidth": "0", "overflow": "hidden", "textOverflow": "ellipsis" }} 
                title={customer.user}>
                {customer.user}
            </td>
            <td style={{ "whiteSpace": "nowrap", "maxWidth": "0", "overflow": "hidden", "textOverflow": "ellipsis" }}
                title={customer.resourceName}>
                {customer.resourceName}
            </td>
            <td>
                <a href="" onClick={e => {
                    window.setResourceName(customer.resourceName);
                    history.push(`/${customer.resourceName}`);
                }}>
                    {t('customers.customerItem.Link')}
                </a>
            </td>
        </tr>
    )
}

export default CustomerItem;