import axios from '../../axios';
import { ordersActions } from './OrdersSlice';
import { outputPresetsActions } from './OutputPresetSlice';

export function getOutputPresets(dispatch) {
    return axios({
        url: `/pax/outputpreset`,
        method: "get",
        responseType: "json",
    })
        .then((response) => {
            let data = response.data;
            if (data.success) {
                dispatch(outputPresetsActions.fetchOutputPresets({ outputPresets: data.outputPresets || [] }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function getAllOutputPresets(dispatch) {
    return axios({
        url: `/pax/outputpreset/allOutputPresets`,
        method: "get",
        responseType: "json",
    })
        .then((response) => {
            let data = response.data;
            if (data.success) {
                dispatch(outputPresetsActions.fetchOutputPresets({ outputPresets: data.outputPresets || [] }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function updateOutputPreset(dispatch, data) {
    return axios({
        url: "/pax/outputpreset",
        method: "put",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response && response.data && response.data.success) {
                if (data.orderData && data.presetData[0] && data.presetData[0].tableData) {
                    dispatch(ordersActions.assignOrder({ data: data.orderData }));
                } else if (!data.orderData && data.presetData[0] && data.presetData[0].tableData) {
                    dispatch(ordersActions.removeAssignmentFromOrders({ data: data.presetData[0].tableData.assignedOrders }));
                }
                // DoNot move this dispatch above if else condition otherwise it will loose table data
                dispatch(outputPresetsActions.updateOutputPreset({ outputPreset: data }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}