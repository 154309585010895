import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Password from "../../../components/Password/Password";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import classes from "./LoginForm.module.css";

function LoginForm({setEnvironmentName, handleLogin, type, environmentName}) {
 
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation();

  function loginHandler(e) {
    e.preventDefault();
    handleLogin(userId, password, type);
  };

  function passwordChangeHandler(e) {
    setPassword(e.target.value);
  }

  function getPathName() {
    let pathName = window.location.pathname;
    if (pathName.startsWith('/')) {
      pathName = pathName.substring(1);
    }
    return pathName;
  }

  const pathName = getPathName();
  const hasPathName = (type === "b2c" && pathName.length > 0);
  return (
    <div className={classes.main}>
      <div className="loginFormLogo" style={!hasPathName ? { "marginBottom" : "1rem" } : {}}></div>
      {/* {hasPathName &&
        <div className={classes.environment}>
          {`Welcome : ${pathName}`}
        </div>
      } */}
      <div className={classes.loginForm} autoComplete="on">
        {/* <h2>{t('loginForm.heading')}</h2> */}
        <label htmlFor="environment-name">{t('loginForm.environmentName')}</label>
        <div className={classes.environmentDiv}>
                    <div className={classes.inputBox}>
                        <input value={`${window.origin}/`} readOnly={true} style={{width:"100%", background:"transparent"}}/>
                        <input id="environment-name" 
                          value={environmentName}
                          onChange={(e) => setEnvironmentName(e.target.value.toLowerCase())} style={{width:"50%"}}/>
                       
                    </div>        
          </div>
        <label htmlFor="email"> {t('loginForm.username')}</label>
        <div className={classes.emailContainer}>
        <input
          id="email"
          type="email"
          autoComplete="username"
          autoFocus={true}
          className={classes["form-field"]}
          onChange={(e) => setUserId(e.target.value)}
        />
          <div className={classes.hint} title={t('loginForm.emailTooltip')}>
            <p>?</p>
          </div>
        </div>
        <label htmlFor="password"> {t('loginForm.password')}</label>
        <Password id="password" autoComplete="current-password"
          width="85%" height="2rem"
          onChange={passwordChangeHandler} togglePasswordVisibility={true} />
        <div className={classes["post-submit-button"]}>
          <PrimaryButton onClick={loginHandler} label={t('button.login')} type="Submit" style={{ "width": "85%", "height": "2rem" }} />
        </div>
      </div>
      <div className="rdgConnectLogo"/>
    </div>
  );
}

export default LoginForm;
