import React from 'react';
import CloseButton from '../Buttons/CloseButton';
import classes from './Modal.module.css';
import { createPortal } from 'react-dom';

const container = document.querySelector('#root');
function Modal({title, onClose, children, style}) {
    return createPortal(
        <>
            <div className={classes.backdrop} style={style}></div>
            <div className={classes.modal} style={style}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        <h2>{title}</h2>
                    </div>
                    <div className={classes.closeBtn}>
                        <CloseButton onClick={onClose} />
                    </div>
                </div>
                {children}
            </div>
        </>,
        container
    );
}

export default Modal;