import React, { useRef } from "react";
import Consts from "../../Consts";
import { useContainerDimensions } from "../../custom-hooks/ContainerDimensions";
import Utils from "../../Utils";
import classes from './JigPreview.module.css';
import boxAdornmentImage from '../../assets/box-adornment.png';

function JigPreview(props) {
    const containerRef = useRef();
    const { width, height } = useContainerDimensions(containerRef);

    const jig = props.previewJig;
    const validJig = (jig && jig.width && jig.height); // defined and not zero

    // Compute the scaling from jig to preview coordinates.
    let scaling = 1.0;
    if (validJig) {
        const rx = width / jig.width;
        const ry = height / jig.height;
        scaling = rx < ry ? rx : ry; // Fit the jig in available space
        if (scaling === 0) {
            // On first preview render, we get width and height of preview container as 0.
            // This causes scaling to become 0, and result in Infinity results dividing by scaling.
            // We set the scaling back to 1.0 here.
            scaling = 1.0;
        }
    }

    const slots = jig.slots || [];
    let overlapIndexes;
    if (props.showValidation) {
        overlapIndexes = Utils.overlapIndexes(slots, jig.width, jig.height);
    }

    const fontSize = `${0.75 / scaling}rem`;
    const strokeWeight = `${1 / scaling}px`;
    let placedWidth = 12 / scaling,
        placedHeight = 12 / scaling,
        paddingLeft = 5 / scaling,
        paddingTop = 5 / scaling;

    let getJigBoundsMarkup = () => {
        return <rect width={jig.width} height={jig.height} stroke={Consts.JIG_STROKE_COLOR} strokeWidth={strokeWeight} fill={Consts.JIG_FILL_COLOR} />;
    };

    let getSlotsMarkup = () => {
        return slots.map((slot, index) => {
            if (!isNaN(slot.x) && !isNaN(slot.y) && slot.width && slot.height) {
                const strokeColor = props.showValidation && overlapIndexes.has(index) ? Consts.STROKE_COLOR_WARN : Consts.SLOT_STROKE_COLOR;
                const slotKey = jig.id + "_slotPreview" + index;
                const strokeColorImageBox = props.showValidation && !Utils.isImageBoxInsideSlot(slot) ? Consts.STROKE_COLOR_WARN : Consts.IMAGE_BOX_STROKE_COLOR;
                const slotFillColor = (index === jig.focusedSlotIndex) ? Consts.FOCUSED_SLOT_FILL_COLOR : Consts.SLOT_FILL_COLOR;
                const imageBoxFillColor = (index === jig.focusedSlotIndex) ? Consts.FOCUSED_IMAGE_BOX_FILL_COLOR : Consts.IMAGE_BOX_FILL_COLOR;

                let rotation = slot.imageBox.rotation || 0;
                rotation = (180 + rotation) % 360;

                // Compute the translation to bring order back into view post rotation.
                let rotateTransX = 0,
                    rotateTransY = 0;
                if (rotation === 90) {
                    rotateTransY = -placedWidth;
                }
                else if (rotation === 180) {
                    rotateTransX = -placedWidth;
                    rotateTransY = -placedHeight;
                }
                else if (rotation === 270) {
                    rotateTransX = -placedHeight;
                }

                // Compute the translation to bring order image back into view post mirroring.
                let mirrorScaleX = 1,
                    mirrorScaleY = 1,
                    mirrorTransX = 0,
                    mirrorTransY = 0;

                if (slot.imageBox.mirror === "HORIZONTAL") {
                    mirrorScaleX = -1;
                    mirrorTransX = -placedWidth;
                }
                else if (slot.imageBox.mirror === "VERTICAL") {
                    mirrorScaleY = -1;
                    mirrorTransY = -placedHeight;
                }

                let toImageX = slot.imageBox.x;
                let toImageY = slot.imageBox.y;
    
                let [ypos, xpos] = slot.imageBox.placement.split("_");
    
                if (ypos === "BOTTOM") {
                    toImageY += paddingTop;
                } else if (ypos === "MIDDLE") {
                    toImageY += ((slot.imageBox.height - placedHeight) / 2);
                } else {
                    toImageY += (slot.imageBox.height - placedHeight - paddingTop);
                }
    
                if (xpos === "LEFT") {
                    toImageX += (slot.imageBox.width - placedWidth - paddingLeft);
                } else if (xpos === "CENTER") {
                    toImageX += ((slot.imageBox.width - placedWidth) / 2);
                } else {
                    toImageX += paddingLeft;
                }
                
                const imageBoxPath = Utils.getSlotShapeMarkup(slot.imageBox, imageBoxFillColor, strokeColorImageBox, strokeWeight);
                let clipPathId = "mask_" + index;
                return (
                    <g key={slotKey}>
                        {Utils.getSlotShapeMarkup(slot, slotFillColor, strokeColor, strokeWeight)}
                        <g transform={`translate(${slot.x} ${slot.y})`}>
                            <defs>
                                <clipPath id={clipPathId}>
                                    {imageBoxPath}
                                </clipPath>
                            </defs>
                            {imageBoxPath}
                            <g clipPath={`url(#${clipPathId})`}>
                                <g transform={`translate(${toImageX}, ${toImageY})`}>
                                    {/* First rotate then mirror the image */}
                                    <g transform={`scale(${mirrorScaleX} ${mirrorScaleY}) translate(${mirrorTransX}, ${mirrorTransY})`}>
                                        <image href={boxAdornmentImage} transform={`rotate(${rotation}) translate(${rotateTransX}, ${rotateTransY})`} width={placedWidth} height={placedHeight} />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <text transform="scale(-1)" x={-slot.x - (slot.width / 2)} y={-slot.y - (slot.height / 2)} alignmentBaseline="middle" textAnchor="middle" fontSize={fontSize} fontWeight="bold" fill={Consts.TEXT_COLOR}>{index + 1}</text>
                    </g>
                );
            }
            return null;
        });
    };

    const dashLength = `${4 / scaling}px`;

    let getJigPrintableBoundsMarkup = () => {
        const printArea = jig.printableArea;
        if (printArea && !isNaN(printArea.x) && !isNaN(printArea.y) && !isNaN(printArea.width) && !isNaN(printArea.height)) {
            return <rect x={printArea.x} y={printArea.y} width={printArea.width} height={printArea.height} stroke={Consts.TABLE_PRINTABLE_STROKE_COLOR} strokeWidth={strokeWeight} fill={Consts.TABLE_PRINTABLE_FILL_COLOR} strokeDasharray={dashLength} />;
        }
    };

    const scaledJigWidth = scaling * jig.width;
    const scaledJigHeight = scaling * jig.height;

    return (
        <div className={classes.previewContainer} style={props.style} ref={containerRef}>
            {validJig &&
                <svg width={scaledJigWidth + 10} height={scaledJigHeight + 10}>
                    <g transform={`scale(${scaling})`}>
                        <g transform={`scale(-1) translate(${-jig.width}, ${-jig.height})`}>
                            {getJigBoundsMarkup()}
                            {getSlotsMarkup()}
                            {props.showPrintableArea &&getJigPrintableBoundsMarkup()}
                            {props.showOrigin && Utils.getOriginMarkerMarkup(scaling)}
                        </g>
                    </g>
                </svg>}
        </div>
    );
}

export default JigPreview;