import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ValueList from '../../components/ValueList/ValueList';
import Utils from '../../Utils';
import classes from './ParameterMapping.module.css';

function ParameterMapping(props) {
    const { t } = useTranslation();
    const [mapping, setMapping] = useState(props.mapping || []);
    const paramsList = Utils.getSystemParameterListForMapping();

    function getValuesForParam(param) {
        const item = mapping.find(item => item.name === param);
        return item ? item.values : [];
    }

    function valuesChangedHandler(param, values) {
        setMapping((prevState) => {
            let newMapping = [...prevState];
            const index = newMapping.findIndex(item => item.name === param);
            if (index !== -1) {
                newMapping[index] = {
                    name: param,
                    values: values
                };
            } else {
                newMapping.push({
                    name: param,
                    values: values
                });
            }
            return newMapping;
        });
    }

    useEffect(() => {
        props.onChange && props.onChange(mapping);
    }, [mapping])

    return (<div className={classes.main}>
        <div className={classes.listContainer}>
            {paramsList.map((param) => {
                const title = t(param);
                return <div key={param} className={classes.valueList}>
                    <ValueList values={getValuesForParam(param)} title={title}
                        onChange={(values) => { valuesChangedHandler(param, values) }}
                        suggestions={props.suggestions} showHeader />
                </div>;
            })}
        </div>
    </div>);
}

export default ParameterMapping;