import { v4 as uuidv4 } from 'uuid';

const FilterUtils = {
    createDataNode: (value, dataNodes) => {
        const dataNode = {
            id: uuidv4(),
            value: value,
            actions: {
                location: "default",
                printerType: "default"
            },
        };
        dataNodes[dataNode.id] = dataNode;
        return dataNode;
    },

    createFilterNode: (filterKey, dataNodeId, filterNodes) => {
        const filterNode = {
            id: uuidv4(),
            key: filterKey,
            values: [{
                filterNodeId: null,
                dataNodeId: dataNodeId,
                isLinked: false
            }]
        };
        filterNodes[filterNode.id] = filterNode;
        return filterNode;
    },

    cloneDataNode: (dataNodeId, dataNodes) => {
        const dataNode = dataNodes[dataNodeId];
        const newNode = {
            id: uuidv4(),
            value: typeof(dataNode.value) === "object" ? [...dataNode.value] : dataNode.value,
            actions: {...dataNode.actions}
        };
        dataNodes[newNode.id] = newNode;
        return newNode.id;
    },

    cloneFilterNode: (filterNodeId, filterNodes, dataNodes) => {
        if (filterNodeId === null) {
            return null;
        }

        const filterNode = filterNodes[filterNodeId];
        const newNode = {
            id: uuidv4(),
            key: filterNode.key,
            values: []
        };

        let clonedNodes = {};
        filterNode.values.forEach((filterValue) => {
            let newValue = {};
            newValue.dataNodeId = FilterUtils.cloneDataNode(filterValue.dataNodeId, dataNodes);
            if (filterValue.filterNodeId !== null) {
                if (clonedNodes.hasOwnProperty(filterValue.filterNodeId)) {
                    newValue.filterNodeId = clonedNodes[filterValue.filterNodeId];
                } else {
                    newValue.filterNodeId = FilterUtils.cloneFilterNode(filterValue.filterNodeId, filterNodes, dataNodes);
                    clonedNodes[filterValue.filterNodeId] = newValue.filterNodeId;
                }
            } else {
                newValue.filterNodeId = null;
            }
            newValue.isLinked = filterValue.isLinked;
            newNode.values.push(newValue);
        });

        filterNodes[newNode.id] = newNode;
        return newNode.id;
    },

    getFilterValue: (filterNodeId, dataNodeId, filterNodes) => {
        const filterNode = filterNodes[filterNodeId];
        if (filterNode) {
            return filterNode.values.find((filterValue) => filterValue.dataNodeId === dataNodeId);
        }
        return null;
    },

    getSelectedFilterValue: (selection, filterNodes) => {
        if (selection.length === 0) {
            return null;
        }

        const last = selection[selection.length - 1];
        return FilterUtils.getFilterValue(last.filterNodeId, last.dataNodeId, filterNodes);
    },

    removeFilterNode: (filterNodeId, filterNodes, dataNodes) => {
        let filterNode = filterNodes[filterNodeId];
        if (filterNode) {
            filterNode.values.forEach((filterValue) => {
                delete dataNodes[filterValue.dataNodeId];
                if (filterValue.filterNodeId) {
                    FilterUtils.removeFilterNode(filterValue.filterNodeId, filterNodes, dataNodes);
                }
            });
            delete filterNodes[filterNodeId];
        }
    },

    deleteObject: (obj) => {
        for (const prop of Object.getOwnPropertyNames(obj)) {
            delete obj[prop];
        }
    },

    clearFilterTree: (decisionTree) => {
        decisionTree.rootFilterNodeId = null;

        let filterNodes = decisionTree.filterNodes;        
        decisionTree.filterNodes = {};
        FilterUtils.deleteObject(filterNodes);

        let dataNodes = decisionTree.dataNodes;
        decisionTree.dataNodes = {};
        FilterUtils.deleteObject(dataNodes);
    },

    createNodesMap: (nodes) => {
        let nodesMap = {};
        nodes.forEach((node) => {
            nodesMap[node.id] = node;
        });
        return nodesMap;
    },

    mapToArray: (nodesMap) => {
        let nodes = [];
        for (const prop of Object.getOwnPropertyNames(nodesMap)) {
            nodes.push(nodesMap[prop]);
        }
        return nodes;
    },

    getNodeValue: (nodeId, nodesMap) => {
        let node = nodesMap[nodeId];
        if (node) {
            if (typeof(node.value) === "object") {
                return node.value[0];
            } else {
                return node.value;
            }
        }
    },
}

export default FilterUtils;