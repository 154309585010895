import axios from '../../axios';
import { masterActions } from "./MasterSlice";

export function getAllMasters(dispatch) {
  return axios({
    url: "/pax/actions",
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(masterActions.fetchMasters({ masters: data.actions || {} }));
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function updateMasters(dispatch, data) {
  return axios({
    url: "/pax/actions",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(masterActions.fetchMasters({ masters: data }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function addMaster(dispatch, data) {
  return axios({
    url: "/pax/actions",
    method: "put",
    data: {
      [data.type]: data.value
    },
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(masterActions.addMaster({ allMasters: response.data.actions }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}
