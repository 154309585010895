import React from "react";
import classes from "./DeviceSettingCheckBox.module.css";

function DeviceSettingCheckBox(props) {
    return (
    <div className={classes.main}>
        <input type='checkbox'
            checked={props.value} 
            onChange={props.onChange}/>
        <span title={props.title}>
            {props.title}
        </span>
    </div>)
}

export default DeviceSettingCheckBox;