import React from "react";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addJig, updateJig } from "./JigsActions";
import { useTranslation } from "react-i18next";
import nextId from "react-id-generator";
import JigPreview from "./JigPreview";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import IconButton from "../../components/Buttons/IconButton";
import arrowUpImage from "../../assets/arrow-up.svg";
import arrowDownImage from "../../assets/arrow-down.svg";
import Modal from "../../components/Modal/Modal";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Utils from "../../Utils";
import AddMultipleSlots from "./AddMultipleSlots";
import classes from "./EditJigs.module.css";
import UnitInput from "../../components/UnitInput/UnitInput";
import EditJigItem from "./EditJigItem";
import SaveAs from "../../components/SaveAs/SaveAs";
import TableList from "./TableList";
import { updateTableList } from "../Tables/TablesActions";
import Confirm from "../../components/Confirm/Confirm";
import MultiSelectActionItem from "../../components/MultiSelectActionItem/MultiSelectActionItem";

const table = `table ${classes.table}`;

function EditJigs(props) {

    const unit = useSelector((state) => state.config.config.unit, shallowEqual);
    const allJigs = useSelector((state) => state.jigs.allJigs, shallowEqual);
    const allTables = useSelector((state) => state.tables.allTables.tables, shallowEqual);
    const allLocations = useSelector((state) => state.master.allMasters.location, shallowEqual) || [];
    const allPrinterTypes = useSelector((state) => state.master.allMasters.printerType, shallowEqual) || [];

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [name, setName] = useState(props.previewJig.name);
    const [width, setWidth] = useState(props.previewJig.width);
    const [height, setHeight] = useState(props.previewJig.height);
    const [thickness, setThickness] = useState(props.previewJig.thickness);
    const [defaultSpacing, setDefaultSpacing] = useState(props.previewJig.defaultSpacing || 5);
    const [showSaveAsDialog, setShowSaveAsDialog] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [tablesUsingJig, setTablesUsingJig] = useState([]);
    let slotIntialState = [];
    if (props.previewJig && props.previewJig.slots) {
        slotIntialState = props.previewJig.slots.map((slot, index) => {
            let obj = { ...slot }
            if (!slot.id) {
                obj.id = nextId();
            }
            return obj;
        })
    }

    const [slots, setSlots] = useState(slotIntialState);
    const [referenceText, setReferenceText] = useState(props.previewJig.referenceText);
    const [previewJig, setPreviewJig] = useState(props.previewJig);
    const [showMultipleSlotBtn, setShowMultipleSlotBtn] = useState(true);
    const [showAddMultiple, setShowAddMultiple] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showTableList, setShowTableList] = useState(false);
    const [focusedSlotIndex, setFocusedSlotIndex] = useState(-1);
    const [locations, setLocations] = useState(props.previewJig.locations || []);
    const [printerTypes, setPrinterTypes] = useState(props.previewJig.printerTypes || []);

    const maxWidthOrHeight = Utils.getMaxJigOrTableSize(unit);
    const isJigSourceSVG = (props.previewJig && props.previewJig.source === "SVG");

    let allJigNames = new Map();
    allJigs.jigs.forEach((jig) => {
        allJigNames.set(jig.name, true);
    });

    const jigBounds = {
        x: 0,
        y: 0,
        width: width,
        height: height
    };

    function getCopyName(name) {
        return Utils.getCopyName(allJigNames, name);
    }

    useEffect(() => {
        updatePreview();
    }, [width, height, slots, focusedSlotIndex]);

    function checkJig() {
        if (!slots || Utils.outsideBounds(slots, jigBounds) || 
            (!isJigSourceSVG && Utils.checkOverlap(slots))) { 
            return false;
        } else {
            return true;
        }
    }

    function saveJigHandler(isUpdate, jigName) {
        if (width > 0 && height > 0 && thickness >= 0 && checkJig()) {
            let slotData = slots.map((slot) => {
                let obj = { ...slot }
                delete obj.id;
                return obj;
            })
            const data = {
                name: name,
                width: width,
                height: height,
                thickness: thickness,
                slots: slotData,
                referenceText: referenceText,
                defaultSpacing: defaultSpacing,
                locations: locations,
                printerTypes: printerTypes,
                source: props.previewJig.source,
            };
            if (jigName) {
                data.name = jigName;
            }
            if (props.previewJig && isUpdate) {
                data.id = previewJig.id;
            }
            (props.previewJig && isUpdate ? updateJig : addJig)(dispatch, data).then((response) => {
                if (response && response.data && response.data.success) {
                    setShowError(false);
                    props.editJigsCloseHandler({ isAdded: !isUpdate, name: name });
                    if ((!props.previewJig || !isUpdate) && props.scrollToBottom) {
                        props.scrollToBottom();
                    }
                }
                else {
                    setShowError(true);
                }
            });
        }
    }

    function updateJigHandler(tableIds) {
        let slotData = slots.map((slot) => {
            let obj = { ...slot }
            delete obj.id;
            return obj;
        })
        const data = {
            name: getCopyName(name),
            width: width,
            height: height,
            thickness: thickness,
            slots: slotData,
            referenceText: referenceText,
            defaultSpacing: defaultSpacing,
            locations: locations,
            printerTypes: printerTypes,
            source: props.previewJig.source,
        };
        addJig(dispatch, data).then((response) => {
            if (response && response.data && response.data.success) {
                if (tableIds && Array.isArray(tableIds) && tableIds.length === 0) {
                    setShowError(false);
                    props.editJigsCloseHandler();
                    props.scrollToBottom();
                    return;
                }
                let newData = {
                    tableIds: tableIds,
                    currentJigId: props.previewJig.id,
                    newJigId: response.data.id,
                }
                updateTableList(dispatch, newData).then((response) => {
                    if (response && response.data && response.data.success) {
                        setShowError(false);
                        props.editJigsCloseHandler();
                        props.scrollToBottom();
                    }
                    else {
                        setShowError(true);
                    }
                });
            }
            else {
                setShowError(true);
            }
        });
    }

    function saveAsJigHandler(jigName) {
        saveJigHandler(false, jigName);
        setShowSaveAsDialog(false);
    }

    function imageBoxOutsideCheck() {
        let isOutside = false;
        isOutside = slots.some((slot) => !Utils.isImageBoxInsideSlot(slot));
        return isOutside;
    }

    function shouldSaveDisable() {
        if (!name || !slots.length | !width || !height || !(thickness >= 0) ||
            Utils.outsideBounds(slots, jigBounds) || (!isJigSourceSVG && imageBoxOutsideCheck()) ||
            (!isJigSourceSVG && Utils.checkOverlap(slots)) || 
            (props.previewJig.name !== name && isNameExist(name))) {
            return true;
        }
        return false;
    }

    function modalCloseHandler() {
        props.editJigsCloseHandler();
    }

    function updatePreview() {
        if (width > 0 && height > 0) {
            setPreviewJig((prevState) => {
                let temp = { ...prevState };
                temp.width = width;
                temp.height = height;
                temp.slots = [...slots];
                temp.focusedSlotIndex = focusedSlotIndex;
                return temp;
            });
            if (slots.length === 0 && !isJigSourceSVG) {
                setShowMultipleSlotBtn(true);
            }
            else {
                setShowMultipleSlotBtn(false);
            }
        }
        else {
            setShowMultipleSlotBtn(false);
        }
    }

    function nameChangeHandler(e) {
        setName(e.target.value);
    }


    function widthChangeHandler(value) {
        setWidth(value);
    }

    function heightChangeHandler(value) {
        setHeight(value);
    }

    function thicknessChangeHandler(value) {
        setThickness(value);
    }

    function referenceTextChangeHandler(e) {
        setReferenceText(e.target.value);
    }

    function defaultSpacingChangeHandler(value) {
        setDefaultSpacing(value);
    }

    function getMinX() {
        let minX = Infinity;
        for (let ind = 0; ind < slots.length; ind++) {
            minX = Math.min(minX, slots[ind].x);
        }
        return minX;
    }

    function getMaxY() {
        let maxY = 0;
        for (let ind = 0; ind < slots.length; ind++) {
            maxY = Math.max(maxY, slots[ind].y + slots[ind].height);
        }
        return maxY;
    }

    function getSlotAtEnd() {

        function hasOverlapInYAxis(slotA, slotB) {
            const bottom = Math.max(slotA.y, slotB.y);
            const top = Math.min(slotA.y + slotA.height, slotB.y + slotB.height);
            if (bottom <= top) {
                return true;
            }
            return false;
        }

        if (slots.length) {
            let slotAtEnd = slots[0];
            for (let ind = 1; ind < slots.length; ind++) {
                if (hasOverlapInYAxis(slots[ind], slotAtEnd) && 
                        slots[ind].x > slotAtEnd.x) {
                   // If slots are overlapping in y axis, consider them in same row
                   // choose slot which has greater x
                    slotAtEnd = slots[ind];
                } else if (slots[ind].y > slotAtEnd.y) {
                    // If no overlapping in y axis, choose slot which has greater y
                    slotAtEnd = slots[ind];
                }
            }
            return slotAtEnd;
        }

        return null;
    }

    function positionNewSlot(property, slot) {
        if (slots.length) {
            const lastSlot = getSlotAtEnd();
            if (lastSlot.x + 2 * lastSlot.width + defaultSpacing <= width) {
                slot.x = lastSlot.x + lastSlot.width + defaultSpacing;
                slot.y = lastSlot.y;
            } else {
                slot.x = getMinX();
                slot.y = getMaxY() + defaultSpacing;
            }

            if (property === "add") {
                slot.width = lastSlot.width;
                slot.height = lastSlot.height;
                slot.imageBox.x = lastSlot.imageBox.x;
                slot.imageBox.y = lastSlot.imageBox.y;
                slot.imageBox.width = lastSlot.imageBox.width;
                slot.imageBox.height = lastSlot.imageBox.height;
            }
        }
    }

    function addSlotHandler() {
        setSlots((slots) => {
            const obj = {
                x: defaultSpacing,
                y: defaultSpacing,
                width: width / 5,
                height: height / 5,
                imageBox: {
                    x: 0,
                    y: 0,
                    width: width / 5,
                    height: height / 5,
                    rotation: 0,
                    mirror: "NONE",
                    scale: "TRUE",
                    placement: "BOTTOM_RIGHT",
                },
                id: nextId()
            }
            // Position the newly added slot.
            positionNewSlot("add", obj);
            return [...slots, obj];
        });
    }

    function copySlotHandler(index) {
        setSlots((slots) => {
            const obj = { ...slots[index], id: nextId() }
            // Position the copied slot
            positionNewSlot("copy", obj);
            return [...slots, obj];
        });
    }

    function addMultiSlotsAddHandler(slots) {
        setSlots((prevState) => {
            return [...prevState, ...slots];
        });
        setShowAddMultiple(false);
    }

    function addMultiSlotsCloseHandler() {
        setShowAddMultiple(false);
    }

    function addMultiSlotBtnHandler() {
        setShowAddMultiple(true);
    }

    function isNameExist(name) {
        let nameExist = false;
        allJigs.jigs.forEach((jig) => {
            if ((name && jig.name === name.trim())) {
                nameExist = true;
            }
        });
        return nameExist;
    }

    function closeErrorHandler() {
        setShowError(false)
    }

    function slotChangeHandler(property, value, index) {
        if (property === "delete") {
            blurSlotHandler();
            setSlots((prevState) => {
                let slots = [...prevState];
                slots.splice(index, 1);
                return slots;
            });
        }
        else {
            setSlots((prevState) => {
                let slots = [...prevState];
                slots[index] = {
                    ...slots[index],
                };
                slots[index][property] = value;
                return slots;
            });
        }
    }

    function imageBoxChangeHandler(property, value, index) {
        setSlots((prevState) => {
            let slots = [...prevState];
            slots[index] = {
                ...slots[index]
            };

            let slot = slots[index];
            slot.imageBox = {
                ...slot.imageBox
            };
            if (property === "scale" && value === "FILL") {
                slot.imageBox.placement = "MIDDLE_CENTER";
            }
            slot.imageBox[property] = value;
            return slots;
        });
    }

    function saveClickHandler() {
        if (props.previewJig && props.previewJig.id) {
            const jigId = props.previewJig.id;
            const tables = Utils.getTablesUsingJig(allTables, jigId);
            if (tables.length) {
                setTablesUsingJig(tables);
                setShowTableList(true);
            }
            else {
                saveJigHandler(true);
            }
        }
        else {
            saveJigHandler(false);
        }
    }

    function deleteAllSlotsHandler() {
        setShowConfirmation(true);
    }

    function deleteAllSlotsCloseHandler() {
        setShowConfirmation(false);
    }

    function confirmDeleteAllSlotsHandler() {
        setShowConfirmation(false);
        blurSlotHandler();
        setSlots(slots => []);
    }

    function isDeleteAllSlotsEnabled() {
        if (!isJigSourceSVG && slots && slots.length) {
            return true;
        }
        return false;
    }

    function isAddMultipleSlotsEnabled() {
        if (!isJigSourceSVG && width && height && (slots.length <= 1 || showMultipleSlotBtn)) {
            return true;
        }
        return false;
    }

    function focusSlotHandler(index) {
        setFocusedSlotIndex(index);
    }

    function blurSlotHandler() {
        setFocusedSlotIndex(-1);
    }

    function locationsChangeHandler(value) {
        setLocations([...value]);
    }

    function printerTypesChangeHandler(value) {
        setPrinterTypes([...value]);
    }

    function moveNodeHandlerForSlots(index, direction) {
        if (canMoveNodeInSlots(index, direction)) {
            let newIndex = index + (direction === 'up' ? -1 : 1);
            setSlots((prevValues) => {
                let values = [...prevValues];
                values[index] = prevValues[newIndex];
                values[newIndex] = prevValues[index];
                return values;
            });
            setFocusedSlotIndex(newIndex);
        }
    }

    function canMoveNodeInSlots(index, direction) {
        if (index < 0) {
            return false;
        }
        let length = slots.length;
        let disallowMove = (index === 0 && direction === 'up') || // First value can't move up
            (index === (length - 1) && direction === 'down'); // Last value can't move down
        return !disallowMove;
    }

    return (
        <Modal onClose={modalCloseHandler} title={previewJig ? t('editJig.heading.edit') : t('editJig.heading.add')}>
            {showError && Utils.showErrorDialog(t('jigs.error.saveFailed'), closeErrorHandler)}
            {showAddMultiple &&
                <AddMultipleSlots onAdd={addMultiSlotsAddHandler} onClose={addMultiSlotsCloseHandler} width={width}
                    height={height} slots={slots} margin={defaultSpacing} />}
            {showSaveAsDialog && <SaveAs onClose={() => setShowSaveAsDialog(false)} onSave={saveAsJigHandler} name={getCopyName(name)} checkName={isNameExist} />}
            {showTableList && <TableList onClose={() => setShowTableList(false)} tables={tablesUsingJig} saveJigHandler={saveJigHandler} updateJigHandler={updateJigHandler} />}
            {showConfirmation &&
                <Confirm title={t('button.delete')} onClose={deleteAllSlotsCloseHandler} onConfirm={confirmDeleteAllSlotsHandler}
                    message={t('jigs.allSlots.delete.message')} confirmLabel={t('button.delete')} cancelLabel={t('button.cancel')} />}
            <div className={classes.main}>
                <div className={classes.leftContainer}>
                    <h3> {t('editJig.jigEntry.heading')} </h3>
                    <div className={classes.jigFields}>
                        <div className={classes.jigField}> <span className={classes.required}>{'* '}</span><span>{t('editJig.jigEntry.name')}</span> </div>
                        <div className={classes.jigField}> <input type="text"
                            name="name"
                            onChange={nameChangeHandler}
                            defaultValue={name} />
                        </div>
                        {props.previewJig.name !== name && isNameExist(name) && <p style={{ color: "red" }}> {t('editJig.jigEntry.sameNameMsg')}</p>}
                        <div className={classes.jigField}> <span className={classes.required}>{'* '}</span><span>{Utils.appendUnits(t('editJig.jigEntry.width'), unit)}</span> </div>
                        <div className={classes.jigField}>
                            <UnitInput name="width"
                                onChange={widthChangeHandler}
                                min={0}
                                value={width}
                                maxValue={maxWidthOrHeight}
                            />
                        </div>
                        <div className={classes.jigField}> <span className={classes.required}>{'* '}</span><span>{Utils.appendUnits(t('editJig.jigEntry.height'), unit)}</span> </div>
                        <div className={classes.jigField}>
                            <UnitInput name="height"
                                onChange={heightChangeHandler}
                                min={0}
                                value={height}
                                maxValue={maxWidthOrHeight}
                            />
                        </div>
                        <div className={classes.jigField}> <span className={classes.required}>{'* '}</span><span>{Utils.appendUnits(t('editJig.jigEntry.thickness'), unit)}</span> </div>
                        <div className={classes.jigField}>
                            <UnitInput name="thickness"
                                onChange={thicknessChangeHandler}
                                min={0}
                                value={thickness}
                            />
                        </div>
                        <div className={classes.jigField}> <span>{t('editJig.jigEntry.referenceText')}</span> </div>
                        <div className={classes.jigField}> <input type="text"
                            name="referenceText"
                            onChange={referenceTextChangeHandler}
                            defaultValue={referenceText} />
                        </div>
                        <div className={classes.jigField}> <span>{Utils.appendUnits(t('editJig.jigEntry.defaultSpacing'), unit)}</span> </div>
                        <div className={classes.jigField}>
                            <UnitInput name="defaultSpacing"
                                onChange={defaultSpacingChangeHandler}
                                value={defaultSpacing}
                            />
                        </div>
                        <div className={classes.jigField}>
                            <span className={classes.required}>{'*'}</span><span style={{ whiteSpace: 'pre' }}>{' - '}</span><span>{t('label.requiredField')}</span>
                        </div>
                    </div>
                </div>
                <div className={classes.middleContainer}>
                    <div className={classes.middleContainerHeader}>
                        <h3>{t('editJig.slotsEntry.heading')} </h3>
                        <div className={classes.arrowBtns}>
                            <IconButton onClick={() => moveNodeHandlerForSlots(focusedSlotIndex, 'up')} iconImage={arrowUpImage} 
                                disabled={!canMoveNodeInSlots(focusedSlotIndex, 'up')}/>
                             <IconButton onClick={() => moveNodeHandlerForSlots(focusedSlotIndex, 'down')} iconImage={arrowDownImage} 
                                disabled={!canMoveNodeInSlots(focusedSlotIndex, 'down')}/>
                        </div>
                    </div>
                    <div className={classes.slotTable}>
                        <table className={table}>
                            <thead>
                                <tr style={{ "lineHeight": "0.75rem" }}>
                                    <th colSpan={5} style={{ "border": "none" }}>
                                    </th>
                                    <th colSpan={8} style={{ "textAlign": "center" }}>
                                        {t('editJig.slotEntry.imageBoxHeading')}
                                    </th>
                                </tr>
                                <tr>
                                    <th style={{ "width": "3%" }}> {t('editJig.slotsEntry.id')} </th>
                                    <th style={{ "width": "7.25%" }}> {Utils.appendUnits(t('editJig.slotsEntry.x'), unit)} </th>
                                    <th style={{ "width": "7.25%" }}> {Utils.appendUnits(t('editJig.slotsEntry.y'), unit)} </th>
                                    <th style={{ "width": "7.25%" }}> {Utils.appendUnits(t('editJig.slotsEntry.width'), unit)} </th>
                                    <th style={{ "width": "8.25%", "paddingRight": "1%" }}> {Utils.appendUnits(t('editJig.slotsEntry.height'), unit)} </th>
                                    <th style={{ "width": "8.25%", "paddingLeft": "1%" }}> {Utils.appendUnits(t('editJig.slotsEntry.imageBoxX'), unit)} </th>
                                    <th style={{ "width": "7.25%" }}> {Utils.appendUnits(t('editJig.slotsEntry.imageBoxY'), unit)} </th>
                                    <th style={{ "width": "7.25%" }}> {Utils.appendUnits(t('editJig.slotsEntry.imageBoxWidth'), unit)} </th>
                                    <th style={{ "width": "7.25%" }}> {Utils.appendUnits(t('editJig.slotsEntry.imageBoxHeight'), unit)} </th>
                                    <th style={{ "width": "7.25%" }}> {t('editJig.slotsEntry.rotation')} </th>
                                    <th style={{ "width": "7.25%" }}> {t('editJig.slotsEntry.mirror')} </th>
                                    <th style={{ "width": "7.25%" }}> {t('editJig.slotsEntry.scale')} </th>
                                    <th style={{ "width": "5.25%" }}> {t('editJig.slotsEntry.placement')} </th>
                                    <th style={{ "width": "10%" }}> {t('editJig.slotsEntry.action')} </th>
                                </tr>
                            </thead>
                            <tbody>{
                                slots && slots.map((slot, index) => {
                                    return <EditJigItem key={slot.id} slot={slot} index={index} onChange={slotChangeHandler}
                                        onChangeImageBox={imageBoxChangeHandler} onCopySlot={copySlotHandler}
                                        onFocus={focusSlotHandler} onBlur={blurSlotHandler}
                                        isActive={focusedSlotIndex === index} focusedSlotIndex={focusedSlotIndex} disabled={isJigSourceSVG} />
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.deleteAllSlotsBtn}>
                        <div> <SecondaryButton onClick={deleteAllSlotsHandler} label={t('jigs.allSlots.button.delete')} disabled={!isDeleteAllSlotsEnabled()} /> </div>
                    </div>
                    <div className={classes.addSlotBtn}>
                        <div style={{ "marginRight": "0.5rem" }}><SecondaryButton onClick={addMultiSlotBtnHandler} label={t('editJig.slotEntry.addMultiBtn')} disabled={!isAddMultipleSlotsEnabled()} /> </div>
                        <div> <SecondaryButton onClick={addSlotHandler} label={t('editJig.slotEntry.addBtn')} disabled={isJigSourceSVG} /></div>
                    </div>
                </div>
                <div className={classes.rightContainer}>
                    <div className={classes.previewHeading}>
                        <h3>{t('preview.heading')}</h3>
                    </div>
                    <div className={classes.previewContents}>
                        <JigPreview previewJig={previewJig} showOrigin={true} showValidation={true} style={{"height":"70%"}} />
                        <div className={classes.previewMessage}>
                            {slots && (Utils.outsideBounds(slots, jigBounds) ? <p>{t('jigs.error.slotsExceedJig')}</p> : (!isJigSourceSVG && Utils.checkOverlap(slots) && <p>{t('jigs.error.slotsOverlap')}</p>))}
                        </div>
                        <MultiSelectActionItem  
                            name={t('actions.location')} 
                            action="location" 
                            items={allLocations} 
                            selectedItems={locations} 
                            selectItemsTitle={t('selectItems.location')} 
                            onChange={locationsChangeHandler}
                            style={{"marginTop": "0.7rem"}} />
                        <MultiSelectActionItem 
                            name={t('actions.printerType')} 
                            action="printerType" 
                            items={allPrinterTypes} 
                            selectedItems={printerTypes} 
                            selectItemsTitle={t('selectItems.printerType')} 
                            onChange={printerTypesChangeHandler} 
                            style={{"marginTop": "0.3rem"}} />
                    </div>
                    <div className={classes.actions}>
                        {props.previewJig && <div className={classes.saveAsBtn}>
                            <SecondaryButton onClick={() => setShowSaveAsDialog(true)} label={t('button.saveAs')} disabled={shouldSaveDisable()} />
                        </div>
                        }
                        <SecondaryButton onClick={modalCloseHandler} label={t('button.cancel')} />
                        <PrimaryButton disabled={shouldSaveDisable()} onClick={saveClickHandler} label={t('button.save')} />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default EditJigs;