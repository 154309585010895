import React, { useEffect, useState } from "react";
import classes from './OutputPreset.module.css';
import SettingBox, { Select } from "./SettingBox";
import { useTranslation } from "react-i18next";
import { isHotFolderChannel } from "./ChannelSelection";

export const actionAtInput = [
    {
        label: "preset.useVersaworksSetting",
        value: ''
    },
    {
        label: "orders.action.doNothing",
        value: 'nothing'
    },
    {
        label: "orders.action.rip",
        value: 'rip'
    },
    {
        label: "orders.action.ripAndPrint",
        value: 'ripAndPrint'
    }
];

export const returnToOrigOpts = [
    {
        label: "preset.useVersaworksSetting",
        value: ''
    },
    {
        label: "True",
        value: 'true'
    },
    {
        label: "False",
        value: 'false'
    }
];

export default function NameSelection({ onInfoChange, outputPreset }) {
    const {t} = useTranslation();
    const [returnToOrig, setReturnToOrig] = useState(outputPreset.returnToOrig);
    const [action, setAction] = useState(outputPreset.action || actionAtInput[0].value);
    const [name, setName] = useState(outputPreset.name || '');

    useEffect(() => {
        onInfoChange && onInfoChange({returnToOrig, action, name});
    }, [returnToOrig, action, name])

    return <div className={`${classes.box} ${classes.nameSelectionLayoutMain}`}>
        <div>
        { 
            !isHotFolderChannel(outputPreset?.outputChannel) &&
            <SettingBox title={t("orders.operationCommand")} containerStyle={{height:"100%"}} >
                <div style={{padding: 10, height: 'unset'}}>
                    <Select label={t("orders.returnToOrigin")} options={returnToOrigOpts.map(i => ({...i, label: t(i.label)}))} defaultOption={false}
                        value={returnToOrig} onChange={e => setReturnToOrig(e ? JSON.parse(e) : undefined)} />
                </div>
                <div style={{padding: 10, height: 'unset'}}>
                    <Select label={t("orders.action")} defaultOption={false}
                        options={actionAtInput.map(i => ({...i, label: t(i.label)}))} value={action} 
                        onChange={e => setAction(e ? e : undefined)} />
                </div>
            </SettingBox>
        }
        </div>
        <div className={classes.outputPresetName}>
            <label htmlFor="outputPresetName">{t("orders.nameOutputPreset")}</label>
             <input id="outputPresetName" type="text" onChange={e => setName(e.target.value)} value={name} />
        </div>
    </div>
}