import axios from '../../axios';
import { filtersActions } from "./FiltersSlice";

export function getAllFilters(dispatch) {
    return axios({
        url: "/pax/filters/tree",
        method: "get",
        responseType: "json",
    })
        .then(function (response) {
            let data = response.data;
            if (data.success) {
                dispatch(filtersActions.fetchFilters({ decisionTree: data.decisionTree || {} }));
            };
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function updateDecisionTree(dispatch, data) {
    return axios({
        url: "/pax/filters/tree",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                dispatch(filtersActions.updateDecisionTree({ decisionTree: data }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}
