import React from "react";
import classes from "./Icon.module.css";

function Icon(props) {
    return (
        <div className={classes.icon}
            style={{width: props.width, height: props.height, backgroundImage: `url(${props.image})`}}>
        </div>
    );
}

export default Icon;
