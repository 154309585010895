import React from "react";
import { useTranslation } from "react-i18next";
import SliderButton from "../../components/Buttons/SliderButton";
import IconButton from "../../components/Buttons/IconButton";
import editOutlineImage from "../../assets/edit-outline.svg";
import classes from "./ToggleSetting.module.css";

function ToggleSetting(props) {
  const { t } = useTranslation();
  return (
    <div className={classes.main}>
      <div className={classes.leftContainer}>
        <div className={classes.actions}>
          {
            props.canEdit && 
            <IconButton 
              onClick={props.onEdit}
              iconImage={editOutlineImage}
              tooltip={props.editTooltip ? props.editTooltip : t('tooltip.edit')}
              altText="Edit" />
          }
        </div>
        <div className={classes.text}>
          <p>{props.title}</p>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <SliderButton onChange={props.onChange} checkedLabel={props.checkedLabel} uncheckedLabel={props.uncheckedLabel} checked={props.checked} />
      </div>
    </div>
  );
}

export default ToggleSetting;
