import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { logoutUser } from '../../Auth/AuthActions';
import { fetchProfile } from '../../Users/UsersActions';
import { useDispatch } from 'react-redux';
import { createEnvironment } from './CreateEnvironmentActions';
import Header from '../../../components/Header/Header';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import Utils from '../../../Utils';
import Consts from '../../../Consts';
import classes from './CreateEnvironment.module.css';
import logo from "../../../assets/RolandDGConnect-PrintAutoMate-black.png"

function CreateEnvironment(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [environmentName, setEnvironmentName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        fetchProfile(dispatch);
    }, [dispatch]);

    function getErrorMessage (e) {
        switch (e) {
            case Consts.ERROR_CUSTOMER_ALREADY_EXISTS :
                return t("createEnvironment.error.environmentAlreadyExists");
            case Consts.ERROR_SYSTEM_KEYWORD : 
                return String.format(t("createEnvironment.error.systemKeyword"), environmentName);
            case Consts.ERROR_INVALID_ENVIRONMENT_NAME : 
                return t("createEnvironment.error.invalidName");
            default : 
                return t("createEnvironment.error.creationFailed");
        }
    }

    function createEnvironmentHandler() {
        const data = {
            resourceName : environmentName
        }
        createEnvironment(data).then((response) => {
           try {
            if (response.data.success) {
                setErrorMessage('');
                setShowError(false);
            } else {
                let message = getErrorMessage(response.data.errorCode);
                setErrorMessage(message);
                setShowError(true); 
            }
           } catch(e) {
            console.log(e);
           }
		});
    }

    function closeErrorHandler() {
        setErrorMessage('');
		setShowError(false);
	}

    function isCreateDisabled() {
        if (environmentName === "") {
            return true;
        }
        return false;
    }

    return<>
    {showError &&
        Utils.showErrorDialog(errorMessage, closeErrorHandler)} 
    <div>
        <Header logoutUser={logoutUser} isAuth={true} />
        <h3 className={classes.environmentHeading}>{t("createEnvironment.title")}</h3>
        <div className={classes.main}>
            <div className={classes.htmlViewerContainer}>
                <div className={classes.htmlViewer}>
                    <iframe src="/marketing/marketing.html"></iframe>
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.logoContainer}>
                    <img src={logo} alt='roland'/>
                </div>
                    <label htmlFor="environment-name"  className={classes.createEnvironmentHeading}>
                        {t("createEnvironment.label.createEnvironment")}
                    </label>
                <div className={classes.environmentDiv}>
                    <div className={classes.inputBox}>
                        <input value={`${window.origin}/`} readOnly={true} style={{width:"100%", background:"transparent"}}/>
                        <input id="environment-name" 
                            onChange={(e) => setEnvironmentName(e.target.value.toLowerCase())} style={{width:"50%"}}/>
                       
                    </div>
                  
                </div>
                <PrimaryButton label={t("button.create")} 
                    onClick={createEnvironmentHandler}
                    disabled={isCreateDisabled()}/>
                <div className={classes.instructions}>
                    <span className={classes.required}>{"* "}</span><span>{t("createEnvironment.instructions")}</span>
                </div>
            </div>
        </div>
    </div>
    </>
}

export default CreateEnvironment;