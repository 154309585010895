import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../Buttons/PrimaryButton';
import Modal from '../Modal/Modal';
import classes from './SaveAs.module.css';

function SaveAs(props) {

    const [name, setName] = useState(props.name);
    const showError = props.checkName(name);
    const { t } = useTranslation();
    const handleFocus = (event) => event.target.select();
    return (
        <Modal onClose={props.onClose} title={t('title.saveAs')}>
            <div className={classes.main}>
                <label htmlFor="name">{t('title.name')}</label>
                <input type="text" id="name" onChange={(e) => {
                    setName(e.target.value);
                }} autoFocus={true} onFocus={handleFocus} value={name} />
                <p style={showError ? {} : { "visibility": "hidden" }}> {t('saveAs.error.nameExists')} </p>
                <div className={classes.actions}>
                    <PrimaryButton onClick={() => props.onSave(name.trim())} label={t('button.ok')}
                        disabled={props.checkName(name) || name.trim() === ""} />
                </div>
            </div>
        </Modal>);
}

export default SaveAs;