import React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../../components/Buttons/IconButton";
import editOutlineImage from "../../assets/edit-outline.svg";
import trashOutlineImage from "../../assets/trash-outline.svg";
import keyIconImage from "../../assets/change-password-outline.svg";
import rulesIconImage from '../../assets/rules-outline.svg';
import warningOutlineImage from '../../assets/warning-outline.svg';
import Consts from "../../Consts";
import Utils from "../../Utils";
import { useSelector } from "react-redux";

function UserItem(props) {

    const user = props.user;
    const { t } = useTranslation();

    const currentUser = useSelector((state) => state.users.allUsers.currentUser) || {};

    const roleMap = new Map();
    roleMap.set("ADMIN", t('edituser.selectAdmin'));
    roleMap.set("POWERUSER", t('edituser.selectPowerUser'));
    roleMap.set("OPERATOR", t('edituser.selectOperator'));

    function isDeleteDisabled() {
        if (currentUser && currentUser.userId && user.userId === currentUser.userId) {
            return true;
        }
        return false;
    }

    return (
        <tr key={user.id} className='clickable'
            style={props.isActive ? { background: Consts.SELECTION_COLOR } : { background: 'white' }}
            onClick={() => props.onSelect(user, props.index)}>
            <td>
                {props.outputStatus && props.outputStatus !== "" && !Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(user) &&
                    <div style={{
                        backgroundImage: `url(${warningOutlineImage})`,
                        width: "1rem",
                        height: "1rem",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain"
                    }} title={props.outputStatus}/>
                }
            </td>
            <td style={{"textAlign": "left"}}>{props.index + 1}</td>
            <td style={{ "whiteSpace": "nowrap", "maxWidth": "0", "overflow": "hidden", "textOverflow": "ellipsis", "textAlign": "left" }}
                title={user.userId}> 
                {user.userId}
            </td>
            <td title={roleMap.get(user.role)}>
                {roleMap.get(user.role)}
            </td>
            <td>
                {/*<IconButton
                    onClick={() => props.onEditRule(user, props.index)}
                    iconImage={rulesIconImage}
                    tooltip={t('tooltip.editRule')}
                    altText="Edit User Rules" /> */}
                <IconButton
                    onClick={() => props.onEditPassword(user, props.index)}
                    iconImage={keyIconImage}
                    tooltip={t('tooltip.changePassword')}
                    altText="Edit User Password" />
                <IconButton
                    onClick={() => props.onEdit(user, props.index)}
                    iconImage={editOutlineImage}
                    tooltip={t('tooltip.edit')}
                    altText="Edit User" />
                <IconButton
                    onClick={() => props.onDelete(user.id)}
                    disabled={isDeleteDisabled()}
                    iconImage={trashOutlineImage}
                    tooltip={t('tooltip.delete')}
                    altText="Delete User" />
            </td>
        </tr>
    );
}

export default UserItem;