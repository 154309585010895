import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getInputSettings } from "../../InputSettings/InputSettingsActions";
import { updateShowHelpMessage } from "../../Config/ConfigActions";
import { useSelector, shallowEqual } from 'react-redux';
import SettingsAction from "./SettingsAction";
import Filters from "../../Filters/Filters";
import Utils from "../../../Utils";
import Master from "../../Master/Master";
import SuccessCard from "../../../components/SuccessCard/SuccessCard";
import InputSettings from "../../InputSettings/InputSettings";
import OutputSettings from "../../OutputSettings/OutputSettings";
import HelpGuide from "../../../components/HelpGuide/HelpGuide";
import jigsImage from "../../../assets/document-outline.svg";
import layoutsImage from "../../../assets/hamburger-outline.svg";
import settingsImage from "../../../assets/settings-outline.svg";
import filtersImage from "../../../assets/filter-outline.svg"
import personImage from "../../../assets/person-outline.svg";
import classes from "./Settings.module.css";

function Settings(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match: { url } } = props;
  const currentUser = useSelector((state) => state.users.allUsers.currentUser || {});
  const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
  const config = useSelector((state) => state.config.config || {});

  const [showFilters, setShowFilters] = useState(false);
  const [showMasters, setShowMasters] = useState(false);
  const [showSuccessCard, setShowSuccessCard] = useState(false);
  const [showInputSettings, setShowInputSettings] = useState(false);
  const [showOutputSettings, setShowOutputSettings] = useState(false);
  const [showError, setShowError] = useState(false);

  function settingsClickHandler() {
    setShowFilters(true);
  }

  function mastersClickHandler() {
    setShowMasters(true);
  }

  function mastersCloseHandler() {
    setShowMasters(false);
  }

  function showInputSettingsHandler() {
    getInputSettings(dispatch).then((response) => {
      setShowInputSettings(true);
    });
  }

  function hideHelpMessageHandler() {
    updateShowHelpMessage(dispatch, false).then((response) => {
      if (!(response && response.data && response.data.success)) {
        setShowError(true);
      }
    })

  }

  function closeErrorHandler() {
    setShowError(false);
  }

  return (<>
    {showSuccessCard && <SuccessCard message={t('master.successMsg')} onClose={() => setShowSuccessCard(false)} btnLabel={t('button.ok')} />}
    {showError && Utils.showErrorDialog(t('config.error.helpMessageHideFailed'), closeErrorHandler)}
    <div className={Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(currentUser) && config.showHelpMessage ? classes.mainWithHelpGuide : classes.main}>
      {Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(currentUser) && config.showHelpMessage && 
        <div className={classes.helpGuideContainer}>
          <div className={classes.helpGuide}>
            <HelpGuide message={config.helpMessage} onHide={hideHelpMessageHandler}/>
          </div>
        </div>
      }
      <div className={classes.btns}>
        {Utils.canViewJigs(permissions) &&
          <div className={classes.btnWrapper}>
            <Link to={`${url}/jigs`}>
              <SettingsAction src={jigsImage} iconBackground="#CCCCE3" altText="Jigs" title={t('settings.button.jigs')} />
            </Link>
          </div>
        }
        {Utils.canViewTables(permissions) &&
          <div className={classes.btnWrapper}>
            <Link to={`${url}/layouts`}>
              <SettingsAction src={layoutsImage} iconBackground="#C2CBFA" altText="Print Layouts" title={t('settings.button.tables')} />
            </Link>
          </div>
        }
        {Utils.canViewAutomationRules(permissions) &&
          <div className={classes.btnWrapper} onClick={settingsClickHandler}>
            <SettingsAction src={filtersImage} iconBackground="#41DBB3" altText="Filters" title={t('settings.button.filters')} />
          </div>
        }
        {Utils.canManageUsers(permissions) &&
          <div className={classes.btnWrapper}>
            <Link to={`${url}/users`}>
              <SettingsAction src={personImage} iconBackground="#FFABAB" altText="Users" title={t('settings.button.users')} />
            </Link>
          </div>
        }
        {Utils.canViewInputSettings(permissions) &&
          <div className={classes.btnWrapper} onClick={showInputSettingsHandler}>
            <SettingsAction src={settingsImage} iconBackground="#FFC8A2" altText="Configuration" title={t('settings.button.inputSettings')} />
          </div>
        }
        {Utils.canViewOutputSettings(permissions) &&
          <div className={classes.btnWrapper} onClick={e => setShowOutputSettings(true)}>
            <SettingsAction src={settingsImage} iconBackground="#DDC984" altText="Configuration" title={t('settings.button.outputSettings')} />
          </div>
        }
        {Utils.canViewConfig(permissions) &&
          <div className={classes.btnWrapper}>
            <Link to={`${url}/configuration-settings`}>
              <SettingsAction src={settingsImage} iconBackground="#97C1A9" altText="Configuration" title={t('settings.button.config')} />
            </Link>
          </div>
        }
        {Utils.canViewMasters(permissions) &&
          <div className={classes.btnWrapper} onClick={mastersClickHandler}>
            <SettingsAction src={settingsImage} iconBackground="#AFCBFF" altText="Masters" title={t('settings.button.masters')} />
          </div>
        }
        <div className={classes.btnWrapper}>
          <Link to={`${url}/connectors`}>
            <SettingsAction src={settingsImage} iconBackground="#FFC8A2" altText="Configuration" title={t('settings.button.connectors')} />
          </Link>
        </div>
        {Utils.isSuperAdminOrB2bRemoteUser(currentUser) &&
          <div className={classes.btnWrapper}>
            <Link to={`/customers`}>
              <SettingsAction src={settingsImage} iconBackground="#FFC8A2" altText="Configuration" title={t('settings.button.dashboard')} />
            </Link>
          </div>
        }
      </div>
    </div>
    {showFilters && <Filters onClose={e => setShowFilters(false)} />}
    {showMasters && <Master onClose={mastersCloseHandler} showSuccessCardHandler={setShowSuccessCard} />}
    {showInputSettings && <InputSettings onClose={() => setShowInputSettings(false)} />}
    {showOutputSettings && <OutputSettings onClose={() => setShowOutputSettings(false)} />}
  </>
  );
}

export default Settings;
