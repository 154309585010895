import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./OutputPreset.module.css";

const colorMap = new Map();
colorMap.set("FULL", "rgb(170, 255, 170)"); // Light green
colorMap.set("PARTIAL", "rgb(255, 180, 110)"); // Light orange
colorMap.set("NOTASSIGNED", "rgb(170, 170, 170)"); // Light black
colorMap.set("EMPTY", "rgb(255, 170, 170)"); // Light red


export default function OutputPreset({outputPreset, isActive, onClick, width="15%", height="3rem",}) {
    const { t } = useTranslation();
    const table = outputPreset?.table;
    const tableData = outputPreset?.tableData;
    const initalStatus = tableData ? tableData.status : "EMPTY";
    const [status, setStatus] = useState(initalStatus);

    const tableName = table?.name || t("option.notFound");
    const outputChannelName = outputPreset.name || t("option.notFound");
    
    useEffect(() => {
        if (!outputPreset?.table?.name) {
            setStatus("NOTASSIGNED");
        } else if (outputPreset.tableData) {
            setStatus(outputPreset.tableData.status);
        } else {
            setStatus(outputPreset.status);
        }
    }, [outputPreset]);

    let borderStyle = isActive && "2px solid #434343";

    const cardStyle = { width, height, "border": borderStyle, "backgroundColor": colorMap.get(status)};
    const textStyle = { textOverflow: "ellipsis", color: 'unset' }
    const name = outputPreset.name || 'Untitled';
    const presetVal = [tableName, outputChannelName].filter(i => i);
    return (
        <div key={outputPreset.id} className={classes.main} style={cardStyle} 
            title={presetVal.join(', ')}
            onClick={() => onClick && onClick(outputPreset)}
        >
            {(outputPreset.table || outputPreset.outputChannel) 
                ? <span className={classes.addPresetText} style={textStyle} title={name}>{name}</span>
                : <span className={classes.addPresetText} 
                    title={t("outputPresets.addPreset.tooltip")}>
                    {t("outputPresets.addPreset")}
                </span>
            }
        </div>);

}