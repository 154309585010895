import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { t } from "i18next";
import IconButton from "../../components/Buttons/IconButton";
import editOutlineImage from "../../assets/edit-outline.svg";
import trashOutlineImage from "../../assets/trash-outline.svg";
import copyOutlineImage from "../../assets/copy-outline.svg";
import downloadOutlineImage from "../../assets/download-outline.svg"
import Utils from "../../Utils";
import Consts from '../../Consts';
import classes from './TableItem.module.css';

function TableItem(props) {
    const table = props.table;
    const unit = useSelector((state) => state.config.config.unit, shallowEqual);
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");

    return (
        <tr
            key={table.id}
            className="clickable"
            style={props.isActive ? { background: Consts.SELECTION_COLOR } : { background: 'white' }}
            onClick={() => props.onSelect(table, props.index)}
        >
            <td> {props.index + 1}</td>
            <td title={table.name} className={classes.nameField} style={{ "textAlign": "left" }} >{table.name}</td>
            <td>{Utils.formatMMToUnits(table.width, unit)}</td>
            <td>{Utils.formatMMToUnits(table.height, unit)}</td>
            <td>
                {Utils.canEditTables(permissions) && <>
                    <IconButton
                        onClick={() => props.onEdit(table)}
                        iconImage={editOutlineImage}
                        tooltip={t('tooltip.edit')}
                        altText="Edit Layout" />
                    <IconButton
                        onClick={() => props.onDelete(table.id)}
                        iconImage={trashOutlineImage}
                        tooltip={t('tooltip.delete')}
                        alt="Delete Layout" />
                    <IconButton
                        onClick={() => props.onCopy(table)}
                        iconImage={copyOutlineImage}
                        tooltip={t('tooltip.copy')}
                        alt="Copy Layout" />
                </>}
                {Utils.canDownload(permissions) &&
                    <IconButton
                        onClick={() => props.onDownload(table)}
                        iconImage={downloadOutlineImage}
                        tooltip={t('tooltip.download')}
                        altText="Download Layout" />
                }
            </td>
        </tr>
    );
}

export default React.memo(TableItem);