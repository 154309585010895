import React from 'react'
import searchOutlineImage from '../../assets/search-outline.svg';
import classes from './SearchField.module.css';

function SearchField(props) {
    return (
        <div className={classes.main}>
            <label htmlFor="search"><span > {props.label}</span></label>
            <div className={classes.searchBar}>
                <img src={searchOutlineImage} alt="Serach Field" width="13px" height="13px" />
                <input id="search" type="search" onChange = {props.onChange} />
            </div>     
        </div>
    );
}

export default SearchField;