import { createSlice } from "@reduxjs/toolkit";
import Consts from '../../Consts';

function getInputSettings() {
    const inputSettings = {
        connectors: [
            {
                type: Consts.INPUT_PERSBIZ,
                mapping: []
            },
        ],
        customParams: [],
        mappingSuggestions: [],
        learningMode: false
    }

    for (let ind = 1; ind <= Consts.HOT_FOLDER_COUNT; ind++) {
        inputSettings.connectors.push({
            type: `${Consts.INPUT_HOTFOLDER}${ind}`,
            order: [],
            separator: '',
            fixValues: []
        }, )
    }

    return inputSettings;
}
const initialState = {
    inputSettings: getInputSettings()
};

const inputSettingsSlice = createSlice({
    name: "inputSettings",
    initialState,
    reducers: {
        fetchInputSettings: (state, action) => {
            state.inputSettings = {
                ...getInputSettings(),
                ...action.payload.inputSettings
            };
        },
        updateConnectors: (state, action) => {
            state.inputSettings.connectors = action.payload.inputSettings.connectors;
            state.inputSettings.learningMode = action.payload.inputSettings.learningMode;
        },
        updateCustomParams: (state, action) => {
            state.inputSettings.customParams = action.payload.customParams;
        }
    }
});

export const inputSettingsActions = inputSettingsSlice.actions;

export default inputSettingsSlice.reducer;