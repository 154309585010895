import React from "react";
import { useTranslation } from 'react-i18next';
import Consts from "../../Consts";
import Utils from "../../Utils";
import classes from "./Permissions.module.css";

function Permissions(props) {
    const { t } = useTranslation();

    const options = [
        { value: Consts.PERMISSIONS_JIG, label: t('permissions.jig') },
        { value: Consts.PERMISSIONS_PRINT_LAYOUT, label: t('permissions.printLayout') },
        { value: Consts.PERMISSIONS_AUTOMATION_RULES, label: t('permissions.automationRules') },
        { value: Consts.PERMISSIONS_MASTERS, label: t('permissions.masters') },
        { value: Consts.PERMISSIONS_INPUT_SETTINGS, label: t('permissions.inputSettings') },
        { value: Consts.PERMISSIONS_OUTPUT_SETTINGS, label: t('permissions.outputSettings') },
        { value: Consts.PERMISSIONS_CONFIGURATION, label: t('permissions.configuration') },
        { value: Consts.PERMISSIONS_DELETE_ORDERS, label: t('permissions.deleteOrders') },
        { value: Consts.PERMISSIONS_CHANGE_ORDER_JIG, label: t('permissions.changeOrderJig') },
        { value: Consts.PERMISSIONS_DOWNLOADS, label: t('permissions.downloads') },
        { value: Consts.PERMISSIONS_CHANGE_ORDER_ASSIGNEE, label: t('permissions.changeOrderAssignee') },
        { value: Consts.PERMISSIONS_OUTPUT_PRESET, label: t('permissions.outputPreset') }
    ]

    let permissions = props.permissions ? [...props.permissions] : [];

    function checkViewChangeHandler(name) {
        const viewName = Utils.getPermissionViewName(name);
        const editName = Utils.getPermissionEditName(name);

        if (Utils.checkViewPermission(permissions, name)) {
            permissions = permissions.filter(e => e !== viewName && e !== editName);
        }
        else {
            permissions.push(viewName);
        }

        props.setPermissions(permissions);
    }

    function checkEditChangeHandler(name) {
        const viewName = Utils.getPermissionViewName(name);
        const editName = Utils.getPermissionEditName(name);

        if (Utils.checkEditPermission(permissions, name)) {
            permissions = permissions.filter(e => e !== editName);
            if (!Utils.isPermissionEditOnly(name)) {
                permissions.push(viewName);
            }
        }
        else {
            if (Utils.checkViewPermission(permissions, name)) {
                permissions = permissions.filter(e => e !== viewName && e !== editName);
            }
            permissions.push(editName);
        }
        
        props.setPermissions(permissions);
    }

    return (
    <div className={classes.main}>
         <table className={classes.table}>
            <thead>
                <tr>
                    <th style={{"width": "75%", "textAlign": "Left"}}>{t('permissions.table.name')}</th>
                    <th style={{"width": "15%"}}>{t('permissions.table.view')}</th>
                    <th style={{"width": "15%"}}>{t('permissions.table.edit')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    options.map((option, index) => {
                        return <tr key={index}>
                            <td className={classes.nameField} 
                                style={{"textAlign": "left"}} 
                                title={option.label}>
                                {option.label}
                            </td>
                            <td>
                                {
                                    !Utils.isPermissionEditOnly(option.value) &&
                                        <input type='checkbox' 
                                            checked={Utils.checkViewPermission(permissions, option.value)}
                                            onChange={() => {checkViewChangeHandler(option.value);}}
                                        />
                                }
                            </td>
                            <td>
                                <input type='checkbox' 
                                    checked={Utils.checkEditPermission(permissions, option.value)}
                                    onChange={() => {checkEditChangeHandler(option.value);}}/>
                            </td>
                        </tr>   
                    })
                }
            </tbody>
        </table>
    </div>);
}

export default Permissions;