import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import IconButton from '../../components/Buttons/IconButton';
import arrowUpImage from '../../assets/arrow-up.svg';
import arrowDownImage from '../../assets/arrow-down.svg';
import Consts from '../../Consts';
import Utils from '../../Utils';
import classes from './HotFolder.module.css';

function getCustomParamsList(list) {
    if (!list || !list.length) return [];
    const customParamsList = list.map((item) => {
        if (item.type === "media") {
            return "additionalMediaParam." + item.name;
        } else {
            return "additionalParam." + item.name;
        }
    });
    return customParamsList;
}

function HotFolder(props) {

    const { t } = useTranslation();
    const customParams = getCustomParamsList(props.customParams);
    let allParameters = Utils.getSystemParameterListForHotFolder();
    allParameters = allParameters.concat(customParams);

    const [usedParameters, setUsedParameters] = useState(props.hotFolder?.order || []);
    const [fixParamsWithValues, setFixParamsWithValues] = useState(props.hotFolder?.fixValues || []);
    const [separator, setSeparator] = useState(props.hotFolder?.separator || '');
    const [allParametersIndex, setAllParametersIndex] = useState(-1);
    const [usedParametersIndex, setUsedParametersIndex] = useState(-1);
    const [fixParametersIndex, setFixParametersIndex] = useState(-1);

    useEffect(() => {
        const obj = {
            order: usedParameters,
            separator: separator,
            fixValues: fixParamsWithValues
        }
        props.onChange && props.onChange(obj, props.hotFolderIndex);
    }, [separator, usedParameters, fixParamsWithValues])

    useEffect(() => {
        setUsedParameters(props.hotFolder?.order || []);
        setFixParamsWithValues(props.hotFolder?.fixValues || []);
        setSeparator(props.hotFolder?.separator || '');
        setAllParametersIndex(-1);
        setUsedParametersIndex(-1);
        setFixParametersIndex(-1);
    }, [props.hotFolderIndex])

    useEffect(() => {
        const updatedUsedParameters = usedParameters.filter((item) => allParameters.includes(item));
        setUsedParameters((prevState) => {
            return [...updatedUsedParameters];
        })

        const updatedFixParamsWithValues = fixParamsWithValues.filter((item) => item.key && allParameters.includes(item.key));
        setFixParamsWithValues((prevState) => {
            return [...updatedFixParamsWithValues];
        })
    }, [props.customParams])

    function isAddParameterButtonEnabled() {
        if (allParametersIndex >= 0 && !usedParameters.includes(allParameters[allParametersIndex]) && 
        !fixParamsWithValues.map((e) => e.key).includes(allParameters[allParametersIndex])) {
            return true;
        }
        return false;
    }

    function isRemoveUsedParameterButtonEnabled() {
        if (usedParametersIndex >= 0) {
            return true;
        }
        return false;
    }

    function isRemoveFixParameterButtonEnabled() {
        if (fixParametersIndex >= 0) {
            return true;
        }
        return false;
    }

    function canMoveNodeInUsedParams(index, direction) {
        if (index < 0) {
            return false;
        }
        let length = usedParameters.length;
        let disallowMove = (index === 0 && direction === 'up') || // First value can't move up
            (index === (length - 1) && direction === 'down'); // Last value can't move down
        return !disallowMove;
    }

    function moveNodeHandlerForUsedParams(index, direction) {
        if (canMoveNodeInUsedParams(index, direction)) {
            let newIndex = index + (direction === 'up' ? -1 : 1);
            setUsedParameters((prevValues) => {
                let values = [...prevValues];
                values[index] = prevValues[newIndex];
                values[newIndex] = prevValues[index];
                return values;
            });
            setUsedParametersIndex(newIndex);
        }
    }

    function canMoveNodeInFixParams(index, direction) {
        if (index < 0) {
            return false;
        }
        let length = fixParamsWithValues.length;
        let disallowMove = (index === 0 && direction === 'up') || // First value can't move up
            (index === (length - 1) && direction === 'down'); // Last value can't move down
        return !disallowMove;
    }

    function moveNodeHandlerForFixParams(index, direction) {
        if (canMoveNodeInFixParams(index, direction)) {
            let newIndex = index + (direction === 'up' ? -1 : 1);
            setFixParamsWithValues((prevValues) => {
                let values = [...prevValues];
                values[index] = prevValues[newIndex];
                values[newIndex] = prevValues[index];
                return values;
            });
            setFixParametersIndex(newIndex);
        }
    }

    function getParamName(item) {
        let itemName = item;
        if (itemName.startsWith("additionalParam.") || itemName.startsWith("additionalMediaParam.")) {
            itemName = itemName.substring(itemName.indexOf(".") + 1);
        } else {
            itemName = t(itemName);
        }
        return itemName;
    }

    function getExampleFilename() {
        if (usedParameters.length === 0) return '';
        let paramNames = usedParameters;
        if (separator === '') {
            paramNames = paramNames.slice(0, 1);
        }
        paramNames = paramNames.map((item) => {
            return getParamName(item);
        });
        return paramNames.join(separator) + '.pdf';
    }

    const exampleFilename = getExampleFilename();

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <h3>{`${t('inputSettings.hotFolder.fileNaming')} : ${t('inputConnectors.hotFolder')} ${props.hotFolderIndex + 1}`}</h3>
            </div>
            <div className={classes.separator}>
                <label htmlFor='separator'>{t('inputSettings.hotFolder.separator')}</label>
                <select id='separator'
                    style={{marginLeft: 6}}
                    value={separator}
                    onChange={event => setSeparator(event.target.value)}>
                        <option value="-">-</option>
                        <option value="+">+</option>
                        <option value="=">=</option>
                        <option value="_">_</option>
                        <option value=" ">{"<space>"}</option>
                        <option value="">{"<nothing>"}</option>
                </select>
                <span style={{marginLeft: "1rem"}}>{t('inputSettings.hotFolder.exampleFilename')}</span>
                <span className={classes.exampleFilenameSpan} title={exampleFilename}>{exampleFilename}</span>
                <span className={classes.help} title={t('inputSettings.hotFolder.info')}>?</span>
            </div>
            <div className={classes.container}>
                <div className={classes.containerBody}>
                    <div className={classes.leftContainer}>
                        <div className={classes.parameterContainer}>
                            <div className={classes.parameterContainerHeader}>
                                <h3>{t('inputSettings.hotFolder.allParameters')}</h3>
                            </div>
                            <div className={classes.parameterContainerBody}>
                                {allParameters.map((item, index) => {
                                    const paramName = getParamName(item);
                                    const color = (usedParameters.includes(item) || fixParamsWithValues.map(item => item.key).includes(item))? 'rgba(0, 0, 0, 0.5)' : 'black';
                                    return <span key={`${item}`}
                                        style={{
                                            backgroundColor: (index === allParametersIndex) ? Consts.SELECTION_COLOR : "",
                                            color: color
                                        }}
                                        title={paramName}
                                        onClick={() => setAllParametersIndex(index)}
                                    >{paramName}</span>
                                }).filter(val => val)
                                }
                            </div>
                        </div>
                    </div>
                    <div className={classes.rightContainer}>
                        <div className={classes.topContainer}>
                            <div className={classes.buttonContainer}>
                                <button type='button'
                                    disabled={!isAddParameterButtonEnabled()}
                                    onClick={() => {
                                        setUsedParameters((prevState) => {
                                            return [...prevState, allParameters[allParametersIndex]];
                                        })
                                    }}>
                                    {'>'}
                                </button>
                                <button
                                    type='button'
                                    disabled={!isRemoveUsedParameterButtonEnabled()}
                                    onClick={() => {
                                        setUsedParameters((prevState) => {
                                            const arr = [...prevState];
                                            arr.splice(usedParametersIndex, 1);
                                            return arr;
                                        })
                                        setUsedParametersIndex(-1);
                                    }}>
                                    {'<'}
                                </button>
                            </div>
                            <div className={classes.parameterContainer}>
                                <div className={classes.parameterContainerHeader}>
                                    <h3>{t('inputSettings.hotFolder.jobFilenameVariable')}</h3>
                                    <div className={classes.parameterContainerBtns}>
                                        <IconButton onClick={() => moveNodeHandlerForUsedParams(usedParametersIndex, 'up')} iconImage={arrowUpImage}
                                            disabled={!canMoveNodeInUsedParams(usedParametersIndex, 'up')} />
                                        <IconButton onClick={() => moveNodeHandlerForUsedParams(usedParametersIndex, 'down')} iconImage={arrowDownImage}
                                            disabled={!canMoveNodeInUsedParams(usedParametersIndex, 'down')} />
                                    </div>
                                </div>
                                <div className={classes.parameterContainerBody}>
                                    {usedParameters.map((item, index) => {
                                        const paramName = getParamName(item);
                                        return <span key={`${item}`}
                                            title={paramName}
                                            style={{ backgroundColor: (index === usedParametersIndex) ? Consts.SELECTION_COLOR : "" }}
                                            onClick={() => setUsedParametersIndex(index)}>{paramName}</span>
                                    }).filter(val => val)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.bottomContainer}>
                            <div className={classes.buttonContainer}>
                                <button type='button'
                                    disabled={!isAddParameterButtonEnabled()}
                                    onClick={() => {
                                        setFixParamsWithValues((prevState) => {
                                            return [...prevState, {key: allParameters[allParametersIndex], value: ''}];
                                        })
                                    }}>
                                    {'>'}
                                </button>
                                <button
                                    type='button'
                                    disabled={!isRemoveFixParameterButtonEnabled()}
                                    onClick={() => {
                                        setFixParamsWithValues((prevState) => {
                                            const arr = [...prevState];
                                            arr.splice(fixParametersIndex, 1);
                                            return arr;
                                        })
                                        setFixParametersIndex(-1);
                                    }}>
                                    {'<'}
                                </button>
                            </div>
                            <div className={classes.parameterContainer}>
                                <div className={classes.parameterContainerHeader}>
                                    <h3>{t('inputSettings.hotFolder.fixValues')}</h3>
                                    <div className={classes.parameterContainerBtns}>
                                        <IconButton onClick={() => moveNodeHandlerForFixParams(fixParametersIndex, 'up')} iconImage={arrowUpImage}
                                            disabled={!canMoveNodeInFixParams(fixParametersIndex, 'up')} />
                                        <IconButton onClick={() => moveNodeHandlerForFixParams(fixParametersIndex, 'down')} iconImage={arrowDownImage}
                                            disabled={!canMoveNodeInFixParams(fixParametersIndex, 'down')} />
                                    </div>
                                </div>
                                <div className={classes.parameterContainerBody}>
                                    {fixParamsWithValues.map((item, index) => {
                                        const paramName = getParamName(item.key);
                                        return <div key={`${item.key}`}
                                                    style={{ backgroundColor: (index === fixParametersIndex) ? Consts.SELECTION_COLOR : "" }}
                                                    onClick={() => {
                                                        setFixParametersIndex(index);
                                                    }}>
                                                     <span title={paramName}>{paramName}</span>
                                                     <input value={item.value}
                                                            title={item.value}
                                                            onChange={(e) => {
                                                                setFixParamsWithValues((prevState) => {
                                                                    const newState = [...prevState];
                                                                    newState[index] = {...newState[index],
                                                                                        value: e.target.value 
                                                                                    }
                                                                    return newState;
                                                                })
                                                            }}></input>
                                                </div>
                                    }).filter(val => val)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HotFolder;