import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classes from "./OutputPreset.module.css";
import OrderPreview from "../Orders/components/OrderPreview";
import { useIdMap } from "../../custom-hooks/IdMap";
import SettingBox, { Select } from "./SettingBox";
import Utils from "../../Utils";
import TextButton from "../../components/Buttons/TextButton";
import AddValueModal from "../../components/AddValueModal/AddValueModal";
import { addMaster } from "../Master/MasterActions";

function getFullTable(outputPreset, tableMap) {
  let tableId = outputPreset.tableId || outputPreset.table?.id;
  return tableMap[tableId];
}

const unwantedFilters = new Set(["id", "printerType", "location", "_id"]);

export default function LayoutSelection({
  userTables,
  onInfoChange,
  outputPreset,
}) {
  const { t } = useTranslation();
  const allMasters = useSelector((state) => state.master.allMasters, shallowEqual) || {};
  const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
  const [table, setTable] = useState();
  const [actions, setActions] = useState([]);
  const [filterParms, setFilterParms] = useState(
    outputPreset.filterParms || {}
  );
  const [tableMap] = useIdMap(userTables);
  const [showAddValue, setShowAddValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (table && filterParms) {
      onInfoChange && onInfoChange({ table, filterParms });
    }
  }, [table, filterParms]);

  useEffect(() => {
    if (outputPreset && tableMap) {
      setTable(getFullTable(outputPreset, tableMap));
    }
  }, [tableMap, outputPreset]);

  useEffect(() => {
    if (allMasters) {
      const filterKeys = Object.keys(allMasters).filter((i) => !unwantedFilters.has(i) && typeof allMasters[i] == "object").sort();
      setActions(
        filterKeys.map((i) => ({
          key: i,
          values: allMasters[i],
        }))
      );
    }
  }, [allMasters]);

  const canUpdateMaster = Utils.canViewMasters(permissions);
  return <>
    {showAddValue && <AddValueModal onAdd={value => {
      addMaster(dispatch, {type: showAddValue, value}).then(e => {
        setShowAddValue('');
        setFilterParms(s => ({ ...s, [showAddValue]: value }))
      });
    }} onClose={e => setShowAddValue('')} />}
    <div className={`${classes.box}`}>
      <div style={{ paddingBottom: "0.5rem", paddingRight: "1rem" }}>
        <Select
          label={t("orders.select.table")}
          options={userTables.map((u) => ({
            label: u.name,
            value: u.id,
          }))}
          value={table?.id || ""}
          onChange={(id) => {
            setTable(tableMap[id]);
          }}
        />
      </div>
      <div
        className={`${classes.rightBorder} ${classes.layoutPreviewContainer}`}
      >
        <div className={classes.layoutPreview}>
          {table && <OrderPreview selectedTable={table} />}
        </div>
      </div>

      <div
        className={`${classes.rightBorder} ${classes.outputPresetContainer}`}
      >
        <SettingBox
          title={t("orders.select.outputPreset")}
          items={actions}
          containerStyle={{ height: "100%" }}
          ItemsWrapper={({ children }) => (
            <table className={classes.filterParms}><tbody>{children}</tbody></table>
          )}
          ItemComponent={({ item }) => {
            const values = item.values || {};
            return <tr>
              <td>{Utils.toPascal(item.key)}</td>
              <td>
                <Select
                  options={Object.keys(values).map(k => ({ label: values[k], value: k })) || []}
                  value={filterParms[item.key] || ""}
                  onChange={(val) =>
                    setFilterParms((s) => ({ ...s, [item.key]: val }))
                  }
                />
              </td>
              {canUpdateMaster && <td>
                <TextButton label={t("button.add")} onClick={e => setShowAddValue(item.key)} />
              </td>}
            </tr>
          }}
        />
      </div>
    </div>
  </>;
}
