import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { t } from "i18next";
import IconButton from "../../components/Buttons/IconButton";
import editOutlineImage from "../../assets/edit-outline.svg";
import trashOutlineImage from "../../assets/trash-outline.svg";
import copyOutlineImage from "../../assets/copy-outline.svg";
import downloadOutlineImage from "../../assets/download-outline.svg"
import Utils from "../../Utils";
import Consts from '../../Consts';
import classes from './JigItem.module.css';

function JigItem(props) {
    const unit = useSelector((state) => state.config.config.unit, shallowEqual);
    const jig = props.jig;
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");

    return (<tr key={jig.id} className='clickable'
        style={props.isActive ? { background: Consts.SELECTION_COLOR } : { background: 'white' }}
        onClick={() => props.onSelect(jig, props.index)}
    >
        <td>{props.index + 1}</td>
        <td title={jig.name} className={classes.nameField} style={{ "textAlign": "left" }}> {jig.name} </td>
        <td>{Utils.formatMMToUnits(jig.width, unit)}</td>
        <td>{Utils.formatMMToUnits(jig.height, unit)}</td>
        <td>{Utils.formatMMToUnits(jig.thickness, unit)}</td>
        <td>
            {Utils.canEditJigs(permissions) &&
                <><IconButton
                    onClick={() => props.onEdit(jig)}
                    iconImage={editOutlineImage}
                    tooltip={t('tooltip.edit')}
                    altText="Edit Jig" />
                    <IconButton
                        onClick={() => props.onDelete(jig.id)}
                        iconImage={trashOutlineImage}
                        tooltip={t('tooltip.delete')}
                        altText="Delete Jig" />
                    <IconButton
                        onClick={() => props.onCopy(jig)}
                        iconImage={copyOutlineImage}
                        tooltip={t('tooltip.copy')}
                        altText="Copy Jig" />
                </>}
            {Utils.canDownload(permissions) &&
                <IconButton
                    onClick={() => props.onDownload(jig)}
                    iconImage={downloadOutlineImage}
                    tooltip={t('tooltip.download')}
                    altText="Download Jig" />
            }
        </td>
    </tr>
    );
}

export default React.memo(JigItem);