import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allFilters: {
        decisionTree: {}
    }
};

const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        fetchFilters: (state, action) => {
            state.allFilters.decisionTree = action.payload.decisionTree;
        },
        updateDecisionTree: (state, action) => {
            state.allFilters.decisionTree = action.payload.decisionTree;
        }
    }
});

export const filtersActions = filtersSlice.actions;

export default filtersSlice.reducer;