import React, { useEffect, useState } from "react";
import { t } from "i18next";
import FilterNode from "./FilterNode";
import AddValue from "../../components/ValueList/AddValue";
import TextButton from "../../components/Buttons/TextButton";
import IconButton from "../../components/Buttons/IconButton";
import FilterUtils from "./FilterUtils";
import LinkWith from "./LinkWith";
import trashOutlineImage from "../../assets/trash-outline.svg";
import arrowUpImage from "../../assets/arrow-up.svg";
import arrowDownImage from "../../assets/arrow-down.svg";
import linkImage from "../../assets/link-outline.svg";
import unlinkImage from "../../assets/unlink-outline.svg";
import classes from "./FilterLevel.module.css";

function FilterLevel(props) {
    const filterNode = props.filterNodes[props.filterNodeId];
    const [filterValues, setFilterValues] = useState(filterNode.values);
    const [showAddValue, setShowAddValue] = useState(false);
    const [showLinkWith, setShowLinkWith] = useState(false);

    useEffect(() => {
        filterNode.values = filterValues;
    }, [filterValues]);

    function getInitialActiveIndex() {
        return filterValues.findIndex((filterValue) => filterValue.dataNodeId === props.selected);
    }
    const [activeIndex, setActiveIndex] = useState(getInitialActiveIndex());
    useEffect(() => {
        setActiveIndex(getInitialActiveIndex());
    }, [props.selected]);

    function addValueHandler() {
        setShowAddValue(true);
    }

    function addValueAddHandler(value) {
        const newNode = FilterUtils.createDataNode(value, props.dataNodes);
        setFilterValues((prevValues) => {
            let values = [...prevValues];
            let filterValue = {
                filterNodeId: null,
                dataNodeId: newNode.id
            };
            values.splice(values.length - 1, 0, filterValue);
            return values;
        });

        setShowAddValue(false);
    }

    function deleteNodeHandler(dataNodeId) {
        const index = filterValues.findIndex((filterValue) => filterValue.dataNodeId === dataNodeId);
        if (index === -1) {
            return;
        }

        // Remove the data node from map.
        const filterValue = filterValues[index];
        delete props.dataNodes[dataNodeId];

        // Remove the filter node from map.
        // We do an additional check here that this filter node is not present in any other filter value.
        // In case it is, we skip the node deletion from map. We also need to update the isLinked field accordingly.
        let filterNodeId = filterValue.filterNodeId;
        if (filterNodeId) {
            if (filterValue.isLinked) {
                filterValue.isLinked = false;

                let linkedFilterValue;
                let linkedNodesCount = 0;
                filterValues.forEach((filterValue) => {
                    if (filterValue.isLinked && filterValue.filterNodeId === filterNodeId) {
                        linkedNodesCount++;
                        linkedFilterValue = filterValue;
                    }
                });

                if (linkedNodesCount === 1) {
                    // Single value left with this filter node.
                    linkedFilterValue.isLinked = false;
                }
            } else {
                FilterUtils.removeFilterNode(filterNodeId, props.filterNodes, props.dataNodes);
            }
        }

        setFilterValues((prevValues) => {
            let values = [...prevValues];
            values.splice(index, 1);
            return values;
        });

        setShowAddValue(false);

        props.onRowDelete(props.filterNodeId, dataNodeId);

        if (index === activeIndex) {
            setActiveIndex(-1);
        }
        else if (index < activeIndex) {
            setActiveIndex((prevState) => prevState - 1);
        }
    }

    function addValueCancelHandler() {
        setShowAddValue(false);
    }

    function selectNodeHandler(dataNodeId) {
        const valueIndex = filterValues.findIndex((value) => value.dataNodeId === dataNodeId);
        if (valueIndex === -1) {
            return;
        }
        setActiveIndex(valueIndex);
        props.onRowSelect(filterNode.id, dataNodeId);
    }

    function isNodeLinked(index) {
        if (index < 0) {
            return false;
        }
        let filterValue = filterValues[index];
        return filterValue.isLinked;
    }

    function canLinkUnlinkNode(index) {
        if (index < 0) {
            return false;
        }

        // Can't link the default node.
        if (index === filterValues.length - 1) {
            return false;
        }

        // No linking if the node has a sub filter.
        let filterValue = filterValues[index];
        if (filterValue.filterNodeId && !filterValue.isLinked) {
            return false;
        }

        return true;
    }

    function linkUnlinkNodeHandler(index) {
        let filterValue = filterValues[index];
        if (filterValue.isLinked) {
            // Clone the node hierarchy and assign.
            setFilterValues((prevValues) => {
                let values = [...prevValues];
                let linkedFilterNodeId = filterValue.filterNodeId;
                values[index].filterNodeId = FilterUtils.cloneFilterNode(linkedFilterNodeId, props.filterNodes, props.dataNodes);
                values[index].isLinked = false;

                let linkedFilterValue;
                let linkedNodesCount = 0;
                values.forEach((value) => {
                    if (value.isLinked && value.filterNodeId === linkedFilterNodeId) {
                        linkedNodesCount++;
                        linkedFilterValue = value;
                    }
                });

                if (linkedNodesCount === 1) {
                    // Single value left with this filter node.
                    linkedFilterValue.isLinked = false;
                }
                return values;
            });
        } else {
            // Get the user to select the nodes to link with.
            setShowLinkWith(true);
        }
    }

    function applyLinkHandler(index, linkValues) {
        setFilterValues((prevValues) => {
            const filterNodesToRemove = [];
            const linkFilterNodeId = linkValues[0][0].filterNodeId;
            let values = [...prevValues];

            linkValues[0].forEach((filterValue) => {
                // Mark as linked.
                values[filterValue.index].isLinked = true;
            });

            for (let i = 1; i < linkValues.length; i++) {
                // Remember the old link to remove.
                filterNodesToRemove.push(linkValues[i][0].filterNodeId);
                linkValues[i].forEach((filterValue) => {
                    // Update the link.
                    values[filterValue.index].filterNodeId = linkFilterNodeId;
                    values[filterValue.index].isLinked = true;
                });
            }

            let activeValue = values[index];
            if (activeValue.filterNodeId) {
                filterNodesToRemove.push(activeValue.filterNodeId);
            }
            activeValue.filterNodeId = linkFilterNodeId;
            activeValue.isLinked = true;

            // Remove the filter nodes.
            filterNodesToRemove.forEach((filterNodeId) => {
                FilterUtils.removeFilterNode(filterNodeId, props.filterNodes, props.dataNodes);
            });

            return values;
        });
        setShowLinkWith(false);
    }

    function getLinkValues(index) {
        // Get unique filter links emanating from values in this filter level.
        let filterChains = {};
        filterValues.forEach((filterValue, index) => {
            let filterNodeId = filterValue.filterNodeId;
            if (filterNodeId) {
                if (filterChains.hasOwnProperty(filterNodeId)) {
                    filterChains[filterNodeId].push({ ...filterValue, index: index });
                } else {
                    filterChains[filterNodeId] = [{ ...filterValue, index: index }];
                }
            }
        });

        let linkValues = [];
        for (const prop of Object.getOwnPropertyNames(filterChains)) {
            linkValues.push(filterChains[prop]);
        }
        return linkValues;
    }

    function canMoveNode(index, direction) {
        if (index < 0) {
            return false;
        }

        let length = filterValues.length;
        let disallowMove = (index === 0 && direction === 'up') || // First value can't move up
            (index === (length - 1)) || // Last value is default. Can't move.
            (index === (length - 2) && direction === 'down'); // Last (but one) value can't move down
        return !disallowMove;
    }

    function moveNodeHandler(index, direction) {
        if (canMoveNode(index, direction)) {
            let newIndex = index + (direction === 'up' ? -1 : 1);
            setFilterValues((prevValues) => {
                let values = [...prevValues];
                values[index] = prevValues[newIndex];
                values[newIndex] = prevValues[index];
                return values;
            });
            setActiveIndex(newIndex);
        }
    }

    function getFilterDisplayName(name) {
        if (name.startsWith('systemParam.')) {
            return t(name);
        } else {
            return name;
        }
    }

    return (
        <>
            {showLinkWith && <LinkWith name={FilterUtils.getNodeValue(filterValues[activeIndex].dataNodeId, props.dataNodes)} linkValues={getLinkValues(activeIndex)} dataNodes={props.dataNodes} onClose={() => setShowLinkWith(false)} onApply={(values) => applyLinkHandler(activeIndex, values)} />}
            <div className={classes.filterLevel}>
                <div className={classes.filterLevelHeader}>
                    <h3>{getFilterDisplayName(filterNode.key)}</h3>
                    <div className={classes.filterLevelActionBtns}>
                        <IconButton onClick={() => props.onDelete(filterNode.id)} iconImage={trashOutlineImage} />
                        <IconButton onClick={() => linkUnlinkNodeHandler(activeIndex)} iconImage={isNodeLinked(activeIndex) ? unlinkImage : linkImage}
                            disabled={!canLinkUnlinkNode(activeIndex)} />
                        <IconButton onClick={() => moveNodeHandler(activeIndex, 'up')} iconImage={arrowUpImage}
                            disabled={!canMoveNode(activeIndex, 'up')} />
                        <IconButton onClick={() => moveNodeHandler(activeIndex, 'down')} iconImage={arrowDownImage}
                            disabled={!canMoveNode(activeIndex, 'down')} />
                    </div>
                </div>
                {showAddValue && <AddValue onAdd={addValueAddHandler} onCancel={addValueCancelHandler} />}
                <div className={classes.filterLevelList}
                    style={showAddValue ? { height: "calc(100% - 10rem)" } : { height: "calc(100% - 5rem)" }}>
                    <ol>
                        {filterValues.map((value, index) => {
                            const nodeId = value.dataNodeId;
                            const isActive = (index === activeIndex);
                            const isLinkedWithActive = (value.isLinked && !isActive && activeIndex >= 0 && value.filterNodeId === filterValues[activeIndex].filterNodeId);
                            return <FilterNode key={nodeId} nodeId={nodeId} hasSubFilter={value.filterNodeId !== null} nodes={props.dataNodes} isActive={isActive} isLinkedWithActive={isLinkedWithActive} onSelect={selectNodeHandler} onDelete={deleteNodeHandler} />;
                        })}
                    </ol>
                </div>
                <div className={classes.filterLevelFooter}>
                    <div className={classes.addBtnWrapper}>
                        <TextButton label={t('button.addValue')} onClick={addValueHandler} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterLevel;