import React from 'react'
import { render } from 'react-dom';
import App from './App/App';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import browserHistory from './history';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import 'font-awesome/css/font-awesome.min.css';

render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router history={browserHistory}>
        <App />
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);