import React from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../Buttons/PrimaryButton";
import checkCircle from "../../assets/check-circle.svg";
import classes from './SuccessCard.module.css';

function SuccessCard(props) {

    const { t } = useTranslation();
    return (<>
        <div className={classes.backdrop}> </div>
        <div className={classes.modal}>
            <div className={classes.container}>
                <img src={checkCircle} alt="Check-Circle" width="80px" height="80px" />
                <h2> {t('successCard.message.heading')} </h2>
                <div className={classes.messagebox}>
                    <span> {props.message}</span>
                </div>
                <div className={classes.action}>
                    <PrimaryButton onClick={props.onClose} label={props.btnLabel} />
                </div>
            </div>
        </div>
    </>);
}

export default SuccessCard;