import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addJig, deleteJig } from "./JigsActions";
import { useTranslation } from "react-i18next";
import EditJigs from "./EditJigs";
import Confirm from "../../components/Confirm/Confirm";
import JigPreview from "./JigPreview";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import JigItem from "./JigItem";
import classes from "./Jigs.module.css";
import Utils from "../../Utils";
import { parseJigSvg, encodeJigToSvg } from "../../SvgParser";
import BackButton from "../../components/Buttons/BackButton";
import SearchField from "../../components/SearchField/SearchField";
import Modal from "../../components/Modal/Modal";
import { addTable } from "../Tables/TablesActions";

async function getValidFiles(files, validator) {
  let validFiles = validator && validator(files);
  if (validFiles) {
    if (validFiles instanceof Promise) {
      validFiles = await validFiles;
    }
    return validFiles;
  }
  return files;
}
function FileButton({ onChange, accept, multiple, label, validate }) {
  return (
    <div
      onClick={(e) => e.currentTarget.querySelector("input[type=file]").click()}
      style={{ display: "inline-block" }}
    >
      <PrimaryButton label={label} />
      <input
        onChange={(e) => {
          if (onChange) {
            let currentTarget = e.currentTarget;
            getValidFiles(currentTarget.files, validate)
              .then((files) => onChange(files))
              .finally(() => {
                currentTarget.value = "";
              });
          }
        }}
        multiple={multiple}
        type="file"
        accept={accept}
        style={{ display: "none" }}
      />
    </div>
  );
}

function CreatePrintLayout({ onClose }) {
  const { t } = useTranslation();
  const unit = useSelector((state) => state.config.config.unit, shallowEqual);
  const allJigs =
    useSelector((state) => state.jigs.allJigs, shallowEqual) || [];
  const allLocations =
    useSelector((state) => state.master.allMasters.location, shallowEqual) ||
    [];
  const allPrinterTypes =
    useSelector((state) => state.master.allMasters.printerType, shallowEqual) ||
    [];
  const [selectedJig, setSelectedJig] = useState();
  const [printableArea, setPrintableArea] = useState({ x: 0, y: 0 });
  const [table, setTable] = useState({});
  const dispatch = useDispatch();
  const maxWidthOrHeight = Utils.getMaxJigOrTableSize(unit);

  useEffect(() => {
    if (selectedJig) {
      setTable((v) => ({
        ...v,
        printableArea,
        jigs: [{ id: selectedJig.id, x: 0, y: 0 }],
      }));
    }
  }, [printableArea, selectedJig]);

  function widthChangeHandler(e) {
    const value = e.target.value;
    setTable((v) => ({
      ...v,
      width: value > maxWidthOrHeight ? table.width : parseFloat(value),
    }));
  }

  function heightChangeHandler(e) {
    const value = e.target.value;
    setTable((v) => ({
      ...v,
      height: value > maxWidthOrHeight ? table.height : parseFloat(value),
    }));
  }

  function handleSelectJig(e) {
    const id = e.target.value,
      jigs = allJigs.jigs;
    if (jigs) {
      const jig = jigs.find((j) => j.id === id);
      setTable((v) => ({
        ...v,
        height: Number(jig?.height.toFixed(2)),
        width: Number(jig?.width.toFixed(2))
      }));
      if (jig) {
        setSelectedJig(jig);
      } else {
        setSelectedJig({});
      }
    }
  }

  const previewTable = {
    ...selectedJig,
    width: table.width,
    height: table.height,
    printableArea: printableArea,
  };

  return (
    <Modal title={t("jigs.button.createPrintLayouts")} onClose={onClose}>
      <div className={classes.createPrintModal}>
        <div>
          <div className={classes.formController}>
            <label className={classes.layoutName}>
              {t("jigs.createPrintLayouts.layoutName")}
            </label>
            <input
              type="text"
              value={(table && table.name) || ""}
              onChange={(e) =>
                setTable((v) => ({ ...v, name: e.target.value }))
              }
            />
          </div>
          <div className={classes.formController}>
            <label className={classes.layoutName}>
              {t("jigs.createPrintLayouts.selectJig")}
            </label>
            <select onChange={handleSelectJig}>
              <option value="">{t("jigs.createPrintLayouts.selectJig")}</option>
              {allJigs &&
                allJigs.jigs.map((jig, idx) => {
                  return (
                    <option key={jig.id} value={jig.id}>
                      {jig.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className={classes.formController}>
            <div className={classes.jigSizingArea}>
              <div className={classes.formController}>
                <label className={classes.titleLabel}>
                  {t("editTable.table.physicalSize")}
                </label>
                <div className={classes.field}>
                  <label>
                    {t("editTable.table.width")}{" "}
                    {`(${t("configItem.unit.mm")})`}
                  </label>
                  <input
                    type="number"
                    max={maxWidthOrHeight}
                    value={table?.width}
                    min={0}
                    onChange={widthChangeHandler}
                  />
                </div>
              </div>
              <div className={classes.formController}>
                <label></label>
                <div className={classes.field}>
                  <label>
                    {t("editTable.table.height")}{" "}
                    {`(${t("configItem.unit.mm")})`}
                  </label>
                  <input
                    type="number"
                    max={maxWidthOrHeight}
                    value={table?.height}
                    min={0}
                    onChange={heightChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className={classes.jigSizingArea}>
              <div className={classes.formController}>
                <label className={classes.titleLabel}>
                  {t("editTable.table.printArea")}
                </label>
                <div className={classes.field}>
                  <label>
                    {t("editTable.table.width")}{" "}
                    {`(${t("configItem.unit.mm")})`}
                  </label>
                  <input
                    type="number"
                    value={printableArea?.width}
                    min={printableArea?.width}
                    onChange={(e) =>
                      setPrintableArea((v) => ({
                        ...v,
                        width: parseFloat(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>
              <div className={classes.formController}>
                <label></label>
                <div className={classes.field}>
                  <label>
                    {t("editTable.table.height")}{" "}
                    {`(${t("configItem.unit.mm")})`}
                  </label>
                  <input
                    type="number"
                    value={printableArea?.height}
                    min={printableArea?.height}
                    onChange={(e) =>
                      setPrintableArea((v) => ({
                        ...v,
                        height: parseFloat(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>
              <div className={classes.formController}>
                <label className={classes.titleLabel}>
                  {t("jigs.createPrintLayouts.position")}
                </label>
                <div className={classes.field}>
                  <label>
                    {t("editTable.table.xOffset")}{" "}
                    {`(${t("configItem.unit.mm")})`}
                  </label>
                  <input
                    type="number"
                    value={printableArea?.x}
                    min={0}
                    onChange={(e) =>
                      setPrintableArea((v) => ({
                        ...v,
                        x: parseFloat(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>
              <div className={classes.formController}>
                <label></label>
                <div className={classes.field}>
                  <label>
                    {t("editTable.table.yOffset")}{" "}
                    {`(${t("configItem.unit.mm")})`}
                  </label>
                  <input
                    type="number"
                    value={printableArea?.y}
                    min={0}
                    onChange={(e) =>
                      setPrintableArea((v) => ({
                        ...v,
                        y: parseFloat(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.formController}>
            <div className={classes.jigPreviewer}>
              <div style={{ width: "27rem", height: "27rem" }}>
                <label>Preview</label>
                <JigPreview
                  previewJig={previewTable}
                  showOrigin={true}
                  showValidation={true}
                  showPrintableArea={true}
                />
              </div>
              { selectedJig?.locations && <div className={classes.location}>
                <div style={{ whiteSpace: "nowrap" }}>
                  <label>
                    Location:{" "}
                    {selectedJig?.locations
                      ?.map((i) => allLocations[i] || i)
                      .join()}
                  </label>
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  <label>
                    Printer Type:{" "}
                    {selectedJig?.printerTypes
                      ?.map((i) => allPrinterTypes[i] || i)
                      .join()}
                  </label>
                </div>
              </div>}
            </div>
        </div>
      </div>
      <div className={classes.footerButtonWrapper}>
        <div className={classes.rightHeader}>
          <SecondaryButton onClick={onClose} label={t("button.cancel")} />
          <SecondaryButton
            disabled={
              !table || !table.name || !table.jigs || !table.jigs.length
            }
            label={t("jigs.button.createPrintLayouts")}
            onClick={(e) => {
              addTable(dispatch, table);
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

function Jigs(props) {
  const unit = useSelector((state) => state.config.config.unit, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const tableEndRef = useRef();
  const tableStartRef = useRef();
  const allJigs =
    useSelector((state) => state.jigs.allJigs, shallowEqual) || [];
  const [showEditJigs, setShowEditJigs] = useState(false);
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedJig, setSelectedJig] = useState(allJigs.jigs[0]);
  const [previewJig, setPreviewJig] = useState({});
  const [jigId, setJigId] = useState({});
  const [activeRow, setActiveRow] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const permissions = useSelector(
    (state) => state.users.allUsers.currentUser.permissions || ""
  );
  const [showCreate, setShowCreate] = useState(false);

  let allJigNames = new Map();
  allJigs.jigs.forEach((jig) => {
    allJigNames.set(jig.name, true);
  });

  useEffect(() => {
    setSelectedJig(allJigs.jigs[allJigs.selectedIndex]);
    setActiveRow(allJigs.selectedIndex);
  }, [allJigs]);

  function scrollToBottom() {
    tableEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }

  function scrollToTop() {
    tableStartRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }

  function getCopyName(name) {
    return Utils.getCopyName(allJigNames, name);
  }

  function addJigHandler() {
    setPreviewJig({
      locations: ["default"],
      printerTypes: ["default"],
    });
    setShowEditJigs(true);
  }

  function editJigCloseHandler() {
    setShowEditJigs(false);
  }

  function deleteJigCloseHandler() {
    setShowConfirmation(false);
  }

  function confirmJigDeleteHandler() {
    setShowConfirmation(false);
    deleteJig(dispatch, jigId).then((response) => {
      if (response && response.data && response.data.success) {
        setShowError(false);
        scrollToTop();
      } else {
        setErrorMessage(t("jigs.error.deleteFailed"));
        setShowError(true);
      }
    });
  }

  function selectJigHandler(jig, index) {
    setSelectedJig(jig);
    setPreviewJig(jig);
    setActiveRow(index);
  }

  function editJigHandler(jig) {
    setSelectedJig(jig);
    setPreviewJig(jig);
    setShowEditJigs(true);
  }

  function deleteJigHandler(id) {
    setShowConfirmation(true);
    setShowEditJigs(false);
    setJigId(id);
  }

  function changeSearchTextHandler(e) {
    setSearchText(e.target.value);
  }

  function copyJigHandler(jig) {
    const data = {
      ...jig,
      name: getCopyName(jig.name),
    };
    delete data.id;
    addJig(dispatch, data).then((response) => {
      if (response && response.data && response.data.success) {
        setShowError(false);
        scrollToBottom();
      } else {
        setShowError(true);
        setErrorMessage(t("jigs.error.copyFailed"));
      }
    });
  }

  function downloadJigHandler(jig) {
    let svg = encodeJigToSvg(jig),
      a = document.createElement("a"),
      url = window.URL.createObjectURL(
        new Blob([svg], { type: "image/svg+xml" })
      );
    a.href = url;
    a.download = jig.name + ".svg";
    a.click();
    window.URL.revokeObjectURL(url);
  }

  function closeErrorHandler() {
    setShowError(false);
  }

  function onSvgFileOpen(svgString, name) {
    try {
      let data = parseJigSvg(svgString);
      name = name.replace(/\..*/, "");
      data.name = getCopyName(name);
      addJig(dispatch, data).then((response) => {
        if (!response || !response.data || !response.data.success) {
          setErrorMessage(t("jigs.error.importFailed"));
        } else {
          scrollToBottom();
        }
      });
    } catch (err) {
      if (err.errorCode === 1) {
        setErrorMessage(t("jigs.error.svgParseFailed"));
        setShowError(true);
      } else if (err.errorCode === 2) {
        setErrorMessage(t("jigs.error.slotsExceedJig"));
        setShowError(true);
      } else if (err.errorCode === 3) {
        setErrorMessage(t("jigs.error.imageExceedsSlot"));
        setShowError(true);
      }
    }
  }

  function filterJig(jig, searchText) {
    if (jig.name) {
      const name = jig.name.toLowerCase();
      if (name.includes(searchText.toLowerCase())) {
        return true;
      }
    }
    if (jig.referenceText) {
      const refText = jig.referenceText.toLowerCase();
      if (refText.includes(searchText.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  function clickHistoryHandler() {
    history.push(`/${window.getResourceName()}/settings/layouts`);
  }

  return (
    <>
      {showError && Utils.showErrorDialog(errorMessage, closeErrorHandler)}
      {showEditJigs && (
        <EditJigs
          editJigsCloseHandler={editJigCloseHandler}
          previewJig={previewJig}
          scrollToBottom={scrollToBottom}
        />
      )}
      {showConfirmation && (
        <Confirm
          onClose={deleteJigCloseHandler}
          onConfirm={confirmJigDeleteHandler}
          title={t("button.delete")}
          message={t("jigs.delete.message")}
          confirmLabel={t("button.delete")}
          cancelLabel={t("button.cancel")}
        />
      )}
      <div className={classes.main}>
        <div className={classes.leftContainer}>
          <div className={classes.backButton}>
            <BackButton onBack={() => props.history.goBack()} />
          </div>
          <div className={classes.container}>
            <div className={classes.heading}>
              <h2> {t("jigs.heading")} </h2>
            </div>
            <div className={classes.primaryBtn}>
              <SearchField
                onChange={changeSearchTextHandler}
                label={t("label.search")}
              />
              {Utils.canEditJigs(permissions) && (
                <>
                  <FileButton
                    label={t("jigs.button.import")}
                    accept="image/svg+xml"
                    onChange={(files) => {
                      if (files.length) {
                        let file = files[0];
                        Utils.readFileAsText(file).then((text) =>
                          onSvgFileOpen(text, file.name)
                        );
                      }
                    }}
                  />
                  <PrimaryButton
                    onClick={addJigHandler}
                    label={t("jigs.button.add")}
                  />
                </>
              )}
            </div>
          </div>
          <div className={classes.jigsTable}>
            <div ref={tableStartRef} />
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>{t("jigs.id")}</th>
                  <th style={{ width: "20%", textAlign: "left" }}>
                    {t("jigs.name")}
                  </th>
                  <th style={{ width: "15%" }}>
                    {Utils.appendUnits(t("jigs.width"), unit)}
                  </th>
                  <th style={{ width: "15%" }}>
                    {Utils.appendUnits(t("jigs.height"), unit)}
                  </th>
                  <th style={{ width: "15%" }}>
                    {Utils.appendUnits(t("jigs.thickness"), unit)}
                  </th>
                  <th style={{ width: "25%" }}>{t("jigs.action")}</th>
                </tr>
              </thead>
              <tbody>
                {allJigs &&
                  allJigs.jigs.map((jig, index) => {
                    if (
                      searchText.length < 3 ||
                      (searchText.length >= 3 && filterJig(jig, searchText))
                    ) {
                      return (
                        <JigItem
                          key={jig.id}
                          jig={jig}
                          index={index}
                          onSelect={selectJigHandler}
                          onDelete={deleteJigHandler}
                          onEdit={editJigHandler}
                          onCopy={copyJigHandler}
                          onDownload={downloadJigHandler}
                          isActive={activeRow === index}
                        />
                      );
                    }
                    return null;
                  })}
              </tbody>
            </table>
            <div ref={tableEndRef} />
          </div>
        </div>
        <div className={classes.rightConatiner}>
          <div className={classes.rightHeader}>
            {Utils.canEditTables(permissions) && (
              <SecondaryButton
                onClick={() => setShowCreate(true)}
                label={t("jigs.button.createPrintLayouts")}
              />
            )}
            {Utils.canViewTables(permissions) && (
              <SecondaryButton
                onClick={clickHistoryHandler}
                label={t("jigs.button.openPrintLayouts")}
              />
            )}
          </div>
          <div className={classes.preview}>
            {selectedJig && (
              <div>
                {" "}
                <h2 title={selectedJig.name}> {selectedJig.name} </h2>
                <p>
                  Size -{" "}
                  {Utils.formatMMSizeToUnits(
                    selectedJig.width,
                    selectedJig.height,
                    unit
                  )}{" "}
                </p>
              </div>
            )}
            {selectedJig && (
              <JigPreview
                previewJig={selectedJig}
                showOrigin={false}
                showValidation={false}
              />
            )}
          </div>
        </div>
        {showCreate && (
          <CreatePrintLayout
            onClose={() => setShowCreate(false)}
            selectedJig={selectedJig}
          />
        )}
      </div>
    </>
  );
}

export default Jigs;
