import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updateCustomParams } from "../InputSettings/InputSettingsActions";
import Modal from "../../components/Modal/Modal";
import CustomParamsData from "./CustomParamsData";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Utils from "../../Utils";
import classes from "./CustomParams.module.css";

export default function ({ onClose, onSave }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const inputSettings = useSelector((state) => state.inputSettings.inputSettings, shallowEqual);
    const [customParams, setCustomParams] = useState(inputSettings.customParams.filter(e => e.type === "topLevel") || []);
    const [showError, setShowError] = useState(false);

    function changeCustomParams(value) {
        setCustomParams(value);
    }

    function saveHandler() {
        updateCustomParams(dispatch, customParams).then((response) => {
            if (response) {
                onSave && onSave();
            }
            else {
                setShowError(true);
            }
        });
    }

    function closeErrorHandler() {
        setShowError(false)
    }

    return (
        <Modal title={t('customParams.heading')} onClose={() => onClose && onClose()}>
            {showError && Utils.showErrorDialog(t('customParams.error.updateFailed'), closeErrorHandler)}
            <div className={classes.main}>
                <div className={classes.listContainer}>
                    <CustomParamsData customParams={customParams}
                        onChange={changeCustomParams}
                    />
                </div>
                <div className={classes.action}>
                    <SecondaryButton onClick={() => onClose && onClose()} label={t('button.cancel')} />
                    <PrimaryButton label={t('button.save')} onClick={saveHandler} />
                </div>
            </div>
        </Modal>
    );
};