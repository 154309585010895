import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Modal from "../../components/Modal/Modal";
import ReferencePoint from "../../components/ReferencePoint/ReferencePoint";
import UnitInput from "../../components/UnitInput/UnitInput";
import Utils from "../../Utils";
import classes from './AddMultipleSlots.module.css';
import SlotPreview from "./SlotPreview";

function AddMultipleSlots(props) {

    const unit = useSelector((state) => state.config.config.unit, shallowEqual);
    const slots = props.slots;
    const { t } = useTranslation();
    const [width, setWidth] = useState(slots.length ? slots[0].width : props.width / 5);
    const [height, setHeight] = useState(slots.length ? slots[0].height : props.height / 5);
    const [horizontalMargin, setHorizontalMargin] = useState(slots.length ? slots[0].x : props.margin);
    const [verticalMargin, setVerticalMargin] = useState(slots.length ? slots[0].y : props.margin);
    const [imageBoxX, setImageBoxX] = useState(slots.length ? slots[0].imageBox.x : 0);
    const [imageBoxY, setImageBoxY] = useState(slots.length ? slots[0].imageBox.y : 0);
    const [imageBoxWidth, setImageBoxWidth] = useState(slots.length ? slots[0].imageBox.width : props.width / 5);
    const [imageBoxHeight, setImageBoxHeight] = useState(slots.length ? slots[0].imageBox.height : props.height / 5);
    const [rotation, setRotation] = useState(slots.length ? slots[0].imageBox.rotation : 0);
    const [mirror, setMirror] = useState(slots.length ? slots[0].imageBox.mirror : "NONE");
    const [scale, setScale] = useState(slots.length ? slots[0].imageBox.scale : "TRUE");
    const [placement, setPlacement] = useState(slots.length ? slots[0].imageBox.placement : "BOTTOM_RIGHT");
    const [imageBoxPlacement, setImageBoxPlacement] = useState("BOTTOM_RIGHT");
    const [previewSlot, setPreviewSlot] = useState({});

    useEffect(() => {
        setPreviewSlot((prevState) => {
            const temp = { ...prevState };
            temp.width = width;
            temp.height = height;
            temp.imageBox = {
                x: imageBoxX,
                y: imageBoxY,
                width: imageBoxWidth,
                height: imageBoxHeight,
                rotation: rotation,
                mirror: mirror,
                scale: scale,
                placement: placement
            }
            return temp;
        })
    }, [width, height, imageBoxX, imageBoxY, imageBoxWidth, imageBoxHeight, rotation, mirror, scale, placement])

    useEffect(() => {
        repositionImageBox(imageBoxPlacement);
    }, [width, height, imageBoxWidth, imageBoxHeight])

    function isAddEnabled() {
        if (Utils.isImageBoxInsideSlot(previewSlot)) {
            return true;
        }
        return false;
    }

    function repositionImageBox(position) {
        let [ypos, xpos] = position.split("_");

        if (ypos === "BOTTOM") {
            ypos = 0;
        } else if (ypos === "MIDDLE") {
            ypos = (height - imageBoxHeight) / 2;
        } else {
            ypos = (height - imageBoxHeight);
        }

        if (xpos === "LEFT") {
            xpos = (width - imageBoxWidth)
        } else if (xpos === "CENTER") {
            xpos = (width - imageBoxWidth) / 2;
        } else {
            xpos = 0;
        }

        if (xpos < 0) {
            xpos = 0;
        }
        if (ypos < 0) {
            ypos = 0;
        }

        setImageBoxX(xpos);
        setImageBoxY(ypos);
    }

    return (<>
        <Modal title={t("addMultiSlots.title")} onClose={props.onClose}>
            <div className={classes.main}>
                <div className={classes.leftContainer}>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.width'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="width"
                            onChange={setWidth}
                            min={0}
                            value={width}
                        />
                    </div>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.height'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="height"
                            onChange={setHeight}
                            min={0}
                            value={height}
                        />
                    </div>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.horizonMargin'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="marginX"
                            onChange={setHorizontalMargin}
                            min={0}
                            value={horizontalMargin}
                        />
                    </div>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.verticalMargin'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="marginY"
                            onChange={setVerticalMargin}
                            min={0}
                            value={verticalMargin}
                        />
                    </div>
                </div>
                <div className={classes.middleContainer}>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.imageBoxX'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="imageBoxX"
                            onChange={setImageBoxX}
                            min={0}
                            maxValue={width}
                            value={imageBoxX}
                            disabled={imageBoxPlacement !== "BOTTOM_RIGHT"}
                            changedByCode={true}
                        />
                    </div>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.imageBoxY'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="imageBoxY"
                            onChange={setImageBoxY}
                            min={0}
                            maxValue={height}
                            value={imageBoxY}
                            disabled={imageBoxPlacement !== "BOTTOM_RIGHT"}
                            changedByCode={true}
                        />
                    </div>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.imageBoxWidth'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="imageBoxWidth"
                            onChange={setImageBoxWidth}
                            min={0}
                            maxValue={imageBoxPlacement === "BOTTOM_RIGHT" ? width - imageBoxX : width}
                            value={imageBoxWidth}
                        />
                    </div>
                    <div className={classes.slotField}> <span>{Utils.appendUnits(t('addMultiSlots.imageBoxHeight'), unit)}</span> </div>
                    <div className={classes.slotField}>
                        <UnitInput name="imageBoxHeight"
                            onChange={setImageBoxHeight}
                            min={0}
                            maxValue={imageBoxPlacement === "BOTTOM_RIGHT" ? height - imageBoxY : height}
                            value={imageBoxHeight}
                        />
                    </div>
                    <div className={classes.slotField}> <span>{t('addMultiSlots.imageBox.placement')}</span> </div>
                    <div className={classes.slotField}>
                        <ReferencePoint onChange={(position) => {
                            setImageBoxPlacement(position);
                            repositionImageBox(position);
                        }} placement={imageBoxPlacement} id="imagebox_placement" />
                    </div>
                </div>
                <div className={classes.rightContainer}>
                    <div className={classes.slotField}> <span>{t('addMultiSlots.rotation')}</span> </div>
                    <div className={classes.slotField}>
                        <select name="rotation" onChange={(e) => {
                            setRotation(parseInt(e.target.value));
                        }
                        } value={rotation} >
                            <option value={0}> {t('editJig.slotEntry.selectNone')}</option>
                            <option value={90}>90</option>
                            <option value={180}>180</option>
                            <option value={270}>270</option>
                        </select> </div>
                    <div className={classes.slotField}> <span>{t('addMultiSlots.mirror')}</span> </div>
                    <div className={classes.slotField}>
                        <select name="mirror" onChange={(e) => {
                            setMirror(e.target.value);
                        }} value={mirror} >
                            <option value="NONE"> {t('editJig.slotEntry.selectNone')}</option>
                            <option value="HORIZONTAL">{t('editJig.slotEntry.horizontal')}</option>
                            <option value="VERTICAL">{t('editJig.slotEntry.vertical')}</option>
                        </select>
                    </div>
                    <div className={classes.slotField}> <span>{t('addMultiSlots.scale')}</span> </div>
                    <div className={classes.slotField}>
                        <select name="scale"
                            onChange={(e) => {
                                setScale(e.target.value);
                                if (e.target.value === "FILL") {
                                    setPlacement("MIDDLE_CENTER");
                                }
                            }} value={scale}>
                            <option value="TRUE">{t('editJig.slotEntry.scaleOptionTrue')}</option>
                            <option value="FIT">{t('editJig.slotEntry.scaleOptionFit')}</option>
                            <option value="FILL">{t('editJig.slotEntry.scaleOptionFill')}</option>
                            <option value="FIT_WIDTH">{t('editJig.slotEntry.scaleOptionFitWidth')}</option>
                            <option value="FIT_HEIGHT">{t('editJig.slotEntry.scaleOptionFitHeight')}</option>
                        </select>
                    </div>
                    <div className={classes.slotField}> <span>{t('addMultiSlots.placement')}</span> </div>
                    <div className={classes.slotField}>
                        <ReferencePoint onChange={(position) => {
                            setPlacement(position);
                        }} placement={placement || "BOTTOM_RIGHT"} disabled={scale === "FILL"} id="image_placement" />
                    </div>
                </div>
                <div className={classes.previewContainer}>
                    <div className={classes.previewHeading}>
                        <h6>{t('preview.heading')}</h6>
                    </div>
                    <div className={classes.previewContents}>
                        <SlotPreview previewSlot={previewSlot} showOrigin={true} showValidation={true} />
                    </div>
                </div>
                <div className={classes.actions}>
                    <SecondaryButton onClick={props.onClose} label={t('button.cancel')} />
                    <PrimaryButton disabled={!isAddEnabled()} onClick={() => props.onAdd(Utils.addMultipleSlots(slots, props.width, props.height, width, height, horizontalMargin, verticalMargin,
                        imageBoxX, imageBoxY, imageBoxWidth, imageBoxHeight, rotation, mirror, scale, placement))} label={t('button.add')} />
                </div>
            </div>
        </Modal>
    </>)
}

export default AddMultipleSlots;