import { createSlice } from "@reduxjs/toolkit";

function getDefaultMasters() {
  return {
    qualityLevel: {},
    location: {},
    printerType: {},
    mediaType: {},
    colorConfig: {}
  }
}

const initialState = {
  allMasters: getDefaultMasters()
};

const masterSlice = createSlice({
  name: "masters",
  initialState,
  reducers: {
    fetchMasters: (state, action) => {
      state.allMasters = {
        ...state.allMasters,
        ...action.payload.masters
      };
    },
    addMaster: (state, action) => {
      state.allMasters = action.payload.allMasters;
    }
  },
});

export const masterActions = masterSlice.actions;

export default masterSlice.reducer;
