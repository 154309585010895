import React from "react";
import classes from './Card.module.css';

function Card(props) {
    return (<>
        <div className={classes.card} style={{ width: props.width, height: props.height }}>
            {props.children}
        </div>

    </>)
}

export default Card;