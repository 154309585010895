import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allJigs: { jigs: [], selectedIndex: 0 },
};

const jigsSlice = createSlice({
  name: "jigs",
  initialState,
  reducers: {
    fetchJigs: (state, action) => {
      state.allJigs.jigs = action.payload.jigs;
    },
    createJig: (state, action) => {
      state.allJigs.jigs.push(action.payload.jig);
      state.allJigs.selectedIndex = state.allJigs.jigs.length - 1;
    },
    updateJig: (state, action) => {
      const index = state.allJigs.jigs.findIndex(
        (item) => item.id === action.payload.jig.id
      );
      state.allJigs.jigs.splice(index, 1, action.payload.jig);
      state.allJigs.selectedIndex = index;
    },
    deleteJig: (state, action) => {
      const index = state.allJigs.jigs.findIndex(
        (item) => item.id === action.payload.id
      );
      state.allJigs.jigs.splice(index, 1);
      if (state.allJigs.jigs.length) {
        state.allJigs.selectedIndex = 0;
      }
      else {
        state.allJigs.selectedIndex = -1;
      }
    },
  },
});

export const jigsActions = jigsSlice.actions;

export default jigsSlice.reducer;
