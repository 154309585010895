import React, { useState, useEffect, useRef } from 'react';
import classes from './DropDownMenu.module.css';
import dropDownImage from '../../assets/dropdown-outline.svg';

function DropDownMenu(props) {

    const ref = useRef();
    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (showChildren && ref.current && !ref.current.contains(e.target)) {
                setShowChildren(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showChildren]);

    return <div className={classes.main} ref={ref}>
        <div className={classes.header} onClick={() => setShowChildren(state => !state)}  >
            <p>{props.title}</p>
            <div className={classes.dropdownImage} style={{
                backgroundImage: `url(${dropDownImage})`
            }}>
            </div>
        </div>
        {showChildren && <div className={classes.children} onClick={() => setShowChildren(false)}>
            {props.children}
        </div>}
    </div>
}

export default DropDownMenu;