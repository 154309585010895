import React from "react";
import classes from "./ConfigItem.module.css";

function ConfigItem(props) {
  return (
    <div className={classes.main}>
      <div className={classes.leftContainer}>
        <input
          className={classes.checkBox}
          type="checkbox"
          onChange={(e)=>props.onCheckChange(e.target.checked)}
          checked={props.checked} 
        />
      </div>
      <div className={classes.rightContainer}>
        {props.Data.map((item, index) => {
          return (<div key={`config-item-${index}`} className={classes.item}>
            <div className={classes.text}> <p>{item.title} </p> </div>
            <div className={classes.itemRight}>
              <input
                className={item.type === "number" ? classes.numberInputBox : classes.timeInputBox}
                type={item.type}
                min={item.min}
                disabled={item.disabled}
                onChange={(event) => {
                  if (item.type === "number") {
                    let value = parseInt(event.target.value);
                    if (item.min && value < item.min) {
                      value = item.min;
                    }
                    if (item.onValueChange) {
                      item.onValueChange(value);
                    }
                  } else {
                    item.onValueChange(event.target.value);
                  }
                }}
                value={item.value}
              />
              <div className={classes.durationTypeBox}><p>{item.durationType}</p></div>
            </div>
          </div>);
        })}
      </div>
    </div>);
}

export default ConfigItem;