import axios from '../../axios';
import { outputSettingsActions } from "./OutputSettingsSlice";

export function getAllMyPrinters(dispatch) {
    return axios({
        url: "/pax/myprinters",
        method: "get",
        responseType: "json",
    })
        .then(function (response) {
            let data = response.data;
            if (data.success && data.myPrinterData) {
                dispatch(outputSettingsActions.fetchMyPrinters({ myPrinters: data.myPrinterData || [] }));
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getAllPrinters(dispatch) {
    return axios({
        url: "/pax/myprinters/allPrinters",
        method: "get",
        responseType: "json",
    })
        .then(function (response) {
            let data = response.data;
            if (data.success && data.allPrinterData) {
                dispatch(outputSettingsActions.fetchMyPrinters({ myPrinters: data.allPrinterData || [] }));
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function updateMyPrinters(dispatch, data) {
    return axios({
        url: "/pax/myprinters",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                dispatch(outputSettingsActions.updateMyPrinters({ myPrinters: data.data }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}
