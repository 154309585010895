import { useEffect, useState } from "react";
export function useIdMap(...args) {
    const [maps, setMaps] = useState([]);
    useEffect(() => {
        setMaps(args.map(arg => {
            const map = {};
            arg.forEach(e => {
                map[e.id] = e;
            });
            return map;
        }));
    }, args)
    return maps;
}