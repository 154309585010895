import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../../components/Buttons/IconButton';
import trashOutlineImage from "../../assets/trash-outline.svg";
import copyOutlineImage from "../../assets/copy-outline.svg";
import Consts from '../../Consts';

function RuleList(props) {

    const { t } = useTranslation();
    const index = props.index;
    const activeIndex = props.activeIndex;
    const myStyle = (index === activeIndex) ? { background: Consts.SELECTION_COLOR } : { background: 'white' };
    return (
        <tr style={myStyle} onClick={() => props.onClick(index)}>
            <td> {index + 1} </td>
            <td style={{ "textAlign": "left" }}>{String.format(t('ruleList.name'), index + 1)}</td>
            <td> <IconButton
                onClick={(e => {
                    e.stopPropagation();
                    props.onCopy(index)
                })}
                iconImage={copyOutlineImage}
                tooltip={t('tooltip.copy')}
                altText="Copy Rule" />
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onDelete(index)
                    }}
                    iconImage={trashOutlineImage}
                    tooltip={t('tooltip.delete')}
                    altText="Delete Rule" />
            </td>
        </tr>
    )
}

export default RuleList;