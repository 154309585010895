import React, { useEffect, useRef, useState } from "react";
import ExtendFilter from "./ExtendFilter";
import InlineTextEditor from "../../components/InlineTextEditor/InlineTextEditor";
import Consts from "../../Consts";
import IconButton from "../../components/Buttons/IconButton";
import Icon from "../../components/Icon/Icon";
import trashOutlineImage from "../../assets/trash-outline.svg";
import editOutlineImage from "../../assets/edit-outline.svg";
import actionsOutlineImage from "../../assets/actions-outline.svg";
import actionsAppliedOutlineImage from "../../assets/actions-applied-outline.svg";
import rightArrowImage from "../../assets/right-arrow.svg";
import classes from "./FilterNode.module.css";

function getNodeValue(node) {
    if (typeof(node.value) === "object") {
        return node.value[0];
    } else {
        return node.value;
    }
}

function setNodeValue(node, value) {
    if (typeof(node.value) === "object") {
        node.value[0] = value;
    } else {
        node.value = value;
    }
}

function FilterNode(props) {
    const ref = useRef();
    const dataNode = props.nodes[props.nodeId];
    const [value, setValue] = useState(getNodeValue(dataNode));
    const [isEditing, setIsEditing] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(true);
    const [showExtendFilter, setShowExtendFilter] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isEditing && ref.current && !ref.current.contains(e.target)) {
                setIsEditing(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isEditing]);

    function valueChangedHandler(newValue) {
        setSaveEnabled(newValue !== "");
    }

    function saveHandler(newValue) {
        setValue(newValue);
        setNodeValue(dataNode, newValue);
        setIsEditing(false);
    }

    function extendFilterCloseHandler() {
        setShowExtendFilter(false);
    }

    let backgroundColor = "";
    if (props.isActive) {
        backgroundColor = Consts.SELECTION_COLOR;
    } else if (props.isLinkedWithActive) {
        backgroundColor = Consts.SECONDARY_COLOR;
    }

    return (
        <>
            {showExtendFilter && <ExtendFilter onClose={extendFilterCloseHandler} item={dataNode} isDefault={getNodeValue(dataNode) === "default"} />}
            <li style={{background: `${backgroundColor}`}} ref={ref}>
                <div className={classes.filterNode} onClick={() => {
                    props.onSelect(props.nodeId);
                }
                } >
                    <span title={value}>{value}</span>
                    {isEditing && <InlineTextEditor onSave={saveHandler} onClose={() => setIsEditing(false)} saveEnabled={saveEnabled} value={getNodeValue(dataNode)} onChange={valueChangedHandler} />
                    }
                    <div className={classes.actionButton}>
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            setShowExtendFilter(true);
                        }} iconImage={Object.keys(dataNode.actions).length === 0 ? actionsOutlineImage : actionsAppliedOutlineImage} />
                    </div>
                    {(value !== "default") &&
                        <div className={classes.editButtons}>
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(true);
                            }} iconImage={editOutlineImage} />
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                props.onDelete(props.nodeId);
                            }} iconImage={trashOutlineImage} />
                        </div>
                    }
                    {props.hasSubFilter && !props.isLinkedWithActive &&
                        <div className={classes.subMenuPopupButton}>
                            <Icon width="2rem" height="2rem" image={rightArrowImage} />
                        </div>
                    }
                </div>
            </li>
        </>
    );
}

export default FilterNode;