import React from "react";
import classes from "./DropDownItem.module.css";

function DropDownItem(props) {

    return <div className={classes.main} onClick={props.onClick}>
        {props.imageIcon && <div className={classes.iconImage} style={{
            backgroundImage: `url(${props.imageIcon})`,
        }}>
        </div>}
        <p>{props.title}</p>
    </div>
}

export default DropDownItem;