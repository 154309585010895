import React, { useState, useEffect, useRef } from "react";
import menuImage from "../../assets/hamburger-black-outline.svg";
import classes from "./SideMenu.module.css";

function SideMenu(props) {

    const ref = useRef();
    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (showChildren && ref.current && !ref.current.contains(e.target)) {
                setShowChildren(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showChildren]);

    const childrenStyle = {visibility : showChildren ? "visible" : "hidden" };

    return <div className={classes.main} ref={ref}>
        <div className={classes.children} onClick={() => setShowChildren(false)} style={childrenStyle}>
            {props.children}
        </div>
        <div className={classes.header} onClick={() => setShowChildren(state => !state)}  >
            <div className={classes.dropdownImage} style={{
                backgroundImage: `url(${menuImage})`
            }}>
            </div>
        </div>
    </div>
}

export default SideMenu;