import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateHelpMessage } from "./ConfigActions";
import Modal from "../../components/Modal/Modal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Utils from "../../Utils";
import classes from "./EditHelpMessage.module.css";

function EditHelpMessage(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const initialHelpMessage = useSelector((state) => state.config.config.helpMessage || "");

    const [message, setMessage] = useState(initialHelpMessage);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setMessage(typeof initialHelpMessage === 'object' ? JSON.stringify(initialHelpMessage) : initialHelpMessage);
    }, [initialHelpMessage])

    function isSaveDisabled() {
        if (message === "") {
            return true;
        }
        return false;
    }

    function helpMessageSaveHandler() {
        let msg;
        try {
            msg = JSON.parse(message);
        } catch(e) {
            msg = message;
        }
        updateHelpMessage(dispatch, msg).then((response) => {
          if (!(response && response.data && response.data.success)) {
            setShowError(true);
          } else {
            setShowError(false);
            props.onClose();
          }
        })
      }

    return (
    <Modal title={t('config.title.editHelpMessage')} onClose={props.onClose}>
        {showError && Utils.showErrorDialog(t('config.error.helpMessageUpdateFailed'), () => setShowError(false))}
        <div className={classes.main}>
            <div className={classes.body}>
                <textarea className={classes.messageBox} value={message} onChange={(e) => setMessage(e.target.value)}/>
            </div>
            <div className={classes.actionBtn}>
                <SecondaryButton label={t('button.cancel')} onClick={props.onClose} />
                <PrimaryButton label={t('button.save')} onClick={helpMessageSaveHandler} disabled={isSaveDisabled()}/>
            </div>
        </div>
    </Modal>);
}

export default EditHelpMessage;