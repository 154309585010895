import React from 'react'
import GridTable from "@nadavshaar/react-grid-table";
import Consts from '../../../Consts';

function OrderTable(props) {
    return (
        <GridTable columns={props.columns}
            rows={props.rows} style={{ width: 'auto', minHeight: 'unset' }} className="order-grid"
            isPaginated={false} showColumnVisibilityManager={true} showSearch={true} texts={{ search: "Search Order :", columnVisibility: "Show/Hide Columns" }}
            onColumnsChange={(columns) => {
                const columnsOrdering = columns.map((column) => {
                    return {
                        field: column.field,
                        visible: column.visible,
                        width: column.width
                    };
                });
                const storageKey = props.isActive ? Consts.STORAGE_KEY_ACTIVE_COLUMNS : Consts.STORAGE_KEY_HISTORY_COLUMNS;
                localStorage.setItem(storageKey, JSON.stringify(columnsOrdering));
                props.setColumns(columns);
            }}
            onRowClick={async ({ rowIndex, data, column, isEdit, event }, tableManager) => {
                let api = tableManager.rowSelectionApi,
                    prev = api.selectedRowsIds[0];
                if (data.id !== prev && api.getIsRowSelectable(data.id)) {
                    if (prev) {
                        api.toggleRowSelection(prev);
                        api.selectedRowsIds.splice(0, api.selectedRowsIds.length);
                    }
                    api.toggleRowSelection(data.id);
                }
                props.setOrder(data);
                props.setTableActiveIndex(rowIndex - 1);
            }}
        />
    );
}

export default OrderTable