import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import classes from './Orders.module.css';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import TextButton from '../../../components/Buttons/TextButton';
import OrderPreview from './OrderPreview';
import { printJobSheet, printOrders, printJobLabels } from '../OrdersActions';
import Utils from '../../../Utils';
import OrderUtils from './OrderUtils';
import Confirm from '../../../components/Confirm/Confirm';
import UpdateOutputPreset from '../../OutputPreset/OutputPreset';
import OutputPreset from './OutputPreset';
import { useIdMap } from '../../../custom-hooks/IdMap';
import Instruction from '../../../components/Instruction/Instruction';

function LocationMap(map, allTables) {
    this.map = map;
    this.allTables = allTables;
}
LocationMap.prototype.get = function({location}) {
    if (!location || location === 'NA') {
        return this.allTables;
    }
    return this.map[location];
}

function convertPresetForEdit(preset, tableMap, outputChannelMap) {
	const newPreset = {...preset};

	newPreset.table = tableMap[newPreset?.tableId];
	newPreset.outputChannel = outputChannelMap[newPreset?.outputChannelId];

	delete newPreset.tableId;
	delete newPreset.outputChannelId;

	return newPreset;
}

function getFullTable(ta, jigMap) {
    if (!ta || !jigMap) {
        return;
    }
    return {...ta, jigs: ta.jigs.map(j => ({
        ...j,
        ...jigMap[j.id]
    }))}
}

function convertPresetForSave(preset) {
	const newPreset = {...preset};
	delete newPreset.table;
	delete newPreset.outputChannel;

	newPreset.tableId = preset.table?.id;
	newPreset.outputChannelId = preset.outputChannel?.id;

	return newPreset;
}

function setIntersection(...sets) {
    let fir = sets[0], idx = 0;
    for (let i = 0; i < sets.length; ++i) {
        const a = sets[i];
        if (a.size < fir.size) {
            fir = a;
            idx = i;
        }
    }
    sets.splice(idx, 1);

    if (!fir) {
        return new Set();
    } 
    if (!sets.length) {
        return fir;
    }
    const ans = new Set();
    for (let item of fir) {
        let has = true;
        for (let s of sets) {
            if (!s.has(item)) {
                has = false;
                break;
            }
        }
        if (has) {
            ans.add(item);
        }
    }
    return ans;
}

function computeLocationMap(allJigs, allTables) {
    const jigMap = {};
    allJigs.forEach(j => jigMap[j.id] = j);
    const tablesLocations = allTables.map(t => {
        const jigsLocations = t.jigs.map(j => new Set(jigMap[j.id]?.locations));
        return setIntersection(...jigsLocations.filter(ls => ls.size));
    });
    const map = {};
    tablesLocations.forEach((locs, idx) => {
        const tab = allTables[idx];
        for (let loc of locs) {
            if (!map[loc]) {
                map[loc] = [];
            } 
            map[loc].push(tab);
        }
    })
    return new LocationMap(map, allTables);
}

export default function PrintPanel({outputPresets, selectedUser, allOutputChannels, selectionIndex = 0, onError, onSelectChange, onPresetUpdate, hidePrintBtn, showLayout}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");

    const [locationToTables, setLocationToTables] = useState();
	const tables = locationToTables?.get(selectedUser) || [];
	const [showClearPresetConfirmation, setShowClearPresetConfirmation] = useState(false);
	const [showOutputPreset, setShowOutputPreset] = useState(false);
	const [outputPresetIndex, setOutputPresetIndex] = useState(selectionIndex);
	const [outputPreset, setOutputPreset] = useState({});
    const allJigs = useSelector((state) => state.jigs.allJigs.jigs, shallowEqual) || [];
    const allTables = useSelector((state) => state.tables.allTables.tables, shallowEqual) || [];

    const [userTables, setUserTables] = useState([]);

	const [tableMap] = useIdMap(userTables);
	const [jigMap] = useIdMap(allJigs);
	const [outputChannelMap] = useIdMap(allOutputChannels);

	const [fullOutputPresets, setFullOutputPresets] = useState([]);
    const [tableData, setTableData] = useState([]);

	let canClearPreset = false
	if (Utils.canChangeOutputPreset(permissions) && outputPreset && outputPreset.table && outputPreset.outputChannel) {
		canClearPreset = true;
	}
    
    useEffect(() => {
        if (allJigs && allTables) {
            setLocationToTables(computeLocationMap(allJigs, allTables));
        }
    }, [allJigs, allTables])

	useEffect(() => {
        if (onSelectChange && outputPresets) {
		    onSelectChange(outputPresetIndex);
        }
	}, [outputPresetIndex]);

    useEffect(() => {
        if (tables && jigMap) {
            setUserTables(tables.map(t => getFullTable(t, jigMap)))
        }
	}, [tables, jigMap]);

	useEffect(() => {
		if (outputPresets.length && tableMap && outputChannelMap) {
			const fullPresets = outputPresets.map(p => convertPresetForEdit(p, tableMap, outputChannelMap));
			setFullOutputPresets(fullPresets);
            setTableData(outputPresets.map(p => ({...tableMap[p.tableId], tableData: p.tableData })));
		}
	}, [outputPresets, tableMap, outputChannelMap]);
	
	useEffect(() => {
		const preset = fullOutputPresets[selectionIndex];
		if (preset) {
			setOutputPreset(preset);
			setOutputPresetIndex(selectionIndex);
		}
	}, [fullOutputPresets, selectionIndex]);

	const hasAssignedPreset = Boolean(fullOutputPresets?.filter(i => i.table).length)
	return <>
		{showOutputPreset && <UpdateOutputPreset onClose={e => setShowOutputPreset(false)} 
			userTables={userTables} outputChannels={allOutputChannels} 
			preset={outputPreset} onSave={e => {
				setOutputPreset(e);
				onPresetUpdate && onPresetUpdate(convertPresetForSave({...e, status: "EMPTY"}));
			}}
		/>}
		{showClearPresetConfirmation &&
			<Confirm title={t('button.clear')} onClose={e => setShowClearPresetConfirmation(false)} 
				onConfirm={e => {
					setShowClearPresetConfirmation(false);
					const preset = {
						userId: outputPreset.userId, 
						id: outputPreset.id, 
						tableData: outputPreset.tableData,
						status: "EMPTY"
					};
					setOutputPreset(preset);
					onPresetUpdate && onPresetUpdate(convertPresetForSave(preset));
				}}
				message={t('orders.clearPreset.message')} confirmLabel={t('button.clear')} cancelLabel={t('button.cancel')} 
			/>
		}
		<div className={classes.previewContainer}>
			<h3>{t('orders.presets.heading')}</h3>
			<div className={classes.outputPreset}>
				{fullOutputPresets.map((preset, index) => 
					<OutputPreset
						key={index} outputPreset={preset}
						onClick={e => {
							setOutputPreset(preset);
							setOutputPresetIndex(index);
						}}
						isActive={outputPresetIndex === index} 
					/>
				)}
			</div>
			<div className={classes.selectTable}>
				<div className={classes.box}>
					<label htmlFor="tables">
						<span> {t('orders.select.table')} </span>
					</label>
					<input type='text' value={outputPreset?.table?.name || ''} readOnly="readonly" />
				</div>
				<div className={classes.box}>
					<label htmlFor="outputChannels">
						<span> {t('orders.select.outputChannel')}</span>
					</label>
					<input type='text' value={OrderUtils.getChannelName(outputPreset?.outputChannel, true) || ''} readOnly="readonly" />
				</div>
				<div className={classes.updateBtn}>
                    <TextButton onClick={() => setShowOutputPreset(true)} label={t('button.updatePreset')} />
					{
						hasAssignedPreset && 
						<TextButton onClick={() => setShowClearPresetConfirmation(true)} 
							label={t('button.clearPreset')} disabled={!canClearPreset}
						/>
					}
				</div>
			</div>
			<div className={classes.preview}>
				{
					!hasAssignedPreset && 
					<Instruction
						style={{width: "30%"}}  
						value={t("users.outputStatus.noPresetsDefined")}
					/>
				}
				{outputPreset?.table && outputPresetIndex >= 0 && tableData[outputPresetIndex] &&
					<OrderPreview selectedTable={tableData[outputPresetIndex]} showLayout={showLayout}/>}
			</div>
            {
                !hidePrintBtn &&
                <div className={classes.printBtn}>
                    <SecondaryButton label={t('button.printLabels')} 
                        onClick={e => {
                            if (tableData && outputPresetIndex >= 0 && tableData[outputPresetIndex]) {
                                let data = {
                                    printData: tableData[outputPresetIndex]
                                };
                                printJobLabels(data).then((response) => {
                                    if (!response?.data) {
                                        onError && onError({msg: t('orders.error.printJobLabels')});
                                    }
                                })
                            }
                        }} 
                        disabled={!OrderUtils.isPrintEnabled(outputPreset, outputPresetIndex)} 
                    />
                    {/* <SecondaryButton label={t('button.printJobSheet')} 
                        onClick={e => {
                            if (tableData && outputPresetIndex >= 0 && tableData[outputPresetIndex]) {
                                let data = {
                                    printData: tableData[outputPresetIndex]
                                };
                                printJobSheet(data).then((response) => {
                                    if (!response?.data) {
                                        onError && onError({msg: t('orders.error.printJobSheet')});
                                    }
                                })
                            }
                        }} 
                        disabled={!OrderUtils.isPrintEnabled(outputPreset, outputPresetIndex)} 
                    /> */}
                    <PrimaryButton label={t('button.printOrders')} 
                        onClick={e => {
                            if (tableData && outputPresetIndex >= 0 && tableData[outputPresetIndex]) {
                                let data = {
									...outputPreset,
                                    printData: tableData[outputPresetIndex]?.tableData,
                                    outputChannel: {
										...outputPreset.outputChannel,
										actionAtInput: outputPreset.action,
										rewindAfterPrint: outputPreset.returnToOrig
									},
                                    presetId: outputPreset.id
                                };

                                printOrders(dispatch, data).then((response) => {
                                    if (response && response.data && response.data.success) {
                                        setOutputPreset((prevState) => {
                                            const obj = { ...prevState }
                                            delete obj.tableData
                                            return obj;
                                        });
                                    } else {
                                        onError && onError({msg: t('orders.error.printOrders')});
                                    }
                                });
                            }
                        }} 
                        disabled={!OrderUtils.isPrintEnabled(outputPreset, outputPresetIndex)} 
                    />
                </div>
            }
		</div>
	</>;
}