import axios from '../../axios';
import { outputChannelsActions } from './OutputChannelSlice';

export function getOutputChannels(dispatch, userId) {
    return axios({
        url: `/pax/outputchannel${userId ? `?userId=${userId}` : ''}`,
        method: "get",
        responseType: "json",
    })
        .then((response) => {
            let data = response.data;
            if (data.success) {
                if (userId) {
                    dispatch(outputChannelsActions.fetchCurrentOutputChannels({ outputChannels: data.outputChannels || [] }));
                } else {
                    dispatch(outputChannelsActions.fetchOutputChannels({ outputChannels: data.outputChannels || [] }));
                }
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function getAllOutputChannels(dispatch) {
    return axios({
        url: `/pax/outputchannel/allOutputChannels`,
        method: "get",
        responseType: "json",
    })
        .then((response) => {
            let data = response.data;
            if (data.success) {
                dispatch(outputChannelsActions.fetchOutputChannels({ outputChannels: data.outputChannels || [] }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}
