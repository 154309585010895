import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

i18n.use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translations: require('./locales/en/translations.json')
      },
      es: {
        translations: require('./locales/es/translations.json')
      },
      de: {
        translations: require('./locales/de/translations.json')
      },
      fr: {
        translations: require('./locales/fr/translations.json')
      },
      it: {
        translations: require('./locales/it/translations.json')
      },
      ja: {
        translations: require('./locales/ja/translations.json')
      },
      pl: {
        translations: require('./locales/pl/translations.json')
      },
      pt: {
        translations: require('./locales/pt/translations.json')
      }
    },
    ns: ['translations'],
    defaultNS: 'translations'
  });

i18n.languages = ['en', 'es', 'de', 'fr', 'it', 'ja', 'pl', 'pt'];

export default i18n;