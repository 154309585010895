import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ActionItem from "./ActionItem";
import classes from "./Actions.module.css";
import { updateJig } from "../Jigs/JigsActions";

async function patchJig(dispatch, jig, update) {
    if (!jig) {
        return;
    }
    return await updateJig(dispatch, {...jig, ...update});
}

function Actions(props) {
    const actions = props.actions;

    const allJigs = useSelector((state) => state.jigs.allJigs, shallowEqual) || [];
    const allLocations = useSelector((state) => state.master.allMasters.location, shallowEqual) || [];
    const allPrinterTypes = useSelector((state) => state.master.allMasters.printerType, shallowEqual) || [];
    const allQualityLevels = useSelector((state) => state.master.allMasters.qualityLevel, shallowEqual) || [];
    const allMediaTypes = useSelector((state) => state.master.allMasters.mediaType, shallowEqual) || [];
    const allColorConfigs = useSelector((state) => state.master.allMasters.colorConfig, shallowEqual) || [];
    const [selectedJig, setSelectedJig] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        if (actions.jig){
            setSelectedJig(allJigs.jigs.find(jig => jig.name === actions.jig));
        }
    }, [allJigs, actions.jig])

    const { t } = useTranslation();

    function actionChangeHandler(action, value) {
        let newActions = {
            ...actions
        };
        if (value === "NA") {
            delete newActions[action];
        }
        else {
            newActions[action] = value;
        }

        if (action === "jig") {
            if (newActions.hasOwnProperty("location")) {
                delete newActions["location"];
            }
            if (newActions.hasOwnProperty("printerType")) {
                delete newActions["printerType"];
            }
        }

        props.onChange(newActions);
    }

    function getListBasedOnJig(initialList, action) {
        if (!actions.jig) {
            return initialList;
        }
        if (selectedJig && selectedJig.hasOwnProperty(action) && selectedJig[action].length > 0) {
            const filteredList = new Set(Object.keys(initialList).filter(e => selectedJig[action].includes(e)));
            return Array.from(filteredList).reduce((prev, current) => {
                prev[current] = initialList[current];
                return prev;
            }, {});
        }

        return initialList;
    }

    let jigNames = allJigs.jigs.reduce((prev, {name}) => {
        prev[name] = name;
        return prev;
    }, {});
    return (
        <div className={classes.main}>
            <ActionItem name={t('actions.jig')} action="jig" items={jigNames} value={actions.jig} onChange={actionChangeHandler} addValueLabel={t('button.addJig')} />
            <ActionItem name={t('actions.location')} action="location" items={getListBasedOnJig(allLocations, "locations")} value={actions.location} onChange={actionChangeHandler} 
                onValueAdd={(a, v) => {
                    const locations = new Set(selectedJig?.locations);
                    locations.add(v);
                    patchJig(dispatch, selectedJig, {locations: Array.from(locations)}).finally(e => {
                        actionChangeHandler(a, v);
                    });
                }}
            />
            <ActionItem name={t('actions.printerType')} action="printerType" items={getListBasedOnJig(allPrinterTypes, "printerTypes")} value={actions.printerType} onChange={actionChangeHandler} 
                onValueAdd={(a, v) => {
                    const printerTypes = new Set(selectedJig?.printerTypes);
                    printerTypes.add(v);
                    patchJig(dispatch, selectedJig, {printerTypes: Array.from(printerTypes)}).finally(e => {
                        actionChangeHandler(a, v);
                    });
                }}
            />
            <ActionItem name={t('actions.qualityLevel')} action="qualityLevel" items={allQualityLevels} value={actions.qualityLevel} onChange={actionChangeHandler} />
            <ActionItem name={t('actions.mediaType')} action="mediaType" items={allMediaTypes} value={actions.mediaType} onChange={actionChangeHandler} />
            <ActionItem name={t('actions.colorConfig')} action="colorConfig" items={allColorConfigs} value={actions.colorConfig} onChange={actionChangeHandler} />
        </div>
    );
}

export default Actions;